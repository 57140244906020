import {Animation} from "@ionic/core";
import {AnimationController, ModalController} from "@ionic/angular";
import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class ModalAnimationsService {
  private static createSideAnimation(animationController: AnimationController, isEnter: boolean, pxOffset: -1 | 1) {
    return (baseEl: HTMLElement): Animation => {
      const wrapperAnimation = animationController
        .create()
        .addElement(baseEl.shadowRoot.querySelector(".modal-wrapper"))
        .keyframes([
          {
            offset: 0,
            // opacity: isEnter ? ".8" : "1",
            transform: isEnter ? `translateX(${pxOffset * 100}vw)` : "translateX(0)"
          },
          {
            offset: 1,
            // opacity: isEnter ? "1" : ".8",
            transform: isEnter ? "translateX(0)" : `translateX(${pxOffset * 100}vw)`
          }
        ]);
      // const wrapperAnimation1 = animationController
      //   .create()
      //   .addElement(baseEl.shadowRoot.querySelector(".modal-wrapper"))
      //   .keyframes([
      //     {offset: 0, opacity: "0", transform: "scale(0)"},
      //     {offset: 1, opacity: "0.99", transform: "scale(1)"},
      //   ]);
      return animationController
        .create()
        .addElement(baseEl)
        .easing("ease-out")
        .duration(isEnter ? 100 : 300)
        .addAnimation([wrapperAnimation]);
    };
  }

  static enterLeft(animationController: AnimationController) {
    return ModalAnimationsService.createSideAnimation(animationController, true, -1);
  }

  static leaveLeft(animationController: AnimationController) {
    return ModalAnimationsService.createSideAnimation(animationController, false, -1);
  }

  static enterRight(animationController: AnimationController) {
    return ModalAnimationsService.createSideAnimation(animationController, true, 1);
  }

  static leaveRight(animationController: AnimationController) {
    return ModalAnimationsService.createSideAnimation(animationController, false, 1);
  }

  constructor(private modalController: ModalController,
              private animationController: AnimationController) {
  }

  async computeEnterAnimation(direction: "left" | "right") {
    const topModal = await this.modalController.getTop();
    if (topModal) {
      topModal.leaveAnimation = direction === null
        ? null
        : direction === "right"
          ? ModalAnimationsService.leaveLeft(this.animationController)
          : ModalAnimationsService.leaveRight(this.animationController);
    }

    return direction === null
      ? null
      : direction === "right"
        ? ModalAnimationsService.enterRight(this.animationController)
        : ModalAnimationsService.enterLeft(this.animationController);
  }
}
