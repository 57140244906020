<div class="d-inline-flex align-items-center justify-content-center w-100 h-100 animated slower fadeIn">
  @if (!hideCircle) {
    <svg class="d-block w-100 h-100 p-0" viewBox="0 0 100 100">
      <circle class="circle-main" cx="50" cy="50" r="32">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1"
                          values="0 50 50;360 50 50">
        </animateTransform>
      </circle>
    </svg>
  }
</div>
