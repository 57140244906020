import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {NavController} from "@ionic/angular";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Injectable({providedIn: "root"})
export class NavService {
  private subject = new Subject<string>();

  constructor(private navController: NavController,
              private router: Router,
              private location: Location) {
    this.subject.asObservable()
      .pipe(debounceTime(200))
      .subscribe((newLocation: string) => {
        const curLocation = this.location.path();
        if (curLocation === newLocation) {
          return;
        }
        this.location.replaceState(newLocation);
      });
  }

  go(route: string | any | Array<string | number>, options: {direction?: "forward" | "back", isStacked?: boolean, queryParams?: any} = {}): Promise<boolean> {
    options = Object.assign({direction: "forward", isStacked: false}, options);

    const strRoute = typeof (route) === "object"
      ? (route as Array<string | number>).join("/")
      : route.toString();
    const currentUrl = strRoute.startsWith("/")
      ? ""
      : this.router.url;

    const normalizedRouteParts = this.normalizeUrl(`${currentUrl}/${strRoute}`).split("/");

    if (strRoute.endsWith("..")) {
      options.direction = "back";
    }

    if (!options.isStacked) {
      return this.navController.navigateRoot(normalizedRouteParts, {
        animationDirection: options.direction,
        queryParams: options.queryParams,
        // replaceUrl: true
      });
      // return this.router.navigate([route], {queryParams: options.queryParams, relativeTo: this.activatedRoute.parent});
    }
    if (options.direction === "forward") {
      return this.navController.navigateForward(normalizedRouteParts, {
        queryParams: options.queryParams
      });
    }
    return this.navController.navigateBack(normalizedRouteParts, {
      queryParams: options.queryParams
    });
  }

  back() {
    this.navController.back({animationDirection: "back"});
  }

  routeContains(str: string): boolean {
    return this.router.url.indexOf(str) !== -1;
  }

  url(): string {
    return this.router.url;
  }

  get locationPath(): string {
    return this.location.path();
  }

  normalizeUrl(url: string): string {
    const routeParts = url.split("/");
    for (let p = 0; p < routeParts.length; p++) {
      const part = routeParts[p];
      if (part === "..") {
        routeParts[p] = null;
        for (let i = p - 1; i >= 0; i--) {
          if (routeParts[i]) {
            routeParts[i] = null;
            break;
          }
        }
      }
    }
    return routeParts.filter(x => x).join("/");
  }

  dropQueryParams() {
    const currentUrl = this.location.path();
    this.location.replaceState(currentUrl.substring(0, currentUrl.indexOf("?")));
  }

  requestLocationChange(newLocation: string) {
    this.subject.next(newLocation);
  }

  setLocationItem(lastActiveUrlSegment: string, itemId: string = null) {
    const curLocation = this.locationPath;
    const idx = curLocation.lastIndexOf("/");
    const lastUrlSegment = curLocation.substring(idx + 1);
    const baseRelUrlSegment = curLocation.substring(0, idx);

    const newLocation = lastUrlSegment.toLowerCase() === lastActiveUrlSegment.toLowerCase()
      ? itemId
        ? `${baseRelUrlSegment}/${lastUrlSegment}/${itemId}`
        : `${baseRelUrlSegment}/${lastUrlSegment}`
      : itemId
        ? `${baseRelUrlSegment}/${itemId}`
        : `${baseRelUrlSegment}`;

    // setTimeout(() => {
      this.requestLocationChange(newLocation);
    // }, itemId ? 110 : 0);
  }
}
