import {Component, EventEmitter, forwardRef, Input, Output} from "@angular/core";
import {AbstractControl, ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators} from "@angular/forms";
import {ArrayService} from "core";
import {ExporterFilterRuleOperation, FilterOperationType} from "../exporter-filter-rule-operation/exporter-filter-rule-operation.component";

export interface ExporterFilterRule {
  field: string;
  ops?: ExporterFilterRuleOperation[];
  valueFormula?: string;
}

@Component({
  selector: "app-exporter-filter-rule",
  templateUrl: "./exporter-filter-rule.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ExporterFilterRuleComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => ExporterFilterRuleComponent), multi: true}]
})
export class ExporterFilterRuleComponent implements ControlValueAccessor, Validator {
  @Input() readOnly = false;
  @Output() remove = new EventEmitter();

  form: FormGroup;
  onTouched: () => void;

  constructor(private arrayService: ArrayService) {
    this.form = new FormGroup({
      "field": new FormControl("", [Validators.required]),
      "ops": new FormArray([]),
      "valueFormula": new FormControl("")
    });
  }

  writeValue(value: ExporterFilterRule) {
    const val = this.arrayService.smartAssign({
      field: "",
      ops: [],
      valueFormula: ""
    } as ExporterFilterRule, value) as ExporterFilterRule;
    this.form.get("field").setValue(val.field);
    this.form.get("valueFormula").setValue(val.valueFormula);
    this.ops().clear();
    val.ops.forEach(this.addNewOp.bind(this));
  }

  registerOnChange(fn: (value: ExporterFilterRule) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : {invalid: {message: "ExporterFilterRule is invalid"}};
  }

  ops(): FormArray {
    return this.form.controls["ops"] as FormArray;
  }

  addNewOp(op: ExporterFilterRuleOperation = null) {
    this.ops().push(new FormControl(op || {type: FilterOperationType.None, params: []} as ExporterFilterRuleOperation));
  }
}
