import {Component, forwardRef, Input, OnInit, ViewChild} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator,
  Validators
} from "@angular/forms";
import {IonInput} from "@ionic/angular";

@Component({
  selector: "app-input-text",
  templateUrl: "./input-text.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputTextComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => InputTextComponent), multi: true}]
})
export class InputTextComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() autofocus = 0;
  @Input() created: (x: InputTextComponent) => void;

  @ViewChild(IonInput) private ionInput: IonInput;

  formControl: FormControl;
  onTouched: () => void;

  constructor() {
    this.formControl = new FormControl("", [Validators.required]);
  }

  ngOnInit() {
    this.created(this);
    if (this.autofocus) {
      setTimeout(() => {
        (this.ionInput as any).el.children[0].select();
      }, this.autofocus * 2);
    }
  }

  writeValue(value: string) {
    this.formControl.setValue(value);
  }

  registerOnChange(fn: (value: string) => void) {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.formControl.valid ? null : {invalid: {message: "Invalid value"}};
  }
}
