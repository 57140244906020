<div class="position-relative">
  @if (enableFiltering) {
    <div class="p-2 bg-white position-sticky w-100" style="top: 0">
      <ion-item class="input">
        <ion-input #filterInput
                   [placeholder]="'dict.search' | translate"
                   [appAutofocus]="500"
                   (keydown.arrowDown)="doContentFilterBlur($event)"
                   (keydown.enter)="doContentFilterApply($event)"
                   (ionInput)="handleContentFilterChange($event)">
        </ion-input>
      </ion-item>
    </div>
  }

  <div *ngFor="let a of filteredActions;index as idx"
       #actionEl
       tabindex="1"
       class="action mouse-handling"
       [class.mouse-handling]="isMouseHandling"
       [class.keyboard-handling]="isKeyboardHandling"
       [class.selected]="selectedActionIdx===idx"
       [hidden]="isHidden(a)"
       (mousemove)="enableMouseHoverHandling()">
    @if (a.type === ActionBarItemType.Separator) {
      <div class="dropdown-divider"></div>
    } @else if (a.type === ActionBarItemType.Label) {
      <div class="dropdown-header font-weight-bolder font-size-sm"
           [ngClass]="a.cssClass"
           [ngStyle]="a.cssStyle"
           [translate]="a.title">
      </div>
    } @else if (a.type === ActionBarItemType.YearMonthPicker) {
      <div class="p-3">
        <app-year-month-picker [ngModel]="a.actionValue"
                               (ngModelChange)="a.actionValue=$event">
        </app-year-month-picker>
        <div class="d-flex align-items-center justify-content-between mt-2">
          <ion-button class="d-block flex-grow-1 d-md-inline-block flex-md-grow-0"
                      (click)="handleYearMonthPickerSubmit(a, $event)"
                      translate="dict.ok">
          </ion-button>
          <ion-button color="light"
                      class="sqr-md"
                      (click)="handleYearMonthPickerClear(a, $event)">
            <ion-icon name="trash"></ion-icon>
          </ion-button>
        </div>
      </div>
    } @else if (a.type === ActionBarItemType.ColorPicker) {
      <div class="p-3">
        <app-color-picker [ngModel]="itemActionValue(a)"
                          (ngModelChange)="a.actionValue=$event">
        </app-color-picker>
      </div>
    } @else if (a.type === ActionBarItemType.TextBox) {
      <div class="p-1">
        <ion-item class="input">
          <ion-input [placeholder]="a.title | translate"
                     (keyup.enter)="handleIonInputChange(a, $event)">
          </ion-input>
        </ion-item>
      </div>
    } @else if (a.type === ActionBarItemType.Bool) {
      <div>
        <ion-item class="no-border" color="white">
          <ion-checkbox [checked]="getMultiselectValueIdx(a.actionValue)!==-1"
                        (ionChange)="checkBoxChanged($event, a)"
                        justify="start"
                        labelPlacement="end">
            <span [translate]="a.title"></span>
          </ion-checkbox>
        </ion-item>
      </div>
    } @else if ((a.type !== ActionBarItemType.TextBox && a.type !== ActionBarItemType.YearMonthPicker && a.type !== ActionBarItemType.ColorPicker && a.type !== ActionBarItemType.Separator && a.type !== ActionBarItemType.Label && a.type !== ActionBarItemType.Bool) || a.isDisabled) {
      <button class="dropdown-item color-{{a.color}}"
              [ngClass]="a.cssClass"
              [ngStyle]="a.cssStyle"
              [class.color-gray]="a.isDisabled"
              [disabled]="a.isDisabled"
              (click)="handleAction(a, $event)">
        <div class="d-flex flex-row align-items-stretch">
          <div class="flex-grow-0 d-flex align-items-center"
               [class.mr-2]="a.photoUrl || a.icon">
            @if (a.photoUrl) {
              <app-auth-img class="d-inline-block overflow-hidden sqr-xs"
                            [url]="a.photoUrl">
                @if (a.icon) {
                  <ion-icon [name]="a.icon" [color]="a.color" [ngClass]="a.iconCssClass" class="sqr-xs"></ion-icon>
                }
              </app-auth-img>
            }
            @if (a.icon && !a.photoUrl) {
              <ion-icon [ngClass]="a.iconCssClass"
                        class="sqr-xs"
                        [name]="a.icon"
                        [color]="a.color">
              </ion-icon>
            }
          </div>

          <div class="d-flex flex-column justify-content-center flex-grow-1">
            <div class="d-flex title"
                 [class.checked]="getMultiselectValueIdx(a.actionValue)!==-1">
              <span class="text-wrap">{{a.title | translateInterpolation}}</span>
              @if (a.actions || a.type === ActionBarItemType.Search) {
                <span> ...</span>
              }
            </div>
            @if (a.description) {
              <div class="color-gray font-size-sm">{{a.description | translateInterpolation}}</div>
            }
          </div>

          <div class="flex-grow-0 text-{{a.color}}"
               [innerHTML]="computeShortcut(a)">
          </div>
        </div>
      </button>
    }
  </div>
  @if (isMultiselect) {
    <div class="p-3">
      <div class="d-flex align-items-center justify-content-between mt-0">
        <ion-button class="d-block flex-grow-1 d-md-inline-block flex-md-grow-0"
                    translate="dict.ok"
                    (click)="submitSelection()">
        </ion-button>
        <ion-button color="light"
                    class="sqr-md"
                    (click)="resetSelection()">
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </div>
    </div>
  }
</div>
