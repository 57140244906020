import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class EvtUtilsService {
  isFocusedOnInput(e): boolean {
    const targetType = e?.target?.tagName;
    if (!targetType) {
      return false;
    }
    return ["INPUT", "TEXTAREA", "SELECT"].indexOf(targetType.toUpperCase()) !== -1;
  }

  isEventType(e, eventType): boolean {
    if (!e) {
      return false;
    }
    if (e.eventType && e.eventType.toString().toLowerCase() === eventType.toString().toLowerCase()) {
      return true;
    }
    if (e.e) {
      return this.isEventType(e.e, eventType);
    }
    if (e.originalEvent) {
      return this.isEventType(e.originalEvent, eventType);
    }
    return e.toString() === "[object " + eventType + "Event]";
  }

  isKeyboardEvt(e): boolean {
    return this.isEventType(e, "Keyboard");
  }

  isMouseEvt(e): boolean {
    return this.isEventType(e, "Mouse");
  }

  isClipboardEvt(e): boolean {
    return this.isEventType(e, "Clipboard");
  }

  cancelEvt(e) {
    if (!e) {
      return;
    }
    if (e.preventDefault) {
      e.preventDefault();
    }
    if (e.stopImmediatePropagation) {
      e.stopImmediatePropagation();
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    e.cancelBubble = true;
    e.returnValue = false;
  }
}
