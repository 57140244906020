<app-year-picker [options]="options"
                 [formControl]="yearFormControl">
</app-year-picker>
<div class="mb-2"></div>
<app-month-picker [options]="options"
                  [year]="yearFormControl.value"
                  [formControl]="formControl">
</app-month-picker>

<!--<div class="d-flex align-items-center">-->
<!--  <ion-button>This month</ion-button>-->
<!--  <ion-button>Last month</ion-button>-->
<!--  <ion-button>This quarter</ion-button>-->
<!--  <ion-button>Last quarter</ion-button>-->
<!--  <ion-button>This year</ion-button>-->
<!--  <ion-button>Last year</ion-button>-->
<!--</div>-->
