@if (isHeader) {
  <div class="header py-2">
    <div class="header-content  height-nav-bar ">
      <span [translate]="_row || '&nbsp;'"></span>
    </div>
  </div>
} @else {
  <div class="row mb-2 no-gutters h-100 cols-{{_cols}}">
    @if (_row[0]) {
      <div [ngClass]="getItemClass(_row[0])" [id]="_row[0]['id']">
        <ng-container *ngComponentOutlet="itemComponent;inputs: dynamicInputs[0];"/>
      </div>
    }
    @if (_row[1]) {
      <div [ngClass]="getItemClass(_row[1])" [id]="_row[1]['id']">
        <ng-container *ngComponentOutlet="itemComponent;inputs: dynamicInputs[1];"/>
      </div>
    }
    @if (_row[2]) {
      <div [ngClass]="getItemClass(_row[2])" [id]="_row[2]['id']">
        <ng-container *ngComponentOutlet="itemComponent;inputs: dynamicInputs[2];"/>
      </div>
    }
    @if (_row[3]) {
      <div [ngClass]="getItemClass(_row[3])" [id]="_row[3]['id']">
        <ng-container *ngComponentOutlet="itemComponent;inputs: dynamicInputs[3];"/>
      </div>
    }
  </div>
}
