import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {MarvinImageProcessor} from "./marvin-image-processor";
import {ImageProcessor} from "./image-processor";
import {FileModel, FileService, FileSource} from "../files/file.service";

@Injectable({providedIn: "root"})
export class ImageProcessorService {
  constructor(private fileService: FileService) {
  }

  createMarvinFromUrl(imageUrl: string): Observable<MarvinImageProcessor> {
    return new Observable(o => {
      const mip = new MarvinImageProcessor();
      mip.loadImage(imageUrl)
        .subscribe(() => {
          o.next(mip);
          o.complete();
        });
    });
  }

  createMarvinFromFile(imageFile: File): Observable<MarvinImageProcessor> {
    return new Observable(o => {
      const mip = new MarvinImageProcessor();
      this.fileService.toFileModel(imageFile, FileSource.FileSys)
        .subscribe((fileModel: FileModel) => {
          const ip = new ImageProcessor();
          ip.loadImage(fileModel.contentUrl, fileModel.arrayBuffer)
            .subscribe(() => {
              ip.fixOrientation()
                .subscribe((img: HTMLImageElement) => {
                  mip.loadImage(img.src)
                    .subscribe(() => {
                      o.next(mip);
                      o.complete();
                    });
                });
            });
        });
    });
  }

  createFromFile(imageFile: File): Observable<ImageProcessor> {
    return new Observable(o => {
      this.fileService.toFileModel(imageFile, FileSource.FileSys)
        .subscribe((fileModel: FileModel) => {
          const ip = new ImageProcessor();
          ip.loadImage(fileModel.contentUrl, fileModel.arrayBuffer)
            .subscribe(() => {
              o.next(ip);
              o.complete();
            });
        });
    });
  }

  createFromFileModel(fileModel: FileModel): Observable<ImageProcessor> {
    return new Observable(o => {
      const ip = new ImageProcessor();
      ip.loadImage(fileModel.contentUrl, fileModel.arrayBuffer)
        .subscribe(() => {
          o.next(ip);
          o.complete();
        });
    });
  }
}
