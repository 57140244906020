import {Injector} from "@angular/core";
import {mergeAttributes, Node} from "@tiptap/core";
import {AngularNodeViewRenderer} from "ngx-tiptap";
import {TiptapNodeImageComponent} from "./tiptap-node-image.component";

const ImageNodeExtension = (injector: Injector): Node => {
  return Node.create({
    name: "image",
    group: "inline",
    inline: true,
    atom: true,
    selectable: true,
    draggable: true,
    addAttributes() {
      return {
        imageSrc: {default: ""},
        imageWidth: {default: ""},
        imageHeight: {default: ""}
      };
    },
    parseHTML() {
      return [{tag: "app-tiptap-node-image"}];
    },
    renderHTML({HTMLAttributes}) {
      return ["app-tiptap-node-image", mergeAttributes(HTMLAttributes)];
    },
    addNodeView() {
      return AngularNodeViewRenderer(TiptapNodeImageComponent, {injector});
    }
  });
};

export default ImageNodeExtension;
