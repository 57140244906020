<ion-item *ngFor="let option of options; let index=index"
          class="input no-border no-padding no-hover mb-0">
  <ion-checkbox [checked]="isChecked(option.id)"
                (ionChange)="changed($event, index)"
                [style.width]="'fit-content'"
                justify="start"
                labelPlacement="end">
    <span [innerText]="option.name"></span>
  </ion-checkbox>
  @if (option.photoUrl) {
    <app-auth-img class="sqr-md mr-2 my-1" [url]="option.photoUrl"></app-auth-img>
  }
</ion-item>
<input *ngFor="let _ of formArray.controls; let index=index"
       type="hidden"
       [value]="formArray.controls[index].value">
