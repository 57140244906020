import {HorizontalRule} from "@tiptap/extension-horizontal-rule";

export const HorizontalRuleExt = HorizontalRule.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      borderStyle: {
        default: null,
        parseHTML: el => el.getAttribute("borderStyle"),
        renderHTML: attrs => attrs["borderStyle"] ? {
            borderStyle: attrs["borderStyle"],
            style: `--tiptap-borderStyle: ${attrs["borderStyle"]}`
          }
          : {}
      },
      borderWidth: {
        default: null,
        parseHTML: el => el.getAttribute("borderWidth"),
        renderHTML: attrs => attrs["borderWidth"] ? {
            borderWidth: attrs["borderWidth"],
            style: `--tiptap-borderWidth: ${attrs["borderWidth"]}`
          }
          : {}
      },
      borderColor: {
        default: null,
        parseHTML: el => el.getAttribute("borderColor"),
        renderHTML: attrs => attrs["borderColor"] ? {
          borderColor: attrs["borderColor"],
          style: `--tiptap-borderColor: ${attrs["borderColor"]}`
        } : {}
      }
    };
  }
});
