import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "app-item-list-row",
  templateUrl: "./item-list-row.component.html",
  styleUrls: ["./item-list-row.component.scss"]
})
export class ItemListRowComponent<T> implements OnInit {
  @Input() set row(value: Array<T> | string) {
    this._row = value;
    this.refreshInputs();
  }

  @Input() set cols(value: number) {
    this._cols = value;
  }

  @Input() itemComponent: Component | any;
  @Input() itemInputs: {[key: string]: any};
  @Input() isItemSelected: Function;
  @Input() isItemDisabled: Function;

  protected _row: Array<T> | string;
  protected _cols: number;
  dynamicInputs: Array<{[key: string]: any}>;
  isHeader = true;

  ngOnInit() {
    this.refreshInputs();
  }

  getItemClass(item: T): string {
    let ret = `app-item-card cursor-pointer col-${12 / this._cols}`;
    ret += this.isItemSelected(item) ? " selected" : "";
    ret += this.isItemDisabled(item) ? " disabled" : "";
    return ret;
  }

  private refreshInputs() {
    this.dynamicInputs = typeof this._row === "string"
      ? []
      : this._row.map((item: T) => {
        return {item, ...this.itemInputs};
      });
    this.isHeader = typeof this._row === "string";
  }
}
