import {Component, forwardRef, Input, QueryList, ViewChildren} from "@angular/core";
import {ControlValueAccessor, FormArray, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {IonInput} from "@ionic/angular";
import {KeypressUtilsService} from "core";

@Component({
  selector: "app-text-list",
  templateUrl: "./text-list.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextListComponent), multi: true}]
})
export class TextListComponent implements ControlValueAccessor {
  @Input() readOnly = false;
  @ViewChildren("input") inputItems: QueryList<IonInput>;

  rows: FormArray;

  constructor(private keypressUtilsService: KeypressUtilsService) {
    this.rows = new FormArray([]);
  }

  writeValue(value: Array<string>) {
    if (!value || !(value instanceof Array)) {
      value = [];
    }
    this.rows.clear();
    value.forEach(v => this.rows.push(new FormControl(v)));
    if (!this.rows.length || this.rows.at(this.rows.length - 1).value) {
      this.addNewRow();
    }
  }

  registerOnChange(fn: (value: string) => void) {
    this.rows.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
  }

  private addNewRow() {
    this.rows.push(new FormControl(""));
  }

  onFocusout($event: any, index: number) {
    this.processValue($event.target.value, index);
  }

  onKeyDown($event: any, index: number) {
    if (!this.keypressUtilsService.isEnterKey($event) && !this.keypressUtilsService.isTabKey($event)) {
      return;
    }
    this.processValue($event.target.value, index);
    this.focusNewField();
    return $event.preventDefault();
  }

  clearInput(index: number) {
    this.processValue("", index);
  }

  private processValue(value: string, index: number) {
    if (!value && index < this.rows.length - 1) {
      this.rows.removeAt(index);
      return;
    }
    if (value && index === this.rows.length - 1) {
      this.addNewRow();
    }
    this.rows.at(index).setValue(value);
  }

  private focusNewField() {
    setTimeout(() => {
      this.inputItems.last.setFocus();
    }, 10);
  }
}
