import {Injectable} from "@angular/core";

export const KeyCodes = {
  tab: 9,
  back: 8,
  enter: 13,
  space: 32,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  home: 36,
  end: 35,
  pgUp: 33,
  pgDown: 34,
  del: 46,
  esc: 27
};

@Injectable({providedIn: "root"})
export class KeypressUtilsService {
  isAlphaNumericalKey(e): boolean {
    const key = e?.keyCode;
    return (key >= 48 && key <= 90) || (key >= 96 && key <= 111) || (key >= 186 && key <= 222) || key === 32;
  }

  isNumericalKey(e): boolean {
    return ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].indexOf(e.key) !== -1;
  }

  isModifierKey(e): boolean {
    return e?.shiftKey || e?.ctrlKey || e?.altKey || e?.metaKey;
  }

  isShiftAlone(e): boolean {
    return e?.keyCode === 16;
  }

  isShiftKey(e): boolean {
    return e?.shiftKey;
  }

  isCtrlKey(e): boolean {
    return e?.ctrlKey || e.metaKey;
  }

  isAltKey(e): boolean {
    return e?.altKey;
  }

  isMetaLeftKey(e): boolean {
    return (e?.metaKey && e?.keyCode === 91) || e.code === "MetaLeft";
  }

  isMetaRightKey(e): boolean {
    return (e?.metaKey && e?.keyCode === 93) || e.code === "MetaRight";
  }

  isFunctionKey(e): boolean {
    return (112 <= e.keyCode && e.keyCode <= 123);
  }

  isDeadKey(e): boolean {
    return e?.key === "Dead";
  }

  isNavKey(e): boolean {
    const keys = [KeyCodes.up, KeyCodes.down, KeyCodes.left, KeyCodes.right, KeyCodes.home, KeyCodes.end, KeyCodes.pgUp, KeyCodes.pgDown];
    return keys.indexOf(e?.keyCode) !== -1;
  }

  isDelKey(e): boolean {
    return e?.keyCode === KeyCodes.del;
  }

  isBackspaceKey(e): boolean {
    return e?.keyCode === KeyCodes.back;
  }

  isLeftNavKey(e): boolean {
    return e?.keyCode === KeyCodes.left;
  }

  isRightNavKey(e): boolean {
    return e?.keyCode === KeyCodes.right;
  }

  isDownNavKey(e): boolean {
    return e?.keyCode === KeyCodes.down;
  }

  isUpNavKey(e): boolean {
    return e?.keyCode === KeyCodes.up;
  }

  isTabKey(e): boolean {
    return e?.keyCode === KeyCodes.tab;
  }

  isEnterKey(e): boolean {
    return e?.keyCode === KeyCodes.enter;
  }

  isSpaceKey(e): boolean {
    return e?.keyCode === KeyCodes.space;
  }

  isEscKey(e): boolean {
    return e?.keyCode === KeyCodes.esc;
  }

  isNbspKey(e): boolean {
    return e?.keyCode === 160;
  }

  isMetaKey(e): boolean {
    return e?.metaKey || e?.key === "Meta";
  }

  isPasteKeyCombo(e): boolean {
    return (e?.ctrlKey || e?.metaKey) && e?.keyCode === 86;
  }

  isNativeBrowserCombo(e): boolean {
    const blackList = [90, 89]; // z, y
    return (e?.ctrlKey || e?.metaKey) && blackList.indexOf(e?.keyCode) === -1;
  }

  isHarmlessKey(e): boolean {
    return this.isShiftAlone(e) || this.isNavKey(e) || this.isNbspKey(e) || this.isFunctionKey(e) || this.isCtrlKey(e) || this.isMetaKey(e) || this.isEscKey(e);
  }

  isRightSeparatorKey(e): boolean {
    if (e?.shiftKey) {
      return [":", "%", ")", "}", "?", ">", "!", "\""].indexOf(e?.key) !== -1;
    } else {
      return [",", ".", ";", "]", "'"].indexOf(e?.key) !== -1;
    }
  }

  isLeftSeparatorKey(e): boolean {
    if (e?.shiftKey) {
      return ["#", "%", "@", "`", "(", "{", "/", "<", "\""].indexOf(e.key) !== -1;
    } else {
      return ["'", "["].indexOf(e?.key) !== -1;
    }
  }

  isUndoShortcut(e): boolean {
    return (e?.ctrlKey || e?.metaKey) && e?.keyCode === 90; // Ctrl+Z
  }

  isRedoShortcut(e): boolean {
    return (e?.ctrlKey || e?.metaKey) && ((e?.keyCode === 90 && e?.shiftKey) || e?.keyCode === 89); // Ctrl+Shift+Z,  Ctrl+Y
  }

  isCutShortcut(e): boolean {
    return (e?.ctrlKey || e?.metaKey) && e?.keyCode === 88; // Ctrl+X
  }

  isIfLongPressedWillDisplayOSXCharPopup(e): boolean {
    // const popupChars = 'èéêëēėęyÿûüùúūnîïíīįìôöòóœøōõàáâäæãåāßśšłžźżçćčñń';
    return ["e", "y", "u", "i", "o", "a", "s", "l", "z", "c", "n"].indexOf(e.key) !== -1;
  }

  isOSXCharPopupNavKey(e): boolean {
    const keys = [229];
    return keys.indexOf(e?.keyCode) !== -1;
  }

  isOSXOpenEmojiCombo(e): boolean {
    return e?.ctrlKey && e?.metaKey && this.isSpaceKey(e);
  }
}
