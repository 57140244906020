import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class RxJsExtService {
  waitFor(conditionFn: () => boolean): Observable<any> {
    return new Observable(o => {
      if (!conditionFn.apply(null)) {
        setTimeout(() => {
          this.waitFor(conditionFn).subscribe(() => {
            o.next();
            o.complete();
          });
        });
      } else {
        o.next();
        o.complete();
      }
    });
  }
}
