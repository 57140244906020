import {ElementRef, Injectable, Injector} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Document} from "@tiptap/extension-document";
import {BulletList} from "@tiptap/extension-bullet-list";
import {OrderedList} from "@tiptap/extension-ordered-list";
import {HardBreak} from "@tiptap/extension-hard-break";
import {Heading} from "@tiptap/extension-heading";
import {ListItem} from "@tiptap/extension-list-item";
import {Paragraph} from "@tiptap/extension-paragraph";
import {Text} from "@tiptap/extension-text";
import {TextAlign} from "@tiptap/extension-text-align";
import {FontFamily} from "@tiptap/extension-font-family";
import {TextStyle} from "@tiptap/extension-text-style";
import {Underline} from "@tiptap/extension-underline";
import {Placeholder} from "@tiptap/extension-placeholder";
import {TableHeader} from "@tiptap/extension-table-header";
import {TableRow} from "@tiptap/extension-table-row";
import {Image} from "@tiptap/extension-image";
import {FocusClasses} from "@tiptap/extension-focus";
import {Bold} from "@tiptap/extension-bold";
import {Italic} from "@tiptap/extension-italic";
import {Strike} from "@tiptap/extension-strike";
import {Dropcursor} from "@tiptap/extension-dropcursor";
import {Gapcursor} from "@tiptap/extension-gapcursor";
import {History} from "@tiptap/extension-history";
import {Color} from "@tiptap/extension-color";
import FieldNodeExtension from "./tiptap-node-field/tiptap-node-field.node";
import ImageNodeExtension from "./tiptap-node-image/tiptap-node-image.node";
import {FontSize} from "./tiptap-extensions/tiptap-extension-font-size";
import {TableExt} from "./tiptap-extensions/tiptap-extension-table-ext";
import {HorizontalRuleExt} from "./tiptap-extensions/tiptap-extension-hr-ext";
import {getParagraphExt} from "./tiptap-extensions/tiptap-extension-paragraph-ext";
import {getTableCellExt} from "./tiptap-extensions/tiptap-extension-table-cell-ext";
import PasteExt from "./tiptap-extensions/tiptap-extension-paste";

@Injectable({providedIn: "root"})
export class TiptapService {
  constructor(private injector: Injector,
              private translateService: TranslateService) {
  }

  getDesignerExtensions(el: ElementRef) {
    return [
      Document,
      BulletList,
      OrderedList,
      HardBreak,
      Heading,
      ListItem,
      Paragraph,
      Text,
      TextAlign.configure({
        types: ["heading", "paragraph"],
        defaultAlignment: null
      }),
      FontFamily.configure({
        types: ["textStyle"]
      }),
      Color.configure({
        types: ["textStyle"]
      }),
      FontSize.configure({
        types: ["textStyle"]
      }),
      TextStyle,
      Underline,
      HorizontalRuleExt,
      Placeholder.configure({
        emptyEditorClass: "is-editor-empty",
        placeholder: this.translateService.instant("reports.field.placeholder")
      }),
      TableExt.configure({
        resizable: true,
        lastColumnResizable: true
      }),
      TableHeader,
      TableRow,
      getTableCellExt(el),
      // Image.configure({
      //   inline: true,
      //   allowBase64: true
      // }),
      FocusClasses,
      Bold,
      Italic,
      Strike,
      Dropcursor,
      Gapcursor,
      History,
      FieldNodeExtension(this.injector),
      ImageNodeExtension(this.injector),
      PasteExt
    ];
  }

  getFormulaEditorExtensions(fn: Function = null) {
    return [
      Document,
      getParagraphExt(fn),
      Text,
      Placeholder.configure({
        emptyEditorClass: "is-editor-empty",
        placeholder: this.translateService.instant("reports.field.placeholder")
      }),
      FocusClasses,
      FieldNodeExtension(this.injector)
    ];
  }
}
