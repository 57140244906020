import {Component, Injector, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from "@angular/core";
import {IonInput, PopoverController} from "@ionic/angular";
import {HotkeyEvent, HotkeysService, SubSink} from "core";
import {ActionBarItem, ActionBarItemType} from "./action.model";
import {ActionBarItemService} from "./action-bar-item.service";

@Component({
  selector: "app-action-dropdown-options",
  templateUrl: "action-dropdown-options.component.html",
  styleUrls: ["./action-dropdown-options.component.scss"]
})
export class ActionDropdownOptionsComponent implements OnInit, OnDestroy {
  @Input() set actions(value: Array<ActionBarItem>) {
    this._actions = value;
    this.filteredActions = this.enableFiltering && this.limitItemsDisplay
      ? this._actions.slice(0, Math.min(this.limitItemsDisplay, this._actions.length))
      : this._actions;
  }

  @Input() enableFiltering = false;
  @Input() isMultiselect = false;
  @Input() limitItemsDisplay: number;
  @Input() actionValue: Array<any>;

  @ViewChildren("actionEl") private actionElements: QueryList<HTMLDivElement>;
  @ViewChild("filterInput") private filterInput: IonInput;

  ActionBarItemType = ActionBarItemType;
  filteredActions: Array<ActionBarItem>;
  selectedActionIdx = -1;
  isMouseHandling = true;
  isKeyboardHandling = false;
  private _actions: Array<ActionBarItem>;
  private sub = new SubSink();
  private actionValueHash: Array<any>;
  private actionBarItemService: ActionBarItemService;

  constructor(private popoverController: PopoverController,
              private hotKeysService: HotkeysService,
              private injector: Injector) {
  }

  ngOnInit() {
    this.actionBarItemService = this.injector.get(ActionBarItemService);
    if (!this.actionValue) {
      this.actionValue = [];
    }
    this.actionValue = [].concat(this.actionValue);
    this.actionValueHash = this.actionValue.map(x => JSON.stringify(x));
    this.hotKeysService.addShortcuts([{hotKey: "arrowDown"}, {hotKey: "arrowUp"}, {hotKey: "enter"}, {hotKey: "esc"}])
      .subscribe(x => this.sub.sink = x.subscribe(this.handleHotkey.bind(this)));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async handleAction(actionBarItem: ActionBarItem, $event: UIEvent | any) {
    if (!actionBarItem) {
      return;
    }
    if (actionBarItem.parent) {
      actionBarItem.parent.actionValue = [actionBarItem.actionValue];
    }
    await this.actionBarItemService.callAction(actionBarItem, $event);
  }

  isHidden(a: ActionBarItem) {
    return this.actionBarItemService.isHidden(a);
  }

  isDisabled(a: ActionBarItem) {
    return this.actionBarItemService.isDisabled(a);
  }

  itemActionValue(a: ActionBarItem): string {
    return this.actionBarItemService.actionValue(a);
  }

  computeShortcut(a: ActionBarItem) {
    return this.actionBarItemService.computeShortcut(a);
  }

  private getNextActionIdx(direction: number): number {
    const filtered = this.filteredActions.filter((a, idx) => {
      const mainCondition = (a.type === ActionBarItemType.Link || !a.type) && !this.isHidden(a) && !this.isDisabled(a);
      const directionCondition = direction > 0
        ? idx > this.selectedActionIdx
        : idx < this.selectedActionIdx;
      return mainCondition && directionCondition;
    });
    return direction > 0
      ? this.filteredActions.indexOf(filtered[0])
      : this.filteredActions.indexOf(filtered[filtered.length - 1]);
  }

  private selectNextActionEl(direction: number): HTMLDivElement {
    this.isMouseHandling = false;
    this.isKeyboardHandling = true;
    const idx = !direction
      ? this.selectedActionIdx
      : this.getNextActionIdx(direction);
    if (idx !== -1) {
      this.selectedActionIdx = idx;
      return (this.actionElements.get(this.selectedActionIdx) as any)?.nativeElement as HTMLDivElement;
    }
    return null;
  }

  enableMouseHoverHandling() {
    this.selectNextActionEl(0)?.blur();
    this.selectedActionIdx = -1;
    this.isMouseHandling = true;
    this.isKeyboardHandling = false;
  }

  handleContentFilterChange($event: CustomEvent) {
    const filter = $event.detail.value.toString().toLowerCase();
    this.filteredActions = this._actions
      .filter(x => this.actionBarItemService.getTitle(x).toLowerCase().indexOf(filter) !== -1);
    if (this.limitItemsDisplay) {
      this.filteredActions = this.filteredActions
        .slice(0, Math.min(this.limitItemsDisplay, this.filteredActions.length));
    }
    this.selectedActionIdx = 0;
  }

  doContentFilterBlur($event: KeyboardEvent) {
    ($event.target as any).blur();
    this.selectNextActionEl(1)?.focus();
  }

  async doContentFilterApply($event: KeyboardEvent) {
    await this.handleHotkey(Object.assign($event, {hotKey: "enter"}) as HotkeyEvent);
  }

  async handleIonInputChange(a: ActionBarItem, $event: any) {
    a.actionValue = $event.target.value;
    await this.handleAction(a, $event);
  }

  async handleYearMonthPickerSubmit(a: ActionBarItem, $event: any) {
    await this.popoverController.dismiss(a.actionValue);
  }

  async handleYearMonthPickerClear(a: ActionBarItem, $event: any) {
    a.actionValue = {dateFrom: null, dateTo: null};
    await this.popoverController.dismiss(a.actionValue);
  }

  checkBoxChanged($event: any, a: ActionBarItem) {
    const checked = $event.target.checked;
    if (checked) {
      this.actionValue.push(a.actionValue);
      this.actionValueHash.push(JSON.stringify(a.actionValue));
    } else {
      const idx = this.getMultiselectValueIdx(a.actionValue);
      if (idx !== -1) {
        this.actionValue.splice(idx, 1);
        this.actionValueHash.splice(idx, 1);
      }
    }
    return $event.preventDefault();
  }

  getMultiselectValueIdx(data: any | Array<any>): number {
    return this.actionValueHash.findIndex(x => x === JSON.stringify(data));
  }

  async submitSelection() {
    await this.popoverController.dismiss(this.actionValue);
  }

  async resetSelection() {
    this.actionValue.length = 0;
    await this.submitSelection();
  }

  async handleHotkey(e: HotkeyEvent) {
    const hotKey = e.hotKey.toLowerCase();
    if (hotKey === "arrowdown") {
      this.selectNextActionEl(1);
    } else if (hotKey === "arrowup") {
      const newSelectedItem = this.selectNextActionEl(-1);
      if (!newSelectedItem && hotKey === "arrowup") {
        await this.filterInput.setFocus();
      }
    } else if (hotKey === "enter") {
      const selectedAction = this.filteredActions[this.selectedActionIdx];
      await this.handleAction(selectedAction, e);
    }
  }
}
