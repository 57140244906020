<form [formGroup]="form">
  <input type="hidden" formControlName="country">
  <div class="row no-gutters">
    <ion-item class="col-12 col-md-4 floating-label"
              [class.error]="form.controls['region'].invalid">
      @if (!readonly) {
        <select class="m-0 p-0 border-0 w-100"
                formControlName="region">
          <option disabled selected hidden translate="address.region"></option>
          <option *ngFor="let region of regions" [value]="region.id" [innerText]="region.name"></option>
        </select>
      } @else {
        <ion-input formControlName="region"
                   [readonly]="true"
                   [label]="'address.region' | translate"
                   labelPlacement="floating">
        </ion-input>
      }
    </ion-item>
    <ion-item class="col-12 col-md-4 pl-md-1 floating-label"
              [class.error]="form.controls['city'].invalid">
      <ion-input formControlName="city"
                 [readonly]="readonly"
                 [label]="'address.city' | translate"
                 labelPlacement="floating">
      </ion-input>
      <app-form-controls-errors [control]="form.controls['city']"></app-form-controls-errors>
    </ion-item>
    <ion-item class="col-12 col-md-4 pl-md-1 floating-label"
              [class.error]="form.controls['zipCode'].invalid">
      <ion-input formControlName="zipCode"
                 [readonly]="readonly"
                 [label]="'address.zipCode' | translate"
                 labelPlacement="floating">
      </ion-input>
      <app-form-controls-errors [control]="form.controls['zipCode']"></app-form-controls-errors>
    </ion-item>
  </div>

  <ion-item class="floating-label"
            [class.error]="form.controls['line1'].invalid">
    <ion-input formControlName="line1"
               [readonly]="readonly"
               [label]="'address.line1' | translate"
               labelPlacement="floating">
    </ion-input>
    <app-form-controls-errors [control]="form.controls['line1']"></app-form-controls-errors>
  </ion-item>
  @if (showEContact) {
    <div class="row no-gutters">
      <ion-item class="col-12 col-md-4 floating-label"
                [class.error]="form.controls['phoneNumber'].invalid">
        <ion-input formControlName="phoneNumber"
                   [readonly]="readonly"
                   [label]="'address.phoneNumber' | translate"
                   labelPlacement="floating">
        </ion-input>
        <app-form-controls-errors [control]="form.controls['phoneNumber']"></app-form-controls-errors>
      </ion-item>
      <ion-item class="col-12 col-md-4 pl-md-1 floating-label"
                [class.error]="form.controls['email'].invalid">
        <ion-input formControlName="email" type="email" inputmode="email"
                   [readonly]="readonly"
                   [label]="'E-Mail'"
                   labelPlacement="floating">
        </ion-input>
        <app-form-controls-errors [control]="form.controls['email']"></app-form-controls-errors>
      </ion-item>
      <ion-item class="col-12 col-md-4 pl-md-1 floating-label"
                [class.error]="form.controls['web'].invalid">
        <ion-input formControlName="web"
                   [readonly]="readonly"
                   [label]="'Web'"
                   labelPlacement="floating">
        </ion-input>
        <app-form-controls-errors [control]="form.controls['web']"></app-form-controls-errors>
      </ion-item>
    </div>
  }
</form>
