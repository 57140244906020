import {Component, ElementRef, forwardRef, Input, Output, EventEmitter, ViewChild} from "@angular/core";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {FormatPipe} from "ngx-dayjs9";
import {UUidService} from "core";
import {IonDatetime, Platform} from "@ionic/angular";

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatePickerComponent), multi: true}]
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() readOnly = false;
  @Input() hourCycle: "h12" | "h24" = "h24";
  @Input() presentation: "date" | "date-time" = "date";
  @Input() label: string;
  @Output() inputClick = new EventEmitter<any>();

  @ViewChild(IonDatetime) private popoverDatetime2: IonDatetime;

  formControl = new FormControl();
  onTouched: () => void;
  dateText: string;
  isDisabled: boolean;
  triggerElId: string;

  isDesktop = this.platform.width() >= 768;

  constructor(private el: ElementRef,
              private platform: Platform,
              private uuid: UUidService) {
    this.triggerElId = uuid.v4();
  }

  writeValue(value: number | string | Date) {
    const fixedValue = FormatPipe.fixDateValue(value);
    this.el.nativeElement.value = fixedValue;
    this.formControl.setValue(fixedValue);
    this.setDateText(value);
  }

  registerOnChange(fn: (value: string) => void) {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  setDateText(value: number | string | Date) {
    this.dateText = new FormatPipe().transform(value, "YYYY-MM-DD");
  }

  startSelection($event: MouseEvent) {
    if (!this.formControl.value) {
      this.writeValue(Date.now());
    }
  }

  resetValue() {
    this.writeValue(null);
  }

  async setDate() {
    const value = this.popoverDatetime2.value.toString();
    const k = value.indexOf("T");
    const fixedValue = k === -1
      ? `${value}T00:00:00`
      : `${value.substring(0, k)}T00:00:00`;
    this.writeValue(fixedValue);
    await this.popoverDatetime2.cancel(true);
  }
}
