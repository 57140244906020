<ion-item *ngFor="let _ of rows.controls; let index=index"
          class="input">
  <ion-input #input
             type="text"
             [spellcheck]="false"
             [value]="rows.controls[index].value"
             (focusout)="onFocusout($event, index)"
             (keydown)="onKeyDown($event, index)"
             [readonly]="readOnly"
             [clearInput]="true">
  </ion-input>
</ion-item>
