<app-auth-img class="h-100"
              [cssStyle]="cssStyle"
              [url]="previewImageUrl"
              (loaded)="loaded.emit()"
              (error)="error.emit($event)">
  <app-auth-img class="h-100"
                [cssStyle]="cssStyle"
                url="~/svg/document.svg"
                (loaded)="loaded.emit()">
  </app-auth-img>
</app-auth-img>
@if (mimePreviewImageUrl != previewImageUrl) {
  <div class="centered-absolute w-100 h-100 bg-gray opacity-09 d-flex align-items-center justify-content-center">
    <app-auth-img [cssStyle]="cssStyle"
                  [url]="mimePreviewImageUrl">
    </app-auth-img>
  </div>
}
