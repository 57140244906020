import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class ArrayService {
  isEmptyObject(obj: any): boolean {
    if (!obj) {
      return true;
    }
    if ((obj instanceof Array) && obj.length === 0) {
      return true;
    }
    return !Object.keys(obj).find(k => !!obj[k]);
  }

  smartAssign(destObj: any, srcObj: any): any {
    if (!srcObj) {
      return destObj;
    }
    const srcCured = Object.keys(srcObj)
      .filter(k => Object.keys(destObj).includes(k))
      .reduce((obj: any, k: string) => Object.assign(obj, {[k]: srcObj[k]}), {});
    return Object.assign({}, destObj, srcCured);
  }

  // mergeArrays(a1: Array<any>, a2: Array<any>, key: string) {
  //   const diff = this.getDiff(a1, a2, key);
  //   diff.added.forEach(x => a1.splice(0, 0, x));
  //   a2.forEach(x => this.updateObject(a1, x, key));
  // }
  //
  // syncArrays(a1: Array<any>, a2: Array<any>, key: string) {
  //   const diff = this.getDiff(a1, a2, key);
  //   diff.added.forEach(d => a1.push(d));
  //   diff.removed.forEach(d => this.removeItem(a1, x => x[key] === d[key]));
  // }

  updateObject(a: Array<any>, updatedObject: any, predicate: Function = (x: any) => x.id === updatedObject["id"]): boolean {
    const found = a.find(x => predicate(x));
    if (found) {
      Object.assign(found, updatedObject);
      return true;
    }
    return false;
  }

  removeItem(a: Array<any>, predicate: Function): boolean {
    const idx = a.findIndex(x => predicate(x));
    if (idx === -1) {
      return false;
    }
    a.splice(idx, 1);
    return true;
  }

  getItem(a: Array<any>, predicate: Function): any {
    const idx = a.findIndex(x => predicate(x));
    return a[idx] || null;
  }

  getDiff(a1: Array<any>, a2: Array<any>, key: string): {removed: Array<any>, added: Array<any>} {
    return {
      removed: this.getRemovedDiff(a1, a2, key),
      added: this.getRemovedDiff(a2, a1, key),
    };
  }

  sortBy(a: Array<any>, key: string): Array<any> {
    return a.sort((x, y) => {
      if (x[key] < y[key]) {
        return -1;
      } else if (x[key] > y[key]) {
        return 1;
      }
      return 0;
    });
  }

  groupBy(a: Array<any>, key: string) {
    const ret = {};
    a.forEach(e => {
      const keyValue = e[key];
      if (!ret[keyValue]) {
        ret[keyValue] = [];
      }
      ret[keyValue].push(e);
    });
    return ret;
  }

  private getRemovedDiff(a1: Array<any>, a2: Array<any>, key: string) {
    const ret = [];
    a1.forEach((e1: any) => {
      const found = a2.find((e2: any) => {
        return e2[key].toString() === e1[key].toString();
      });
      if (!found) {
        ret.push(e1);
      }
    });
    return ret;
  }
}
