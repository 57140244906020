import {Component, Input, NgZone, OnInit, ViewChild} from "@angular/core";
import {LoadState} from "core";
import {ActionBarItem, ActionBarItemType} from "shared";
import {PdfJsViewerComponent} from "ng2-pdfjs-viewer";
import {Observable} from "rxjs";

@Component({
  selector: "app-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html"
})
export class PdfViewerComponent implements OnInit {
  @Input() pdfSrc$: string; // Observable<string>;

  @Input() set downloadFileName(value: string) {
    this._downloadFileName = value
      .trim()
      .replace(/ +/g, "-")
      .replace(/[^a-z0-9-]/gi, "-");
  }

  @Input() locale: string;

  @ViewChild("pdfViewer") pdfViewer: PdfJsViewerComponent;
  pdfToolbarActions: Array<ActionBarItem> = [];
  loadState = new LoadState();
  private pageCounterAction: ActionBarItem = {
    type: ActionBarItemType.Label,
    cssClass: "font-size-sm py-1",
    title: "0 / 0"
  };
  protected _downloadFileName: string;

  constructor(private ngZone: NgZone) {
  }

  ngOnInit() {
    setTimeout(() => {
      if (!this.checkPdfLoadStatus()) {
        this.onPdfError();
      }
    }, 10000);
  }

  private getPdfActions(): Array<ActionBarItem> {
    return [{
      type: ActionBarItemType.Link,
      icon: "ellipsis-horizontal",
      actions: [{
        type: ActionBarItemType.Link,
        title: "documents.print",
        icon: "print",
        isHidden: !this.loadState.isOk,
        handler: this.pdfDocumentPrint.bind(this),
        hotKey: "alt.p"
      }, {
        type: ActionBarItemType.Link,
        title: "documents.download",
        icon: "caret-down",
        isHidden: !this.loadState.isOk,
        handler: this.pdfDocumentDownload.bind(this),
        hotKey: "alt.d"
      }, {
        type: ActionBarItemType.Link,
        title: "documents.searchInto",
        icon: "search",
        isHidden: !this.loadState.isOk,
        handler: this.pdfDocumentSearchToggle.bind(this),
        hotKey: "alt.g"
      }]
    }, {
      type: ActionBarItemType.Separator
    }, {
      type: ActionBarItemType.Link,
      icon: "add-circle-outline",
      handler: this.pdfDocumentZoomIn.bind(this),
      hotKey: "control.="
    }, {
      type: ActionBarItemType.Link,
      icon: "ellipse-outline",
      handler: this.pdfDocumentZoomFit.bind(this)
    }, {
      type: ActionBarItemType.Link,
      icon: "remove-circle-outline",
      handler: this.pdfDocumentZoomOut.bind(this),
      hotKey: "control.-"
    }, {
      type: ActionBarItemType.Separator
    }, {
      type: ActionBarItemType.Link,
      icon: "chevron-back-outline",
      handler: this.pdfDocumentPreviousPage.bind(this)
    }, this.pageCounterAction, {
      type: ActionBarItemType.Link,
      icon: "chevron-forward-outline",
      handler: this.pdfDocumentNextPage.bind(this)
    }];
  }

  onPdfLoaded(pageCount: number) {
    // https://coveragereportspdfjs.netlify.com/pdf.js/build/lib/web/app.js.html
    // this.pdfViewer.PDFViewerApplication.eventBus.on("zoomin", () => {
    // });

    setTimeout(() => {
      if (this.pdfViewer.PDFViewerApplication) {
        this.pdfViewer.PDFViewerApplication.pdfSidebar.isOpen = true;
        this.pdfViewer.PDFViewerApplication.appConfig.appContainer.addEventListener("keydown", this.onPdfKeyDown.bind(this));
      }
      // this.pdfViewer.PDFViewerApplication.appConfig.appContainer.addEventListener("mouseup", this.onPdfMouseUp.bind(this));
      this.onPdfPageChange(1);
      this.loadState.setOk();
      this.pdfToolbarActions = this.getPdfActions();
    }, 100);
  }

  onPdfPageChange(page: number) {
    if (this.pdfViewer.PDFViewerApplication) {
      this.pageCounterAction.title = `${page} / ${this.pdfViewer.PDFViewerApplication.pagesCount}`;
    }
  }

  private checkPdfLoadStatus(): boolean {
    if (!this.pdfViewer || !this.pdfViewer.PDFViewerApplication) {
      return false;
    }
    if (this.pdfViewer.PDFViewerApplication.appConfig.appContainer.className === "loadingInProgress") {
      return false;
    }
    return true;
  }

  private onPdfError() {
    this.loadState.setError(new Error("PDF Error"));
    this.pdfToolbarActions = this.getPdfActions();
  }

  private onPdfKeyDown($event: KeyboardEvent) {
    this.ngZone.run(() => {
      window.document.dispatchEvent(new KeyboardEvent($event.type, $event));
    });
  }

  private pdfDocumentPrint() {
    this.pdfViewer.PDFViewerApplication.appConfig.appContainer.ownerDocument.defaultView.print();
  }

  private pdfDocumentDownload() {
    this.pdfViewer.PDFViewerApplication.download();
  }

  private pdfDocumentSearchToggle() {
    const classList = this.pdfViewer.PDFViewerApplication.appConfig.findBar.bar.classList;
    if (classList.contains("hidden")) {
      classList.remove("hidden");
    } else {
      classList.add("hidden");
    }
  }

  private pdfDocumentZoomIn() {
    this.pdfViewer.PDFViewerApplication.zoomIn();
  }

  private pdfDocumentZoomOut() {
    this.pdfViewer.PDFViewerApplication.zoomOut();
  }

  private pdfDocumentZoomFit() {
    this.pdfViewer.PDFViewerApplication.zoomReset();
  }

  private pdfDocumentPreviousPage() {
    if (this.pdfViewer.page > 1) {
      this.pdfViewer.page--;
    }
  }

  private pdfDocumentNextPage() {
    if (this.pdfViewer.page < this.pdfViewer.PDFViewerApplication.pagesCount) {
      this.pdfViewer.page++;
    }
  }
}
