import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from "@angular/core";
import {MarvinImageProcessor, ImageProcessorService, OcrOptimizationParameters, OcrOptimizationResult, OcrOptimizer, Rectangle} from "core";

@Component({
  selector: "app-image-processor",
  templateUrl: "./image-processor.component.html",
  styleUrls: ["./image-processor.component.scss"]
})
export class ImageProcessorComponent implements AfterViewInit, OnChanges {
  @Input() imageUrl;
  @ViewChild("canvas") canvasRef: ElementRef;
  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;

  ip: MarvinImageProcessor;
  ocrOptimizer: OcrOptimizer;
  ocrParams: OcrOptimizationParameters = OcrOptimizer.defaultOcrOptimizationParameters;
  ocrResult: OcrOptimizationResult;

  constructor(private ipService: ImageProcessorService) {
  }

  ngAfterViewInit(): void {
    this.canvas = <HTMLCanvasElement>this.canvasRef.nativeElement;
    this.ctx = this.canvas.getContext("2d");
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ipService.createMarvinFromUrl(this.imageUrl)
      .subscribe((ip: MarvinImageProcessor) => {
        this.ip = ip;
        this.ocrOptimizer = new OcrOptimizer(ip);
        this.ocrEvaluate();
      });
  }

  reset() {
    this.ip.reset().subscribe(() => {
      this.ip.drawImage(this.canvas);
      this.ocrResult = null;
    });
  }

  ocrEvaluate() {
    this.ocrOptimizer.ocrOptimize(this.ocrParams).subscribe((ocrResult: OcrOptimizationResult) => {
      this.ocrResult = ocrResult;
      this.ip.reset().subscribe(() => {
        if (this.ip.getImageSize().width > Number(this.ocrParams.maxWidth)) {
          this.ip.scaleByWidth(Number(this.ocrParams.maxWidth));
        }
        this.ip.drawImage(this.canvas);
        this.ocrResult.textRegions.forEach((r: Rectangle) => {
          this.drawRect(r, "#a1618e");
        });
        this.drawRect(this.ocrResult.cropRectangle, "#ff0000");
      });
    });
  }

  ocrOptimize() {
    this.ocrOptimizer.ocrOptimize(this.ocrParams).subscribe((ocrResult: OcrOptimizationResult) => {
      this.ocrResult = ocrResult;
      this.ip.drawImage(this.canvas);
    });
  }

  drawRect(r: Rectangle, color) {
    this.ctx.beginPath();
    this.ctx.strokeStyle = color;
    this.ctx.lineWidth = 1;
    this.ctx.rect(r.x1, r.y1, (r.x2 - r.x1), (r.y2 - r.y1));
    this.ctx.stroke();
  }

}
