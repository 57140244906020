import {Observable} from "rxjs";
import {MarvinImageProcessor, Rectangle, RGB} from "./marvin-image-processor";

export interface OcrOptimizationParameters {
  maxWidth?: number;
  threshold?: number;
  maxWhiteSpace?: number;
  maxFontLineWidth?: number;
  minTextWidth?: number;
  brightness?: number;
  contrast?: number;
}

export interface OcrOptimizationResult {
  textRegions?: Array<Rectangle>;
  cropRectangle?: Rectangle;
  cropPercentage?: number;
  isValidCrop?: boolean;
  averageColor?: RGB;
  brightnessPercentage?: number;
  grayVariationPercentage?: number;
  isValidGrayVariation?: boolean;
  textCoveragePercentage?: number;
  isValidTextCoverage?: boolean;
  isValid: boolean;
}

export class OcrOptimizer {
  static defaultOcrOptimizationParameters: OcrOptimizationParameters = {
    maxWidth: 2000,
    threshold: 155,
    maxWhiteSpace: 25, // 40,
    maxFontLineWidth: 8, // 20,
    minTextWidth: 40, // 50
  };

  constructor(private ip: MarvinImageProcessor) {
  }

  ocrOptimize(params: OcrOptimizationParameters = {}): Observable<OcrOptimizationResult> {
    params = Object.assign(params, OcrOptimizer.defaultOcrOptimizationParameters);
    return new Observable<OcrOptimizationResult>(o => {
      this.ip.reset()
        .subscribe(() => {
          let imageSize = this.ip.getImageSize();
          if (imageSize.width > Number(params.maxWidth)) {
            this.ip.scaleByWidth(Number(params.maxWidth));
            imageSize = this.ip.getImageSize();
          }

          let averageColor = this.ip.averageColor();
          let grayVariationPercentage = this.computeGrayVariationPercentage(averageColor);
          let isValidGrayVariation = grayVariationPercentage > 90;

          if (isValidGrayVariation && !this.ip.isPng()) {
            averageColor = this.adjustBrightness();
            grayVariationPercentage = this.computeGrayVariationPercentage(averageColor);
          }
          const brightnessPercentage = this.computeBrightnessPercentage(averageColor);
          isValidGrayVariation = grayVariationPercentage > 90;

          const textRegions = isValidGrayVariation
            ? this.ip.findTextRegions(params.maxWhiteSpace, params.maxFontLineWidth, params.minTextWidth, params.threshold)
              .filter(r => r.height > 5 && r.width > params.minTextWidth)
            : [];

          const cropRectangle = this.expandRectangle(this.getSurroundingRectangle(textRegions), 10, imageSize.width, imageSize.height);
          const cropArea = (cropRectangle.y2 - cropRectangle.y1) * (cropRectangle.x2 - cropRectangle.x1);
          const cropPercentage = this.round2(cropArea * 100 / this.ip.computeArea());
          const isValidCrop = 50 < cropPercentage;

          let textCoveragePercentage = 0;
          let isValidTextCoverage = false;

          if (isValidCrop) {
            this.ip.crop(cropRectangle);
            this.ip.thresholding(params.threshold);
            const averageColor1 = this.ip.averageColor();
            const blackLevel = 255 - averageColor1.r;
            textCoveragePercentage = this.round2(blackLevel * 100 / 255);
            isValidTextCoverage = 1 < textCoveragePercentage && textCoveragePercentage < 40;
          }

          const res: OcrOptimizationResult = {
            textRegions,
            cropRectangle,
            cropPercentage,
            averageColor,
            brightnessPercentage,
            grayVariationPercentage,
            isValidGrayVariation,
            textCoveragePercentage,
            isValidCrop,
            isValidTextCoverage,
            isValid: isValidCrop && isValidTextCoverage
          };

          if (!res.isValid) {
            o.next(res);
            return o.complete();
          }

          this.optimizeSize(params)
            .subscribe(() => {
              this.ip.crop(cropRectangle);
              o.next(res);
              o.complete();
            });
        });
    });
  }

  optimizeSize(params: OcrOptimizationParameters = {}): Observable<OcrOptimizationResult> {
    params = Object.assign(params, OcrOptimizer.defaultOcrOptimizationParameters);
    return new Observable<OcrOptimizationResult>(o => {
      this.ip.reset()
        .subscribe(() => {
          const imageSize = this.ip.getImageSize();
          if (imageSize.width > Number(params.maxWidth)) {
            this.ip.scaleByWidth(Number(params.maxWidth));
          }
          o.next({isValid: true});
          o.complete();
        });
    });
  }

  private adjustBrightness(): RGB {
    // return;
    while (true) {
      const averageColor = this.ip.averageColor();
      const brightnessPercentage = this.computeBrightnessPercentage(averageColor);
      if (brightnessPercentage < 70) {
        this.ip.brightnessAndContrast(10, 0);
      } else {
        return averageColor;
      }
    }
  }

  private computeBrightnessPercentage(avg: RGB): number {
    return this.round2(((avg.r + avg.g + avg.b) / 3) * 100 / 255);
  }

  private computeGrayVariationPercentage(avg: RGB): number {
    const d1 = Math.abs(avg.r - avg.g);
    const d2 = Math.abs(avg.r - avg.b);
    const d3 = Math.abs(avg.g - avg.b);
    return this.round2(100 - (((d1 + d2 + d3) * 100 / (255 * 3))));
  }

  private round2(value: number): number {
    return Math.round(value * 100) / 100;
  }

  private getSurroundingRectangle(rectangles: Array<Rectangle>): Rectangle {
    const max = 99999999;
    let x1 = max, y1 = max, x2 = 0, y2 = 0;
    rectangles.forEach(r => {
      if (r.x1 < x1) {
        x1 = r.x1;
      }
      if (r.y1 < y1) {
        y1 = r.y1;
      }
      if (r.x2 > x2) {
        x2 = r.x2;
      }
      if (r.y2 > y2) {
        y2 = r.y2;
      }
    });
    if (x1 === max) {
      x1 = 0;
    }
    if (x2 === max) {
      x2 = 0;
    }
    return {x1, y1, x2, y2};
  }

  private expandRectangle(rectangle: Rectangle, offset: number, maxX2: number, maxY2: number): Rectangle {
    let x1 = rectangle.x1 - offset;
    let y1 = rectangle.y1 - offset;
    let x2 = rectangle.x2 + offset;
    let y2 = rectangle.y2 + offset;
    if (x1 < 0) {
      x1 = 0;
    }
    if (y1 < 0) {
      y1 = 0;
    }
    if (x2 > maxX2) {
      x2 = maxX2;
    }
    if (y2 > maxY2) {
      y2 = maxY2;
    }
    return {x1, y1, x2, y2};
  }

}
