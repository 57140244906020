import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FileModel, LoadState} from "core";
import {FilePickerService, PickedFiles} from "shared";
import {filter} from "rxjs/operators";

@Component({
  selector: "app-photo-change",
  templateUrl: "./photo-change.component.html",
  styleUrls: ["./photo-change.component.scss"]
})
export class PhotoChangeComponent implements OnInit {
  @Input() url: string;
  @Input() readOnly = false;
  @Input() loadState = new LoadState();
  @Output() filePicked = new EventEmitter<FileModel>();
  @Output() reset = new EventEmitter();

  constructor(private filePickerService: FilePickerService) {
  }

  ngOnInit() {
    this.loadState.setOk();
  }

  pickFile() {
    this.filePickerService.pickFromDesktopFileSystem("image/jpeg,image/png,image/svg+xml")
      .pipe(filter((pickedFiles: PickedFiles) => pickedFiles.status === "finished"))
      .subscribe((pickedFiles: PickedFiles) => {
        this.filePicked.emit(pickedFiles.files[0]);
      });
  }
}
