import {Component, Input, OnInit} from "@angular/core";
import {AbstractControl, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

interface Error {
  key: string;
  value: any;
}

@Component({
  selector: "app-form-controls-errors",
  templateUrl: "./form-control-errors.component.html"
})
export class FormControlErrorsComponent implements OnInit {
  @Input() control?: AbstractControl;
  @Input() form?: FormGroup;
  @Input() showUntouched?: boolean;

  errorControls: Array<AbstractControl | FormGroup>;
  private controlName: string;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.errorControls = [];
      if (this.control) {
        this.controlName = this.getControlName(this.control);
        this.errorControls.push(this.control);
      }
      if (this.form) {
        this.errorControls.push(this.form);
      }
    }, 1000);
  }

  private getControlName(control: AbstractControl): string {
    const parent = control.parent;

    let ret = null;
    if (parent instanceof FormGroup) {
      Object.keys(parent.controls).forEach((name: string) => {
        if (this.control === parent.controls[name]) {
          ret = name;
        }
      });
    }
    return ret;
  }

  getErrorMessage(error: Error, control: AbstractControl | FormGroup) {
    if (!control.touched && !this.showUntouched) {
      return null;
    }
    const key = error.key.toLowerCase();

    if (key === "required") {
      return this.translateService.instant("ng.validation.required", {
        field: this.controlName
      });
    }
    if (key.toLowerCase() === "requiredtrue") {
      return this.translateService.instant("ng.validation.requiredtrue", {
        field: this.controlName
      });
    }
    if (key === "email") {
      return this.translateService.instant("ng.validation.email", {
        field: this.controlName
      });
    }
    if (key === "minlength") {
      return this.translateService.instant("ng.validation.minlength", {
        field: this.controlName,
        requiredLength: error.value.requiredLength
      });
    }
    if (key === "maxlength") {
      return this.translateService.instant("ng.validation.maxlength", {
        field: this.controlName,
        requiredLength: error.value.requiredLength
      });
    }
    if (key === "min") {
      return this.translateService.instant("ng.validation.min", {
        field: this.controlName,
        min: error.value.min
      });
    }
    if (key === "max") {
      return this.translateService.instant("ng.validation.max", {
        field: this.controlName,
        max: error.value.max
      });
    }

    return this.translateService.instant(this.removeHtmlTags(error.value.message)) || JSON.stringify(error);
  }

  private removeHtmlTags(string) {
    return string.replace(/<[^>]*>/g, " ")
      .replace(/\s{2,}/g, " ")
      .trim();
  }
}
