import {Component, Input} from "@angular/core";

@Component({
  selector: "app-dashboard-button",
  templateUrl: "./dashboard-button.component.html"
})
export class DashboardButtonComponent {
  @Input() text: string;
  @Input() icon: string;
  @Input() bgIconClass: string;
  @Input() value: string | number;
  @Input() routerLink: string;
}
