import {Extension} from "@tiptap/core";
import {Plugin, PluginKey} from "prosemirror-state";
import {Slice} from "prosemirror-model";
import {DOMParser as ProseMirrorDOMParser} from "prosemirror-model";

function arrayBufferToString(arrayBuffer: ArrayBuffer) {
  const typedArray = new Uint8Array(arrayBuffer);
  return typedArray.reduce((data, byte) => data + String.fromCharCode(byte), "");
}

function base64ToDataUrl(base64: string, type: string) {
  return `data:${type};base64,${base64}`;
}

const PasteExt = Extension.create({
  name: "pasteExt",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("handlePastePlugin"),
        props: {
          handlePaste: (view, event, slice) => {
            try {
              event.preventDefault();

              const {schema} = view.state;

              const clipboardData = event.clipboardData;
              const pastedHtml = clipboardData.getData("text/html");
              const hasFiles = !!event.clipboardData?.files?.length;

              if (pastedHtml) {
                const doc = new DOMParser().parseFromString(pastedHtml, "text/html");
                const b64images = Array.from(doc.querySelectorAll("img[src^=\"data:image/\"]"));
                const images = Array.from(doc.querySelectorAll("img[src^=\"http\"]"));

                if (images.length) {
                  [].concat(images).concat(b64images).forEach((img: any) => {
                    const newImg = img.ownerDocument.createElement("app-tiptap-node-image");
                    newImg.setAttribute("imageSrc", img?.src);
                    newImg.setAttribute("imageWidth", img?.width || 100);
                    newImg.setAttribute("imageHeight", img?.height || 100);

                    img.parentElement.insertBefore(newImg, img);
                    img.parentElement.removeChild(img);
                  });

                  // Convert the updated DOM to ProseMirror nodes
                  const pmDoc = ProseMirrorDOMParser.fromSchema(schema).parse(doc.body);
                  const fragment = pmDoc.content;
                  const newSlice = new Slice(fragment, slice.openStart, slice.openEnd);
                  const transaction = view.state.tr.replaceSelection(newSlice);
                  view.dispatch(transaction);
                  return true;
                }
              } else if (hasFiles) { // https://github.com/ueberdosis/tiptap/issues/2912#issuecomment-1169631614
                const images = Array.from(event.clipboardData.files)
                  .filter(file => /image/i.test(file.type));
                images.forEach((f: File) => {
                  const reader = new FileReader();
                  reader.onload = () => {
                    const src = arrayBufferToString(reader.result as ArrayBuffer);
                    const isSvg = src.toLowerCase().includes("</svg>");
                    const node = schema.nodes.image.create({
                      imageSrc: isSvg
                        ? `data:image/svg+xml,${encodeURIComponent(src)}`
                        : base64ToDataUrl(btoa(src), f.type),
                      imageWidth: "100",
                      imageHeight: "100"
                    });
                    const transaction = view.state.tr.replaceSelectionWith(node);
                    view.dispatch(transaction);
                  };
                  reader.readAsArrayBuffer(f);
                });
                return true;
              }
              return false;
            } catch (ex) {
              console.error(ex);
              alert("Paste operation failed.\nPlease try again with a smaller content.");
              return true;
            }
          }
        }
      })
    ];
  }
});

export default PasteExt;
