<div class="w-100 h-100 d-flex flex-row align-items-center justify-content-between cursor-pointer">
  <ion-input [value]="dateText"
             [readonly]="true"
             [label]="label"
             labelPlacement="floating"
             (click)="inputClick.emit($event)">
  </ion-input>
  <ion-icon icon="calendar-outline" class="pr-1 sqr-xs" id="{{triggerElId}}" (click)="startSelection($event)"></ion-icon>
  <ion-icon icon="close-circle-outline" class="pl-1 sqr-xs" (click)="resetValue()"></ion-icon>
</div>
<ion-popover trigger="{{triggerElId}}" show-backdrop="false" alignment="start">
  <ng-template>
    <ion-datetime [presentation]="presentation"
                  [hourCycle]="hourCycle"
                  [readonly]="readOnly || isDisabled"
                  [preferWheel]="!isDesktop"
                  [firstDayOfWeek]="1"
                  [formControl]="formControl"
                  (ionChange)="setDate()">
    </ion-datetime>
  </ng-template>
</ion-popover>
