export class Pagination {
  private _recordCount: number;
  private readonly _pageSize: number;

  public page = 1;
  public pageCount: number;

  constructor(pageSize: number = 10) {
    this._pageSize = pageSize;
  }

  get recordCount(): number {
    return this._recordCount;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  set recordCount(value: number) {
    this._recordCount = value;
    this.pageCount = Math.ceil(this._recordCount / this._pageSize);
  }

  increment() {
    this.page++;
  }

  decrement() {
    this.page--;
  }

  reset() {
    this.page = 1;
  }
}

