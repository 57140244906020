import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {HotkeyMap, HotkeysService, SubSink} from "core";

@Component({
  selector: "app-hotkeys-cheatsheet",
  templateUrl: "./hotkeys-cheatsheet.component.html",
  styleUrls: ["./hotkeys-cheatsheet.component.scss"]
})
export class HotkeysCheatsheetComponent implements OnInit, OnDestroy {
  @Input() hotkeysMap: Array<HotkeyMap>;

  hotKeys: Array<{description: string, hotKeys: Array<string>, icon: string}>;
  private sub = new SubSink();

  constructor(private modalController: ModalController,
              private hotKeysService: HotkeysService) {
  }

  ngOnInit() {
    this.hotKeys = this.hotkeysMap.reduce((cur: any, hkm: HotkeyMap) => {
      const found = cur.find(x => x.description === hkm.description);
      if (found) {
        found.hotKeys.push(hkm.hotKey);
      } else {
        cur.push({description: hkm.description, hotKeys: [hkm.hotKey], icon: hkm.icon});
      }
      return cur;
    }, []);
    this.hotKeysService.addShortcuts([{hotKey: "esc"}])
      .subscribe(x => this.sub.sink = x.subscribe(() => this.modalController.dismiss()));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
