import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class UUidService {
  v4(): string {
    const stringArr = [];
    for (let i = 0; i < 4; i++) {
      const s4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(s4);
    }
    return stringArr.join("-");
  }

  number(): number {
    return parseInt(Date.now().toString() + Math.floor(Math.random() * 1000000).toString(), 0);
  }
}
