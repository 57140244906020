import packageInfo from "../../../../package.json";

export const environment = {
  version: packageInfo.version,
  name: "web.test",
  production: true,
  isWeb: true,
  isDesktop: false,
  baseUrl: "https://p8pcrl506m.execute-api.eu-central-1.amazonaws.com/",
  wsUrl: "wss://wgnpk1ejzh.execute-api.eu-central-1.amazonaws.com/test",
  facebookClientId: "1075943315880101",
  googleClientId: "1033597755510-ngc5f3cj4h97d88udn2h0ue1gs71bdf9.apps.googleusercontent.com",
  gaTrackingCode: "G-DY831ZFNGD"
};
