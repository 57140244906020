import {Pipe, PipeTransform} from "@angular/core";
import defaultExport from "dayjs";
import plugin from "dayjs/plugin/relativeTime";

@Pipe({name: "amFromNow"})
export class FromNowPipe implements PipeTransform {
  transform(value: any, withoutSuffix?: boolean): string {
    defaultExport.extend(plugin);
    return defaultExport(value).fromNow(withoutSuffix);
  }
}
