import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
  ApiService,
  ImageProcessorService,
  ImageProcessor,
  Pagination,
  HateoasList,
  FileModel,
  IdentityUser
} from "core";
import {Address, AppMessage} from "shared";
import {AccountEvent, UserProfile, UserSettings} from "./account.model";
import {AccountEventsFilter} from "account/account-events/account-events-filter";

@Injectable({providedIn: "root"})
export class AccountApiService {
  constructor(private apiService: ApiService,
              private ipService: ImageProcessorService) {
  }

  readAccount(): Observable<UserProfile> {
    return this.apiService.get("account", null);
  }

  deleteAccount(): Observable<UserProfile> {
    return this.apiService.delete("account", null);
  }

  updatePhoto(file: FileModel): Observable<string> {
    return new Observable(o => {
      if (!file) {
        this.apiService.delete("account/photo", {})
          .subscribe((res: any) => {
            o.next(res.photoUrl);
            o.complete();
          }, err => {
            o.error(err);
            o.complete();
          });
        return;
      }
      this.ipService.createFromFileModel(file)
        .subscribe((ip: ImageProcessor) => {
          ip.fixOrientation()
            .subscribe(() => {
              const fixedFile = ip.toFile(file.name);
              const formData = new FormData();
              formData.append("file", fixedFile, fixedFile.name);
              this.apiService.put("account/photo", null, formData)
                .subscribe((res: any) => {
                  o.next(res.photoUrl);
                  o.complete();
                }, err => {
                  o.error(err);
                  o.complete();
                });
            });
        });
    });
  }

  updatePassword(passwordData): Observable<any> {
    return this.apiService.put("account/password", null, passwordData);
  }

  updateEmail(emailData): Observable<any> {
    return this.apiService.put("account/email", null, emailData);
  }

  updateProfile(profileData): Observable<UserProfile> {
    return this.apiService.patch("account/profile", null, profileData);
  }

  updateAddress(addressData): Observable<Address> {
    return this.apiService.put("account/address", null, addressData);
  }

  updateSettings(settingsData): Observable<UserSettings> {
    return this.apiService.put("account/settings", null, settingsData);
  }

  readEvents(filter: AccountEventsFilter = null, pagination: Pagination): Observable<HateoasList<AccountEvent>> {
    const qry = ApiService.buildQuery([{
      page: pagination?.page,
      pageSize: pagination?.pageSize,
      search: filter?.search
    }]);
    return this.apiService.get(`account/events?${qry}`, null);
  }

  readEmailAddressBook(): Observable<HateoasList<AppMessage>> {
    return this.apiService.get("account/address-book", null);
  }

  readInvoicingData() {
    return this.apiService.get("account/invoicing", null);
  }

  updateInvoicingData(invoicingData: any) {
    return this.apiService.put("account/invoicing", null, invoicingData);
  }

  readUser(userId: string): Observable<IdentityUser> {
    return this.apiService.get("users/{userId}", {userId});
  }
}
