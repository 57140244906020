<ion-item *ngIf="!isAnimated"
          class="input"
          [class.no-border]="!hasBorder">
  <ion-icon *ngIf="iconName"
            [name]=iconName
            class="mr-1 sqr-xs">
  </ion-icon>
  <ion-input [formControl]="formControl"
             class="m-0"
             [appAutofocus]="autofocus"
             [placeholder]="placeholder | translate"
             (keyup.enter)="submitSearch()">
  </ion-input>
  <div *ngIf="clearIconName"
       class="d-flex align-items-center justify-content-end h-100 width-sm">
    <ion-icon *ngIf="formControl.value?.trim()"
              [name]="clearIconName"
              class="cursor-pointer sqr-xs"
              (click)="clear()">
    </ion-icon>
  </div>
</ion-item>

<div *ngIf="isAnimated" class="position-relative d-flex align-items-center justify-content-center">
  <ion-button [ngClass]="cssClass"
              [color]="color"
              (click)="showInputBox(true)">
    <ion-icon [ngClass]="iconCssClass"
              [name]="iconName || 'search'">
    </ion-icon>
  </ion-button>
  <ion-item *ngIf="isInputBoxVisible"
            class="input position-absolute overflow-visible right-0 animated faster fadeInExpandRight"
            [class.no-border]="!hasBorder">
    <ion-icon *ngIf="iconName"
              [name]=iconName
              class="mr-1 sqr-xs">
    </ion-icon>
    <ion-input #animatedInput
               [formControl]="formControl"
               class="m-0"
               [appAutofocus]="autofocus"
               [placeholder]="placeholder | translate"
               (focusout)="showInputBox(false)"
               (keydown.escape)="showInputBox(false)"
               (keyup.enter)="submitSearch()">
    </ion-input>
    <div *ngIf="clearIconName"
         class="d-flex align-items-center justify-content-end h-100 width-sm">
      <ion-icon [name]=clearIconName
                class="cursor-pointer sqr-xs"
                (click)="clear()">
      </ion-icon>
    </div>
  </ion-item>
</div>
