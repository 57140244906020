import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild} from "@angular/core";
import {Platform} from "@ionic/angular";
import {DomUtilsService, LoadState, ScrollAntiBlock} from "core";

@Component({
  selector: "app-modal-wrapper",
  templateUrl: "./modal-wrapper.component.html",
  styleUrls: ["./modal-wrapper.component.scss"]
})
export class ModalWrapperComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() hasClose = true;
  @Input() hasHelp = true;
  @Input() hasFooter = false;
  @Input() hasShortcut = false;
  @Input() isScrollAntiBlockDisabled = false;
  @Input() loadState: LoadState;
  @Output() close = new EventEmitter<any>();
  @Output() help = new EventEmitter<any>();
  @Output() titleClick = new EventEmitter<any>();
  @Output() shortcut = new EventEmitter<any>();

  @ViewChild("elModalContent", {static: true}) elModalContent: ElementRef;

  private scrollAntiBlock: ScrollAntiBlock;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private platform: Platform,
              private domUtilsService: DomUtilsService) {
  }

  headerTouchStart($event: TouchEvent) {
    $event.preventDefault();
  }

  ngOnInit() {
    this.renderer.addClass(document.body, "overflow-hidden");
  }

  ngOnDestroy() {
    const bodyHasAnotherModal = !!this.domUtilsService.findChildByPredicate(document.body, (e: Element) => e.localName === "app-modal-wrapper");
    if (!bodyHasAnotherModal) {
      this.renderer.removeClass(document.body, "overflow-hidden");
    }
    if (this.scrollAntiBlock) {
      this.scrollAntiBlock.destroy();
    }
  }

  domChanged($event: MutationRecord) {
    if (this.isScrollAntiBlockDisabled) {
      return;
    }
    const hasItemListInside = !!this.domUtilsService.findChildByPredicate(this.el.nativeElement, (e: Element) => e.localName === "app-items-list");
    if (hasItemListInside) {
      if (this.scrollAntiBlock) {
        this.scrollAntiBlock.destroy();
      }
      this.scrollAntiBlock = null;
    } else {
      if (!this.scrollAntiBlock) {
        this.scrollAntiBlock = new ScrollAntiBlock(this.elModalContent.nativeElement, 1, 150, true, true, this.renderer, this.platform, this.domUtilsService);
        this.scrollAntiBlock.apply();
      }
    }
  }
}
