import {Component, Input, ViewChild} from "@angular/core";
import {ActionBarItem, ActionDropdownOptionsComponent} from "shared";
import {HotkeyEvent} from "core";

@Component({
  selector: "app-type-ahead-options",
  templateUrl: "./type-ahead-options.component.html",
  styleUrls: ["./type-ahead-options.component.scss"]
})
export class TypeAheadOptionsComponent {
  @Input() actions: Array<ActionBarItem>;
  @ViewChild(ActionDropdownOptionsComponent) dropdownOptionsComponent: ActionDropdownOptionsComponent;

  constructor() {
  }

  async handleHotkey(e: HotkeyEvent) {
    await this.dropdownOptionsComponent.handleHotkey(e);
  }

  hasActions() {
    return !!this.actions?.length;
  }
}
