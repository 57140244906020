import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Injectable({providedIn: "root"})
export class TranslateInterpolation {
  constructor(private translateService: TranslateService) {
  }

  private generateInterpolationParameters(pipedString: string): {key: string, interpolationParams: any} {
    if (!pipedString) {
      return {
        key: "",
        interpolationParams: null
      };
    }
    const split = pipedString.split("|");
    const interpolationArray = split.slice(1);
    const interpolationParams = {};
    interpolationArray.forEach((val, idx) => {
      interpolationParams[`v${idx}`] = val
        ? this.translateService.instant(val)
        : "";
    });
    return {
      key: split[0],
      interpolationParams
    };
  }

  translate(pipedString: string): string {
    const interpolation = this.generateInterpolationParameters(pipedString);
    if (!interpolation.key) {
      return pipedString;
    }
    return this.translateService.instant(interpolation.key, interpolation.interpolationParams);
  }

  translateArray(pipedStrings: Array<string>): Array<string> {
    if (!pipedStrings) {
      return null;
    }
    const ret = [];
    pipedStrings.forEach((x: string) => {
      const interpolation = this.generateInterpolationParameters(x);
      ret.push(!interpolation.key ? x : this.translateService.instant(interpolation.key, interpolation.interpolationParams));
    });
    return ret;
  }
}
