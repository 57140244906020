<div class="d-flex">

  <div *ngIf="ocrOptimizer" class="">
    <ion-button (click)="reset()">Reset</ion-button>
    <ion-button [disabled]="ocrResult && !ocrResult.isValid" (click)="ocrOptimize()">Apply</ion-button>

    <div>
      <label>Max width</label>
      <input [(ngModel)]="ocrParams.maxWidth"
             (change)="ocrEvaluate()"
             type="number"
             placeholder="New width">
    </div>

    <div>
      <label>Brightness</label>
      <input [(ngModel)]="ocrParams.brightness"
             (change)="ocrEvaluate()"
             type="number"
             placeholder="Brightness">
    </div>

    <div>
      <label>Contrast</label>
      <input [(ngModel)]="ocrParams.contrast"
             (change)="ocrEvaluate()"
             type="number"
             placeholder="Contrast">
    </div>

    <div>
      <label>Max white space</label>
      <input [(ngModel)]="ocrParams.maxWhiteSpace"
             (change)="ocrEvaluate()"
             type="number"
             placeholder="Max white space">
    </div>

    <div>
      <label>Max font line width</label>
      <input [(ngModel)]="ocrParams.maxFontLineWidth"
             (change)="ocrEvaluate()"
             type="number"
             placeholder="Max font line width">
    </div>

    <div>
      <label>Min text width</label>
      <input [(ngModel)]="ocrParams.minTextWidth"
             (change)="ocrEvaluate()"
             type="number"
             placeholder="Min text width">
    </div>

    <div>
      <label>Grayscale treshold</label>
      <input [(ngModel)]="ocrParams.threshold"
             (change)="ocrEvaluate()"
             type="number"
             placeholder="Grayscale treshold">
    </div>
  </div>

  <div class="flex-grow-1">
    <div *ngIf="ocrOptimizer" class="form-inline">
      <div class="badge">{{ip.getImageSize().width}} x {{ip.getImageSize().height}}</div>
      <div *ngIf="ocrResult" class="badge">Color {{ocrResult.averageColor  | json}}%</div>
      <div *ngIf="ocrResult" class="badge">Brightness {{ocrResult.brightnessPercentage}}%</div>

      <div *ngIf="ocrResult" class="badge badge-outline"
           [ngClass]="ocrResult.isValidGrayVariation ? 'badge-success' : 'badge-danger'">
        Gray {{ocrResult.grayVariationPercentage}}%
      </div>
      <div *ngIf="ocrResult" class="badge badge-outline"
           [ngClass]="ocrResult.isValidCrop ? 'badge-success' : 'badge-danger'">
        Crop {{ocrResult.cropPercentage}}%
      </div>
      <div *ngIf="ocrResult" class="badge badge-outline"
           [ngClass]="ocrResult.isValidTextCoverage ? 'badge-success' : 'badge-danger'">
        Text coverage {{ocrResult.textCoveragePercentage}}%
      </div>
    </div>
    <canvas #canvas style="max-width: 100%;border: 1px solid #2b3d51;"></canvas>
  </div>

</div>
