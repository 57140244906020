import {Injectable, NgModule, Optional, SkipSelf} from "@angular/core";
import {RouterModule} from "@angular/router";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from "@angular/platform-browser";
import {Ng2UiAuthModule} from "@faubulous/ng2-ui-auth";
import {CookieService} from "ngx-cookie-service";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import * as Hammer from "hammerjs";

import {
  EnsureModuleLoadedOnceGuard,
  UnauthorizedInterceptor
} from "./";

import {AppConfig} from "app.config";

@Injectable({providedIn: "root"})
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pan: {enable: false},
    swipe: {enable: false},
    pinch: {enable: false},
    rotate: {enable: false}
  };

  buildHammer(element: HTMLElement) {
    return new Hammer(element, {
      touchAction: "pan-y"
    });
  }
}

const providers = [
  CookieService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UnauthorizedInterceptor,
    multi: true
  },
  {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }
];

@NgModule({
  imports: [
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    HammerModule,
    Ng2UiAuthModule.forRoot(AppConfig.authConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
