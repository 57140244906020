<div class="d-flex flex-column" [ngClass]="cssClass">
  <color-sketch [color]="formControl.value" (onChangeComplete)="handleColorChange($event)"></color-sketch>
  <div class="d-flex align-items-center justify-content-between mt-4">
    <ion-button class="d-block flex-grow-1 d-md-inline-block flex-md-grow-0"
                (click)="colorPicked()"
                translate="dict.ok">
    </ion-button>
    <ion-button color="light"
                class="sqr-md"
                (click)="resetColor()">
      <ion-icon name="trash"></ion-icon>
    </ion-button>
  </div>
</div>
