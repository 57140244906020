import {YearMonthPickerOptions, monthAbbrev, monthNames} from "./year-month.model";

export class NGXMonthYear {
  defaultOptions: YearMonthPickerOptions = {
    closeOnSelect: true,
    disabledDates: [],
    disabledDateRanges: [],
    format: "yyyy-mm",
    resetYearOnBlur: true,
    yearMin: null,
    yearMax: null,
    yearStart: null,
    selectRange: false
  };

  areEqual(d1: Date, d2: Date): boolean {
    return this.compare(d1, d2) === 0;
  }

  compare(d1: Date, d2: Date): number {
    return (d1?.getTime() || 0) - (d2?.getTime() || 0);
  }

  setDefaultOptions(options: YearMonthPickerOptions): YearMonthPickerOptions {
    if (options.yearStart && options.yearStart < options.yearMin) {
      throw new Error("ng-month-year-selector error: yearStart must be >= yearMin");
    }
    if (options.yearStart && options.yearStart > options.yearMax) {
      throw new Error("ng-month-year-selector error: yearStart must be <= yearMax");
    }
    Object.keys(this.defaultOptions).forEach(optKey => options[optKey] = options[optKey] ? options[optKey] : this.defaultOptions[optKey]);
    return options;
  }

  formatValue(format: string, date: Date): string {
    if (!date) {
      return "";
    }
    let returnStr = format;
    returnStr = returnStr.replace("yyyy", <any>date.getFullYear());
    returnStr = returnStr.replace("yy", <any>date.getFullYear().toString().slice(-2));
    returnStr = returnStr.replace("mmmm", <any>monthNames[date.getMonth()]);
    returnStr = returnStr.replace("mmm", <any>monthAbbrev[date.getMonth()]);
    returnStr = returnStr.replace("mm", ("0" + (date.getMonth() + 1)).slice(-2));
    return returnStr;
  }
}
