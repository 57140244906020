import {Injectable} from "@angular/core";
import {Howl} from "howler";

@Injectable({providedIn: "root"})
export class SoundService {
  playSound(soundFile: string) {
    // const sound = new Howl({
    //   src: [`assets/sounds/${soundFile}.mp3`]
    // });
    // sound.play();
  }
}
