import {Renderer2} from "@angular/core";
import {Platform} from "@ionic/angular";
import {fromEvent} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {SubSink} from "../subsink/subsink";
import {DomUtilsService} from "../dom/dom-utils.service";

export class ScrollAntiBlock {
  private sub = new SubSink();
  // private oldPaddingTop: string;
  // private oldPaddingBottom: string;

  constructor(private nativeElement: any,
              private scrollOffset: number,
              private debounce: number,
              private applyTop: boolean,
              private applyBottom: boolean,
              private renderer: Renderer2,
              private platform: Platform,
              private domUtils: DomUtilsService) {
  }

  apply() {
    // console.log("aplying scrollAntiblock");
    const isAndroid = this.platform.is("android");
    const isIos = this.platform.is("ios");
    const isDesktop = this.platform.is("desktop");

    // this.oldPaddingTop = this.nativeElement.style.paddingTop;
    // this.oldPaddingBottom = this.nativeElement.style.paddingTop;
    // console.log("aplying scrollAntiblock", this.oldPaddingTop, this.oldPaddingBottom);

    // this.renderer.setStyle(this.nativeElement, "padding-bottom", `${this.scrollOffset}px`);
    // this.renderer.setStyle(this.nativeElement, "padding-top", `${this.scrollOffset}px`);

    let isTouching = false;
    if (isIos || isAndroid) {
      this.sub.sink = fromEvent(this.nativeElement, "touchstart")
        .subscribe(() => isTouching = true);
      this.sub.sink = fromEvent(this.nativeElement, "touchend")
        .subscribe(() => isTouching = false);
      this.sub.sink = fromEvent(this.nativeElement, "scroll")
        // .pipe(tap((evt) => console.log(evt, isTouching)))
        .pipe(debounceTime(this.debounce))
        .subscribe((evt) => {
          if (isTouching) {
            return;
          }
          this.fixScroll();
        });
    }

    if (isDesktop) {
      this.sub.sink = fromEvent(this.nativeElement, "wheel", {capture: false, passive: false})
        .subscribe(($event: any) => {
          const direction = $event.wheelDeltaY < 0 ? "up" : "down";
          const isScrolledToBottom = this.domUtils.isScrolledToBottom(this.nativeElement, this.scrollOffset);
          const isScrolledToTop = this.domUtils.isScrolledToTop(this.nativeElement, this.scrollOffset);
          if (isScrolledToBottom && direction === "up" || isScrolledToTop && direction === "down") {
            $event.preventDefault();
            $event.stopPropagation();
            return;
          }
        });
    }
  }

  destroy(): void {
    this.sub.unsubscribe();
  }

  setAppliance(applyTop: boolean, applyBottom: boolean) {
    this.applyTop = applyTop;
    this.applyBottom = applyBottom;
  }

  private fixScroll() {
    if (this.applyBottom && this.domUtils.isScrolledToBottom(this.nativeElement, this.scrollOffset)) {
      // console.log("scrollToBottom", this.scrollOffset);
      this.domUtils.scrollToBottom(this.nativeElement, this.scrollOffset);
    }
    if (this.applyTop && this.domUtils.isScrolledToTop(this.nativeElement, this.scrollOffset)) {
      // console.log("scrollToTop", this.scrollOffset);
      this.domUtils.scrollToTop(this.nativeElement, this.scrollOffset);
    }
  }
}
