import {Component} from "@angular/core";
import {AngularNodeViewComponent} from "ngx-tiptap";
import {mergeAttributes} from "@tiptap/core";

@Component({
  selector: "app-tiptap-node-image",
  templateUrl: "./tiptap-node-image.component.html",
  styleUrls: ["./tiptap-node-image.component.scss"]
})
export class TiptapNodeImageComponent extends AngularNodeViewComponent {
  onImageResizeEnd($event: {width: number; height: number}) {
    const pos = this.editor.state.selection.from;
    this.editor.chain()
      .focus()
      .updateAttributes("image", mergeAttributes(this.editor.getAttributes("image"), {imageWidth: $event.width, imageHeight: $event.height}))
      .setNodeSelection(pos)
      .run();
  }
}
