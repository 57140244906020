import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {SwUpdate, VersionEvent} from "@angular/service-worker";
import {ModalController, Platform} from "@ionic/angular";
import {Plugins} from "@capacitor/core";
import {AppEventsService, InternetConnectionService, SubSink, HotkeyMap} from "core";
import {AppInfoComponent, ModalAnyComponent, ToasterService} from "shared";
import {HotkeysCheatsheetComponent} from "shared/hotkeys-cheatsheet/hotkeys-cheatsheet.component";
import {ScreenOrientation} from "@ionic-native/screen-orientation/ngx";
import {environment} from "environment";
import {filter} from "rxjs/operators";

const {SplashScreen} = Plugins;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  private sub = new SubSink();
  private isOnline = false;
  private isDesktop = this.platform.width() >= 768;

  constructor(private translateService: TranslateService,
              private modalController: ModalController,
              private swUpdate: SwUpdate,
              private platform: Platform,
              private internetConnection: InternetConnectionService,
              private appEventsService: AppEventsService,
              private screenOrientation: ScreenOrientation,
              private toasterService: ToasterService) {
    console.log(`Actas.${environment.name} ${this.isDesktop ? "web" : "mobile"}`);
    this.translateService.setDefaultLang("ro");
  }

  async ngOnInit() {
    if (environment.isWeb) {
      try {
        await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        await SplashScreen.hide();
      } catch (e) {
      }
      if (this.swUpdate.isEnabled) {
        this.sub.sink = this.swUpdate.versionUpdates
          .pipe(filter((evt: VersionEvent) => evt.type === "VERSION_READY"))
          .subscribe((evt: VersionEvent) => this.showAppUpgradeModal());
      }
    }
    // this.sub.sink = this.appEventsService.onAppLoaded()
    //   .subscribe(this.checkForUpdate.bind(this));
    // this.sub.sink = this.appEventsService.onAppShown()
    //   .subscribe(this.checkForUpdate.bind(this));
    this.sub.sink = this.internetConnection.observe()
      .subscribe((isOnline: boolean) => {
        if (isOnline) {
          this.appEventsService.notifyAppOnline();
        } else {
          this.appEventsService.notifyAppOffline();
        }
        if (!this.isOnline && isOnline) {
          console.log("Back online");
          this.appEventsService.notifyAppBackOnline();
        }
        this.isOnline = isOnline;
      });
    this.sub.sink = this.appEventsService.onShowShowHotkeysCheatsheet()
      .subscribe(this.showHotkeysCheatsheet.bind(this));

    if (this.isDesktop) {
      setTimeout(() => this.toasterService.default("app.notifications.hotkeys", {icon: "information-circle"}), 2500);
    }
  }

  ngAfterViewInit() {
    this.appEventsService.notifyAppLoaded();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async showAppUpgradeModal() {
    const modal = await this.modalController.create({
      component: ModalAnyComponent,
      cssClass: "responsive-modal h-sm",
      componentProps: {
        title: "appInfo.upgradeTitle",
        itemComponent: AppInfoComponent
      }
    });
    await modal.present();
  }

  async showHotkeysCheatsheet(hotKeys: Array<HotkeyMap>) {
    const hotkeysMap = hotKeys.filter(x => x.description || x.icon);
    if (!hotkeysMap.length) {
      return;
    }
    const modal = await this.modalController.create({
      component: ModalAnyComponent,
      cssClass: "responsive-modal h-lg w-md",
      componentProps: {
        title: "appInfo.hotkeysCheatSheetTitle",
        itemComponent: HotkeysCheatsheetComponent,
        itemProps: {hotkeysMap}
      }
    });
    await modal.present();
  }

  @HostListener("window:beforeunload", ["$event"])
  appUnloaded() {
    this.appEventsService.notifyAppUnloaded();
  }

  @HostListener("document:visibilitychange", ["$event"])
  appVisibility() {
    if (document.hidden) {
      this.appEventsService.notifyAppHidden();
    } else {
      this.appEventsService.notifyAppShown();
    }
  }
}
