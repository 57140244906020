import {Workspace} from "workspace/workspace.model";

export enum EventTypes {
  DocumentCreate = "document-create",
  DocumentUpdate = "document-update",
  DocumentDelete = "document-delete",
  DocumentExportOverdue = "document-export-overdue",
  DocumentExportFailed = "document-export-failed",
  WorkspaceUserUpdate = "workspace-user-update",
  WorkspaceUserDelete = "workspace-user-delete",
  WorkspaceBackupCreate = "workspace-backup-create",
  WorkspaceBackupDelete = "workspace-backup-delete",
  WorkspaceDelete = "workspace-delete",
  IntegrationRun = "integration-run",
  ExporterRun = "exporter-run"
}

export interface SocketEvent<T> {
  id: string;
  key: string;
  level: "info" | "warning" | "danger";
  senderUserId: string;
  recipientUserId: string;
  timestamp: Date;
  eventType: EventTypes;
  payload: T;
  workspace?: Workspace;
}
