import {Directive, ElementRef, Renderer2, OnDestroy, AfterViewInit} from "@angular/core";
import {DomUtilsService} from "core";

@Directive({
  selector: "[appIonFloatingLabel]"
})
export class IonFloatingLabelDirective implements AfterViewInit, OnDestroy {
  private focusOutHandler: Function;
  private changeHandler: Function;
  private inputEl: HTMLInputElement;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private domUtilsService: DomUtilsService) {
  }

  ngAfterViewInit() {
    this.inputEl = this.domUtilsService.findChildByPredicate(this.el.nativeElement, e => e.localName === "ion-input" || e.localName === "select");
    if (!this.inputEl) {
      this.inputEl = this.el.nativeElement.children[1];
    }
    if (!this.inputEl) {
      return;
    }
    setTimeout(() => this.render());
    this.focusOutHandler = this.renderer.listen(this.inputEl, "focusout", this.render.bind(this));
    this.changeHandler = this.renderer.listen(this.inputEl, "ionChange", this.render.bind(this));
  }

  ngOnDestroy() {
    if (!this.focusOutHandler) {
      return;
    }
    this.focusOutHandler();
    this.changeHandler();
  }

  private render() {
    const labelEl = this.el.nativeElement.firstChild;
    if (!labelEl || labelEl?.nodeName?.toString().toLowerCase() !== "ion-label") {
      return;
    }
    if (this.inputEl.value !== null && this.inputEl.value !== undefined && this.inputEl.value !== "") {
      this.renderer.addClass(labelEl, "floating");
    } else {
      this.renderer.removeClass(labelEl, "floating");
    }
  }
}
