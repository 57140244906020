import {Directive, ElementRef, EventEmitter, OnDestroy, Output} from "@angular/core";

@Directive({
  selector: "[appDomChange]"
})
export class DomChangeDirective implements OnDestroy {
  private changes: MutationObserver;

  @Output() public domChange = new EventEmitter<MutationRecord>();

  constructor(private elementRef: ElementRef) {
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => setTimeout(() => this.domChange.emit(mutation), 700));
    });
    this.changes.observe(this.elementRef.nativeElement, {
      attributes: true,
      childList: true,
      characterData: false
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
