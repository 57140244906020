import {Component, EventEmitter, forwardRef, Input, OnDestroy, Output} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";
import {ArrayService, CurrencyExchangeApiData, CurrencyExchangeApiService, SubSink} from "core";
import {debounceTime} from "rxjs/operators";

export interface CurrencyExchange {
  currencyFrom: string;
  currencyTo: string;
  exchangeDate: Date;
  exchangeRate: number;
}

@Component({
  selector: "app-currency-exchange",
  templateUrl: "./currency-exchange.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CurrencyExchangeComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => CurrencyExchangeComponent), multi: true}]
})
export class CurrencyExchangeComponent implements ControlValueAccessor, Validator, OnDestroy {
  @Input() label?: string;
  @Input() readOnly = false;
  @Output() focused = new EventEmitter<{dataType: "string" | "number" | "date", cssPath: string}>();

  form: FormGroup;
  onTouched: () => void;
  private sub = new SubSink();
  private apiSub = new SubSink();

  constructor(private arrayService: ArrayService,
              private currencyExchangeApiService: CurrencyExchangeApiService) {
    this.form = new FormGroup({
      "valueFrom": new FormControl(0, [Validators.required]),
      "currencyFrom": new FormControl("", [Validators.required]),
      "exchangeDate": new FormControl(new Date(), [Validators.required]),
      "exchangeRate": new FormControl(1, [Validators.required]),
      "valueTo": new FormControl(0, [Validators.required]),
      "currencyTo": new FormControl("", [Validators.required])
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.apiSub.unsubscribe();
  }

  writeValue(value: CurrencyExchange) {
    this.form.setValue(this.arrayService.smartAssign({
      valueFrom: 0,
      currencyFrom: "EUR",
      exchangeDate: new Date(),
      exchangeRate: 1,
      valueTo: 0,
      currencyTo: "RON",
    } as CurrencyExchange, value));
    this.sub.unsubscribe(true);
    this.apiSub.unsubscribe(true);
    this.sub.sink = this.form.get("exchangeRate").valueChanges
      .subscribe(this.compute.bind(this));
    this.sub.sink = this.form.get("valueFrom").valueChanges
      .subscribe(this.compute.bind(this));
    this.refreshRate();
  }

  registerOnChange(fn: (value: CurrencyExchange) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : {invalid: {message: "CurrencyExchange is invalid"}};
  }

  private initApiSubs() {
    this.apiSub.sink = this.form.get("currencyFrom").valueChanges
      .pipe(debounceTime(1000))
      .subscribe(this.refreshRate.bind(this));
    this.apiSub.sink = this.form.get("currencyTo").valueChanges
      .pipe(debounceTime(1000))
      .subscribe(this.refreshRate.bind(this));
    this.apiSub.sink = this.form.get("exchangeDate").valueChanges
      .pipe(debounceTime(1000))
      .subscribe(this.refreshRate.bind(this));
  }

  private compute() {
    const exchangeRate = +this.form.get("exchangeRate").value;
    const valueFrom = +this.form.get("valueFrom").value;
    this.form.get("valueTo").setValue((exchangeRate * valueFrom).toFixed(2));
  }

  private refreshRate() {
    const currencyFrom = this.form.get("currencyFrom").value;
    const currencyTo = this.form.get("currencyTo").value;
    const exchangeDate = this.form.get("exchangeDate").value;
    if (!currencyFrom || !currencyTo || !exchangeDate) {
      return;
    }
    this.currencyExchangeApiService.readCurrencyExchange(currencyFrom, exchangeDate, currencyTo)
      .subscribe((exchangeInfo: CurrencyExchangeApiData) => {
        this.apiSub.unsubscribe(true);
        this.form.patchValue(exchangeInfo);
        this.initApiSubs();
      });
    this.compute();
  }
}
