import {Directive, ElementRef, OnInit, Output, EventEmitter, Input} from "@angular/core";
import {FilePickerService, PickedFiles} from "./file-picker.service";

@Directive({
  selector: "[appFilePicker]"
})
export class FilePickerDirective implements OnInit {
  @Input() accept: string;
  @Input() isMultiple: boolean;
  @Output() picked = new EventEmitter<PickedFiles>();

  constructor(private el: ElementRef,
              private filePickerService: FilePickerService) {
  }

  ngOnInit() {
    this.el.nativeElement.onclick = (e: Event) => {
      this.filePickerService.pickFromDesktopFileSystem(this.accept, this.isMultiple)
        .subscribe((x: PickedFiles) => this.picked.emit(x));
    };
  }
}
