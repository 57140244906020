import {Component, Input, ViewChild, forwardRef, OnInit} from "@angular/core";
import {IonInput} from "@ionic/angular";
import {YearMonthPickerOptions} from "./year-month.model";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";

@Component({
  selector: "app-year-picker",
  templateUrl: "./year-picker.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => YearPickerComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => YearPickerComponent), multi: true}]
})
export class YearPickerComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() options: YearMonthPickerOptions;

  @ViewChild("yearEditInput") yearEditInput: IonInput;
  isEditState = false;

  formControl: FormControl;
  onTouched: () => void;

  constructor() {
    this.formControl = new FormControl(new Date().getFullYear(), [Validators.required]);
  }

  ngOnInit() {
    this.formControl.setValidators([Validators.required,
      Validators.min(this.options?.yearMin || 0),
      Validators.max(this.options?.yearMax)]);
  }

  writeValue(value: number) {
    this.formControl.setValue(value);
  }

  registerOnChange(fn: (value: number) => void) {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.formControl.valid ? null : {invalid: {message: "Invalid year"}};
  }

  yearBack() {
    this.formControl.setValue(this.formControl.value - 1);
  }

  yearForward() {
    this.formControl.setValue(this.formControl.value + 1);
  }

  yearClick() {
    setTimeout(() => {
      this.isEditState = true;
      setTimeout(() => this.yearEditInput.setFocus(), 0);
    });
  }

  yearBlur() {
    this.isEditState = false;
  }
}
