<!--{{form.value | json}}-->
<form [formGroup]="form" class="d-flex flex-column">
  <ion-item class="input no-border no-padding mb-4">
    <ion-toggle color="primary" formControlName="isActive" labelPlacement="end" justify="start">
      <span [translate]="caption || 'dict.active.m'"></span>
    </ion-toggle>
  </ion-item>
  <div class="position-relative d-block">
    @if (!form.value['isActive']) {
      <div style="z-index: 2; margin: -5px"
           class="position-absolute trbl-0 bg-white opacity-05">
      </div>
    }
    <app-exporter-filter-rule *ngFor="let ruleControl of rules().controls; let idx=index"
                              class="d-block mb-2"
                              [formControl]="$any(ruleControl)"
                              [readOnly]="readOnly"
                              (remove)="rules().removeAt(idx)">
    </app-exporter-filter-rule>
    @if (!readOnly) {
      <ion-button class="sqr-md"
                  (click)="addNewRule()">
        <ion-icon name="add"></ion-icon>
      </ion-button>
    }
  </div>
</form>
