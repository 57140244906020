import {Observable} from "rxjs";

declare var Marvin: any;
declare var MarvinImage: any;

const rotation = {
  1: "rotate(0deg)",
  3: "rotate(180deg)",
  6: "rotate(90deg)",
  8: "rotate(270deg)"
};

export class MarvinImageProcessor {
  private image = new MarvinImage();
  private imageUrl: string;

  loadImage(imageUrl: string): Observable<any> {
    this.imageUrl = imageUrl;
    return new Observable(o => {
      this.image.load(imageUrl, () => {
        o.next(this.image);
        o.complete();
      });
    });
  }

  private destroyImage(image) {
    image.canvas = null;
    image.ctx = null;
    image.data = null;
    image.imageData = null;
  }

  dispose() {
    this.destroyImage(this.image);
    this.imageUrl = null;
    this.image = null;
  }

  reset(): Observable<any> {
    return this.loadImage(this.imageUrl);
  }

  isPng() {
    return this.imageUrl.toString().startsWith("data:image/png");
  }

  getImageSize(): { width: number, height: number } {
    return {
      width: this.image.width,
      height: this.image.height
    };
  }

  computeArea(): number {
    return this.image.width * this.image.height;
  }

  drawImage(canvas: HTMLCanvasElement) {
    canvas.width = this.image.width;
    canvas.height = this.image.height;
    this.image.draw(canvas);
  }

  blackAndWhite(level: number) {
    Marvin.blackAndWhite(this.image, this.image, Number(level));
  }

  thresholding(level: number) { // 0:255
    Marvin.thresholding(this.image, this.image, Number(level));
  }

  brightnessAndContrast(brightness: number, contrast: number) {
    let src = this.image.clone();
    Marvin.brightnessAndContrast(src, this.image, Number(brightness), Number(contrast));
    this.destroyImage(src);
    src = null;
  }

  scaleByWidth(width: number) {
    let src = this.image.clone();
    Marvin.scale(src, this.image, Number(width));
    this.destroyImage(src);
    src = null;
  }

  crop(rectangle: Rectangle) {
    let src = this.image.clone();
    Marvin.crop(src, this.image, rectangle.x1, rectangle.y1, (rectangle.x2 - rectangle.x1), (rectangle.y2 - rectangle.y1));
    this.destroyImage(src);
    src = null;
  }

  findTextRegions(maxWhiteSpace: number, maxFontLineWidth: number, minTextWidth: number, grayScaleThreshold: number): Array<any> {
    return Marvin.findTextRegions(this.image, Number(maxWhiteSpace), Number(maxFontLineWidth), Number(minTextWidth), Number(grayScaleThreshold));
  }

  averageColor(): RGB {
    const rgb = Marvin.averageColor(this.image);
    return {
      r: rgb[0],
      g: rgb[1],
      b: rgb[2]
    };
  }

  toFile(name: string): File {
    const blob = this.image.toBlob();
    return Object.assign(blob, {
      name,
      lastModifiedDate: new Date(),
    });
  }
}

export interface Rectangle {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export interface RGB {
  r: number;
  g: number;
  b: number;
}
