import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class AppDomUtilsService {
  private stickyElementsCount = 0;
  private isStickyTitle = false;

  static getScrollableEl(): Element {
    const ionContentEl = document.getElementById("ion-content");
    return ionContentEl?.shadowRoot?.querySelector(".inner-scroll");
  }

  static setScrollPos(scrollPos: number) {
    const el = AppDomUtilsService.getScrollableEl();
    if (el) {
      el.scrollTo(0, scrollPos);
    }
  }

  static getScrollPos(): number {
    const el = AppDomUtilsService.getScrollableEl();
    return el?.scrollTop || 0;
  }

  static getPageHeight(): number {
    const el = AppDomUtilsService.getScrollableEl();
    return el?.clientHeight || 0;
  }

  stickTitle(isSticky: boolean) {
    this.isStickyTitle = isSticky;
  }

  stickElement(nativeElement: any, isSticky: boolean) {
    this.stickyElementsCount += isSticky ? 1 : -1;
    if (this.stickyElementsCount < 0) {
      this.stickyElementsCount = 0;
    }
    nativeElement.classList.toggle("is-stuck", isSticky);

    const headerEl = document.getElementById("main-header");
    if (!headerEl) {
      return;
    }
    setTimeout(() => headerEl.classList.toggle("shadow-sm-bottom", !this.stickyElementsCount && this.isStickyTitle));
  }

  refreshUploadButton() {
    // const uploadButtonEl = document.getElementById("upload-button");
    // if (document.URL.indexOf("/registry/documents") !== -1) {
    //   uploadButtonEl.classList.add("d-none");
    // } else {
    //   uploadButtonEl.classList.remove("d-none");
    // }
  }
}
