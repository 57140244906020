import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "hotKey"})
export class HotkeyPipe implements PipeTransform {
  constructor() {
  }

  private symbols = {
    meta: "⌘",
    shift: "⇧",
    arrowLeft: "←",
    arrowRight: "→",
    arrowUp: "↑",
    arrowDown: "↓",
    control: "^",
    alt: "⌥",
    delete: "del"
  };

  transform(keyCode: string) {
    return Object.keys(this.symbols).reduce((ret, symbol) => {
      return ret.replace(new RegExp(symbol, "g"), this.symbols[symbol]);
    }, keyCode).replace(new RegExp("\\.", "g"), "+")
      .toUpperCase();
  }
}
