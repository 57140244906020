import {Injectable} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {concatMap, from, Observable, of, throwError} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {
  ApiService, BrowserStorageService, ErrorString, FiscalInfoApiData, FiscalInfoApiService, HateoasList,
  ImageProcessorService,
  MarvinImageProcessor,
  OcrOptimizationResult,
  OcrOptimizer
} from "core";
import {AppMessage, FiscalInfo, ToasterService} from "shared";
import {
  SupportedMimeType,
  WebPreview,
  Document,
  DocumentEvent,
  DocumentPipelineStatus,
  DocumentOperateRequest,
  DocumentFileType, DocumentRendering
} from "./documents.model";
import {DocumentsMqService} from "./documents-mq.service";
import {DocumentsUtilService} from "./documents-util.service";
import {environment} from "environment";
import {DynamicField, DynamicFieldValue, FieldDataType} from "dynamic-forms/dynamic-forms.model";

@Injectable({providedIn: "root"})
export class DocumentsApiService {
  constructor(private apiService: ApiService,
              private ipService: ImageProcessorService,
              private documentsMqService: DocumentsMqService,
              private documentsUtilService: DocumentsUtilService,
              private fiscalApiService: FiscalInfoApiService,
              private browserStorage: BrowserStorageService,
              private toasterService: ToasterService) {
  }

  readSupportedMimeTypes(): Observable<Array<SupportedMimeType>> {
    return this.apiService.get(`documents/supported-mime-types`, null);
  }

  readSupportedFileTypes(): Observable<Array<DocumentFileType>> {
    return this.apiService.get(`documents/supported-file-types`, null);
  }

  getFieldMemory(documentTypeId: number, fieldId: string, take: number = 0, orderBy: string = null, search: string = null, dataFilters: Array<{fieldId: string, value: string}> = null): Observable<Array<Array<DynamicFieldValue>>> {
    const qry = ApiService.buildQuery([{
      documentTypeId, fieldId, take, orderBy, search
    }]);
    return this.apiService.post(`documents/field-memory?${qry}`, {}, dataFilters || []);
  }

  readDocument(documentId: number): Observable<Document> {
    return this.apiService.get(`documents/{documentId}`, {documentId})
      .pipe(tap((d: Document) => d.viewMode = this.documentsUtilService.computeOptimalViewMode(d)));
  }

  readDocumentText(documentId: number): Observable<string> {
    return this.apiService.get(`documents/{documentId}/text`, {documentId})
      .pipe(map(x => x.text));
  }

  read1stPageImage(documentId): Observable<{imageUrl: string}> {
    return this.apiService.get(`documents/{documentId}/1st-page-image`, {documentId});
  }

  readDocumentEvents(documentId: number): Observable<HateoasList<DocumentEvent>> {
    return this.apiService.get(`documents/{documentId}/events`, {documentId});
  }

  renameDocument(documentId: number, newName: string): Observable<Document> {
    return this.apiService.put("documents/{documentId}/name", {documentId}, {name: newName})
      .pipe(tap((document: Document) => this.documentsMqService.notifyDocumentUpdated(document, false)));
  }

  changeDocumentSource(documentId: number, newSourceFile: File): Observable<Document> {
    if (!newSourceFile) {
      return new Observable(o => {
        o.error(new Error("Invalid file"));
        o.complete();
      });
    }

    const q = environment.production
      ? this.apiService.uploadS3("files/presigned", newSourceFile)
        .pipe(switchMap((s3Url: string) => {
          return this.apiService.post("documents/{documentId}/source/from-url", {documentId}, {url: s3Url});
        }))
      : this.apiService.upload("documents/{documentId}/source", {documentId}, [newSourceFile], {});

    return q
      .pipe(tap((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false)));
  }

  extractDataFromDocument(documentId: number): Observable<Document> {
    return this.apiService.get("documents/{documentId}/extract-data", {documentId})
      .pipe(tap((document: Document) => this.documentsMqService.notifyDocumentUpdated(document, false)));
  }

  assignDocumentsToWorkspace(documentsIds: Array<number>, workspaceId: number): Observable<Array<Document>> {
    return this.apiService.put("documents/change", null, {documentsIds, workspaceId})
      .pipe(tap((documents: Array<Document>) => {
        documents.forEach((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false));
      }));
  }

  classifyDocuments(documentsIds: Array<number>, documentTypeId: number): Observable<Array<Document>> {
    return this.apiService.put("documents/change", null, {documentsIds, documentTypeId: documentTypeId || -1})
      .pipe(tap((documents: Array<Document>) => {
        documents.forEach((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false));
      }));
  }

  changeDocumentsStatus(documentsIds: Array<number>, pipelineStatus: DocumentPipelineStatus): Observable<Array<Document>> {
    return this.apiService.put("documents/change", null, {documentsIds, pipelineStatus})
      .pipe(tap((documents: Array<Document>) => {
        documents.forEach((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false));
      }));
  }

  unarchiveDocuments(documentsIds: Array<number>): Observable<Array<Document>> {
    return this.apiService.put("documents/unarchive", null, {documentsIds})
      .pipe(tap((documents: Array<Document>) => {
        documents.forEach((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false));
      }));
  }

  joinDocuments(documentsIds: Array<number>): Observable<Document> {
    return this.apiService.post("documents/join", null, {documentsIds})
      .pipe(tap((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false)));
  }

  updateDocumentProperties(documentId: number, doRq: DocumentOperateRequest): Observable<Document> {
    return this.apiService.put("documents/{documentId}", {documentId}, doRq)
      .pipe(tap((d: Document) => this.toasterService.success(`document.do.save.ok|${d.name}`)))
      .pipe(tap((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false)));
  }

  deleteDocument(documentId: number): Observable<number> {
    if (documentId < 0) {
      return this.browserStorage.idb.remove("registry", (d: Document) => d.id === documentId)
        .pipe(tap(() => this.documentsMqService.notifyDocumentDeleted({id: documentId}, false)));
    }
    return this.apiService.delete("documents/{documentId}", {documentId})
      .pipe(tap(() => this.documentsMqService.notifyDocumentDeleted({id: documentId}, false)));
  }

  deleteDocumentJoinSources(documentId: number): Observable<Document> {
    return this.apiService.delete("documents/{documentId}/join-sources", {documentId})
      .pipe(tap((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false)));
  }

  splitDocumentToPages(documentId: number): Observable<Document> {
    return this.apiService.post("documents/{documentId}/split", {documentId}, null)
      .pipe(tap((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false)));
  }

  readRenderings(documentId: number): Observable<HateoasList<DocumentRendering>> {
    return this.apiService.get("documents/{documentId}/renderings", {documentId});
  }

  renderPdf(documentId: number, rendererId: string): Observable<DocumentRendering> {
    let ret: DocumentRendering;
    return this.apiService.post("documents/{documentId}/renderings", {
      documentId
    }, {rendererId})
      .pipe(concatMap((dr: DocumentRendering) => {
        ret = dr;
        return this.apiService.get(`documents/{documentId}`, {documentId});
      }))
      .pipe(tap((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false)))
      .pipe(map(() => ret));
  }

  deleteRendering(documentId: number, documentRenderingId: number): Observable<any> {
    return this.apiService.delete("documents/{documentId}/renderings/{documentRenderingId}", {
      documentId,
      documentRenderingId
    })
      .pipe(concatMap((dr: DocumentRendering) => this.apiService.get(`documents/{documentId}`, {documentId})))
      .pipe(tap((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false)));
  }

  sendRenderingByEmail(documentId: number, documentRenderingId: number, message: AppMessage): Observable<AppMessage> {
    return this.apiService.post("documents/{documentId}/renderings/{documentRenderingId}/email/send", {
      documentId,
      documentRenderingId
    }, message)
      .pipe(tap(() => this.toasterService.success("dict.emailSent")));
  }

  previewRenderingEmail(documentId: number, documentRenderingId: number, message: AppMessage): Observable<AppMessage> {
    return this.apiService.post("documents/{documentId}/renderings/{documentRenderingId}/email/preview", {
      documentId,
      documentRenderingId
    }, message);
  }

  proposeRenderingEmailRecipient(documentId: number, documentRenderingId: number): Observable<AppMessage> {
    return this.apiService.get("documents/{documentId}/renderings/{documentRenderingId}/email/recipient", {
      documentId,
      documentRenderingId
    });
  }

  uploadOptimizedDocument(file: File, uiId: string, name: string): Observable<Document> {
    return new Observable(o => {
      if (file.type.startsWith("image/")) {
        this.ipService.createMarvinFromFile(file)
          .subscribe((mip: MarvinImageProcessor) => {
            const ocrOptimizer = new OcrOptimizer(mip);
            ocrOptimizer.optimizeSize()
              .subscribe((ocrResult: OcrOptimizationResult) => {
                if (!ocrResult.isValid) {
                  mip.dispose();
                  o.error(new Error("Unacceptable image quality or not a document"));
                  return o.complete();
                }
                const f = mip.toFile(file.name);
                mip.dispose();
                this.uploadNewDocument(f, {uiId, name})
                  .subscribe((d: Document) => {
                    o.next(d);
                    o.complete();
                  }, (err: any) => {
                    o.error(err);
                    o.complete();
                  });
              });
          });
      } else {
        this.uploadNewDocument(file, {uiId, name})
          .subscribe((d: Document) => {
            o.next(d);
            o.complete();
          }, (err: any) => {
            o.error(err);
            o.complete();
          });
      }
    });
  }

  uploadNewDocument(file: File, additionalPayload: any): Observable<any> {
    if (!file) {
      return new Observable(o => {
        o.error(new Error("Invalid file"));
        o.complete();
      });
    }

    const q = environment.production
      ? this.apiService.uploadS3("files/presigned", file)
        .pipe(switchMap((s3Url: string) => {
          return this.apiService.post("documents/from-url", null, Object.assign({}, additionalPayload, {
            url: s3Url,
            mimeType: file.type
          }));
        }))
      : this.apiService.upload("documents", null, [file], additionalPayload);

    return q
      .pipe(tap((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false)))
      .pipe(catchError((err: HttpErrorResponse) => {
        const errDoc: Document = {
          uiId: additionalPayload["uiId"],
          pipelineStatus: DocumentPipelineStatus.UploadError,
          uploadError: new ErrorString().transform(err)
        };
        this.documentsMqService.notifyDocumentUpdated(errDoc, false);
        return throwError(() => err);
      }));
  }

  submitWeblink(linkUrl: string, uiId: string, name: string): Observable<any> {
    if (!linkUrl || !linkUrl.startsWith("http")) {
      return new Observable(o => {
        o.error(new Error("Invalid link"));
        o.complete();
      });
    }

    return this.apiService.post("documents/from-url", null, {uiId, name, url: linkUrl})
      .pipe(tap((d: Document) => this.documentsMqService.notifyDocumentUpdated(d, false)))
      .pipe(catchError((err: HttpErrorResponse) => {
          const errDoc: Document = {
            uiId: uiId,
            pipelineStatus: DocumentPipelineStatus.UploadError,
            uploadError: new ErrorString().transform(err)
          };
          this.documentsMqService.notifyDocumentUpdated(errDoc, false);
          return throwError(() => err);
        })
      );
  }

  previewWeblink(url: string): Observable<WebPreview> {
    return this.apiService.post(`files/preview`, null, {url});
  }

  updateAdditionalData(document: Document): Observable<any> {
    return this.updateFiscalInfo(document.documentType?.headerFields, document.documentData?.headerData)
      .pipe(concatMap(() => this.updateFiscalInfo(document.documentType?.footerFields, document.documentData?.footerData)));
  }

  private updateFiscalInfo(fields: Array<DynamicField>, values: Array<DynamicFieldValue>) {
    const fiscalFields = fields?.filter(x => x.fieldType === FieldDataType.FiscalId) || [];
    return from(fiscalFields)
      .pipe(concatMap((f: DynamicField) => {
        const fieldValue = values?.find(x => x.fieldId === f.fieldId);
        const fi = fieldValue.value as FiscalInfo;
        const fiscalInfoApiData = {
          cui: fi?.cui,
          name: fi?.name,
          isVat: !!fi?.isVat,
          companyInfo: {
            j: fi?.j,
            region: fi?.region,
            city: fi?.city,
            address: fi?.line1,
            zipCode: fi?.zipCode,
            phone: fi?.phoneNumber,
            email: fi?.email,
            website: fi?.web,
            caen: fi?.caen,
            legalRepresentative: {
              name: fi?.contactName,
              phone: fi?.contactPhone,
              email: fi?.contactEmail
            }
          }
        } as FiscalInfoApiData;
        return fi
          ? this.fiscalApiService.updateFiscalData(fi.cui, fiscalInfoApiData)
          : of({});
      }));
  }
}
