import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class DomUtilsService {
  // findChildWithName(element, pattern) {
  //   if (!element) {
  //     return null;
  //   }
  //
  //   if (element.localName.startsWith(pattern)) {
  //     return element;
  //   }
  //
  //   return this.findChildWithName(element.parentElement, pattern);
  // }

  getPlaceholderText(element) {
    const placeholderInputAttr = element.attributes["placeholder"]
      || element.attributes["ng-reflect-placeholder"]
      || element.attributes["aria-placeholder"];
    if (placeholderInputAttr && placeholderInputAttr.value) {
      return placeholderInputAttr.value.trim();
    }

    for (let i = 0; i < element.children.length; i++) {
      const v = this.getPlaceholderText(element.children[i]);
      if (v !== undefined) {
        return v;
      }
    }

    if (element.children && element.children[0] && element.children[0].tagName === "OPTION") {
      return element.children[0].innerText.trim();
    }

    return undefined;
  }

  findInputValue(element) {
    if (element.value !== undefined) {
      return element.value;
    }
    if (!element.children || !element.children.length) {
      return undefined;
    }
    for (let i = 0; i < element.children.length; i++) {
      const v = this.findInputValue(element.children[i]);
      if (v !== undefined) {
        return v;
      }
    }
    return undefined;
  }

  findAncestorWithClass(element, className) {
    if (!element) {
      return null;
    }
    if (!element.classList) {
      return null;
    }
    if (element.classList.contains(className)) {
      return element;
    }
    return this.findAncestorWithClass(element.parentElement, className);
  }

  findAncestorWithNameStartsWith(element, pattern, breakPattern) {
    if (!element) {
      return null;
    }

    if (element.localName.startsWith(breakPattern)) {
      return null;
    }

    if (element.localName.startsWith(pattern)) {
      return element;
    }

    return this.findAncestorWithNameStartsWith(element.parentElement, pattern, breakPattern);
  }

  findAncestorWithName(element, pattern, breakPattern) {
    if (!element) {
      return null;
    }

    if (element.localName.startsWith(breakPattern)) {
      return null;
    }

    if (element.localName === pattern) {
      return element;
    }

    return this.findAncestorWithNameStartsWith(element.parentElement, pattern, breakPattern);
  }

  findChildByPredicate(element: Element, predicate: (e: Element) => boolean) {
    if (!element) {
      return null;
    }

    for (let i = 0; i < element.children.length; i++) {
      const subEl = element.children[i];
      if (predicate.apply(this, [subEl])) {
        return subEl;
      }
      const nextMatch = this.findChildByPredicate(subEl, predicate);
      if (nextMatch) {
        return nextMatch;
      }
    }

    return null;
  }

  findChildByClassPath(element: Element, classTree: Array<string>) {
    if (!element) {
      return null;
    }

    for (let i = 0; i < classTree.length; i++) {
      const cls = classTree[i];
      element = this.findChildByPredicate(element, e => e.classList.contains(cls));
      if (!element) {
        return null;
      }
    }
    return element;
  }

  bottomScrollDistance(nativeElement: Element): number {
    return nativeElement.scrollHeight - nativeElement.scrollTop - nativeElement.clientHeight;
  }

  isScrolledToBottom(nativeElement: Element, offset: number = 0): boolean {
    return this.bottomScrollDistance(nativeElement) <= offset;
  }

  isScrolledToTop(nativeElement: Element, offset: number = 0): boolean {
    return nativeElement.scrollTop <= offset;
  }

  scrollToBottom(nativeElement: Element, offset: number = 0) {
    nativeElement.scrollTo(0, nativeElement.scrollHeight - nativeElement.clientHeight - offset);
  }

  scrollToTop(nativeElement: Element, offset: number = 0) {
    nativeElement.scrollTo(0, offset);
  }

  getClientCoords(event: MouseEvent | TouchEvent, relativeToNativeElement: any = null): {x: number, y: number} {
    const offset = {x: 0, y: 0};
    if (relativeToNativeElement) {
      const rect = relativeToNativeElement.getBoundingClientRect();
      offset.x = rect.left;
      offset.y = rect.top;
    }
    if (event instanceof TouchEvent) {
      return {
        x: (<TouchEvent>event).touches[0].clientX - offset.x,
        y: (<TouchEvent>event).touches[0].clientY - offset.y
      };
    } else {
      return {
        x: (<MouseEvent>event).clientX - offset.x,
        y: (<MouseEvent>event).clientY - offset.y
      };
    }
  }
}
