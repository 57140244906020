<div class="position-relative w-100 h-100" [class.bg-light]="!isFinishedDrawing">
  @if (loadState.isOk) {
    <div class="content" [ngClass]="{'animated fast fadeIn': animate}">
      <ion-img class="w-100 h-100"
               [style]="cssStyle"
               [src]="photoSrc"
               (ionImgDidLoad)="imageLoaded()"
               (ionError)="imageError($event)">
      </ion-img>
    </div>
  } @else if (loadState.isError) {
    <div class="content animated faster fadeIn">
      <ng-content></ng-content>
    </div>
  }
</div>
