import {
  animate,
  AnimationStyleMetadata,
  AUTO_STYLE,
  group, keyframes,
  query,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";

const timing = ".3s ease-in-out";

const start: AnimationStyleMetadata = style({
  position: "absolute",
  transform: "translate(0, 0)",
  width: "100%",
  height: "100%"
});

const outsideLeft: AnimationStyleMetadata = style({
  transform: "translateX(-100%)",
  opacity: 0
});

const outsideRight: AnimationStyleMetadata = style({
  transform: "translateX(100%)",
  opacity: 0
});

const inPlace: AnimationStyleMetadata = style({
  transform: "translateX(0)",
  opacity: 1
});

const _leaveToLeft = query(":leave", animate(timing, outsideLeft), {optional: true});
const _leaveToRight = query(":leave", animate(timing, outsideRight), {optional: true});
const _enterInPlace = query(":enter", animate(timing, inPlace), {optional: true});

export const slideInAnimation =
  trigger("routeAnimations", [
    transition("login => *", [
      query(":enter, :leave", start, {optional: true}),
      query(":enter", outsideRight, {optional: true}),
      group([_leaveToLeft, _enterInPlace])
    ]),
    transition("* => login", [
      query(":enter, :leave", start, {optional: true}),
      query(":enter", outsideLeft, {optional: true}),
      group([_leaveToRight, _enterInPlace])
    ])
  ]);

export const blinkAnimation = trigger("blink", [
  state("normal", style({transform: "rotate(0deg)"})),
  state("large", style({transform: "rotate(30deg)"})),
  transition("normal => large", animate(".2s ease-in-out", keyframes([
    style({transform: "rotate(0deg)"}),
    style({transform: "rotate(-30deg) scale(1.9)"}),
    style({transform: "rotate(30deg)"}),
  ]))),
  transition("large => normal", animate(".2s ease-in-out", keyframes([
    style({transform: "rotate(30deg)"}),
    style({transform: "rotate(0deg)"}),
  ])))
]);

export const fadeInOutAnimation = trigger("fade", [
  state("visible", style({opacity: 1})),
  state("hidden", style({opacity: 0, 'z-index': -1})),
  transition("visible => hidden", animate(".2s ease-in-out")),
  transition("hidden => visible", animate(".2s ease-in-out"))
]);

export const toggleAnimation = trigger("cardToggle", [
  state("collapsed, void", style({overflow: "hidden", height: "0", opacity: 0})),
  state("expanded", style({overflow: "hidden", height: AUTO_STYLE, opacity: 1})),
  transition("collapsed <=> expanded", animate(".4s ease-in-out"))
]);
