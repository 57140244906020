import {Node as ProseMirrorNode} from "@tiptap/pm/model";
import {NodeView} from "@tiptap/pm/view";
import {Table} from "@tiptap/extension-table";

class CustomTableView implements NodeView {
  node: ProseMirrorNode;

  cellMinWidth: number;

  dom: Element;

  table: Element | any;

  colgroup: Element;

  contentDOM: Element | any;

  constructor(node: ProseMirrorNode, cellMinWidth: number) {
    this.node = node;
    this.cellMinWidth = cellMinWidth;
    this.dom = document.createElement("div");
    this.dom.className = "tableWrapper";
    this.table = this.dom.appendChild(document.createElement("table")) as any;
    this.colgroup = this.table.appendChild(document.createElement("colgroup"));
    this.updateColumns(node, this.colgroup, this.table, cellMinWidth);
    this.contentDOM = this.table.appendChild(document.createElement("tbody"));

    ["borderLayout", "borderStyle", "borderWidth", "borderColor"].forEach(x => {
      if (node.attrs[x]) {
        this.table.setAttribute(x, node.attrs[x]);
        this.table.style.setProperty(`--tiptap-${x}`, node.attrs[x]);
      }
    });
  }

  update(node: ProseMirrorNode) {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;
    this.updateColumns(node, this.colgroup, this.table, this.cellMinWidth);

    return true;
  }

  ignoreMutation(mutation: MutationRecord | {type: "selection"; target: Element}) {
    return (
      mutation.type === "attributes"
      && (mutation.target === this.table || this.colgroup.contains(mutation.target))
    );
  }

  updateColumns(
    node: ProseMirrorNode,
    colgroup: Element,
    table: Element | any,
    cellMinWidth: number,
    overrideCol?: number,
    overrideValue?: any,
  ) {
    let totalWidth = 0;
    let fixedWidth = true;
    let nextDOM = colgroup.firstChild as any;
    const row = node.firstChild;

    for (let i = 0, col = 0; i < row.childCount; i += 1) {
      const {colspan, colwidth} = row.child(i).attrs;

      for (let j = 0; j < colspan; j += 1, col += 1) {
        const hasWidth = overrideCol === col ? overrideValue : colwidth && colwidth[j];
        const cssWidth = hasWidth ? `${hasWidth}px` : "";

        totalWidth += hasWidth || cellMinWidth;

        if (!hasWidth) {
          fixedWidth = false;
        }

        if (!nextDOM) {
          colgroup.appendChild(document.createElement("col")).style.width = cssWidth;
        } else {
          if (nextDOM.style.width !== cssWidth) {
            nextDOM.style.width = cssWidth;
          }

          nextDOM = nextDOM.nextSibling;
        }
      }
    }

    while (nextDOM) {
      const after = nextDOM.nextSibling;

      nextDOM.parentNode.removeChild(nextDOM);
      nextDOM = after;
    }

    if (fixedWidth) {
      table.style.width = `${totalWidth}px`;
      table.style.minWidth = "";
    } else {
      table.style.width = "";
      table.style.minWidth = `${totalWidth}px`;
    }

    ["borderLayout", "borderStyle", "borderWidth", "borderColor"].forEach(x => {
      if (node.attrs[x]) {
        table.setAttribute(x, node.attrs[x]);
        table.style.setProperty(`--tiptap-${x}`, node.attrs[x]);
      }
    });
  }
}

export const TableExt = Table.extend({
  // @ts-ignore
  addOptions() {
    return {
      HTMLAttributes: {},
      resizable: false,
      handleWidth: 5,
      cellMinWidth: 25,
      // TODO: fix
      View: CustomTableView, /* REPLACED BY CUSTOM_TABLE_VIEW */
      lastColumnResizable: true,
      allowTableNodeSelection: false,
    };
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      borderLayout: {
        default: null,
        parseHTML: el => el.getAttribute("borderLayout"),
        renderHTML: attrs => attrs["borderLayout"] ? {
          borderLayout: attrs["borderLayout"],
          style: `--tiptap-borderLayout: ${attrs["borderLayout"]}`
        } : {}
      },
      borderStyle: {
        default: null,
        parseHTML: el => el.getAttribute("borderStyle"),
        renderHTML: attrs => attrs["borderStyle"] ? {
            borderStyle: attrs["borderStyle"],
            style: `--tiptap-borderStyle: ${attrs["borderStyle"]}`
          }
          : {}
      },
      borderWidth: {
        default: null,
        parseHTML: el => el.getAttribute("borderWidth"),
        renderHTML: attrs => attrs["borderWidth"] ? {
            borderWidth: attrs["borderWidth"],
            style: `--tiptap-borderWidth: ${attrs["borderWidth"]}`
          }
          : {}
      },
      borderColor: {
        default: null,
        parseHTML: el => el.getAttribute("borderColor"),
        renderHTML: attrs => attrs["borderColor"] ? {
          borderColor: attrs["borderColor"],
          style: `--tiptap-borderColor: ${attrs["borderColor"]}`
        } : {}
      }
    };
  }
});
