<form [formGroup]="form" class="d-flex flex-column h-100">
  <ion-item *ngIf="!hideRecipient" class="floating-label">
    <ion-input formControlName="recipientName"
               [label]="'dict.emailToName' | translate"
               labelPlacement="floating">
    </ion-input>
    <app-form-controls-errors [control]="form.controls['recipientName']"></app-form-controls-errors>
    <app-action-dropdown class="d-block"
                         [actions]="addressBookChooseActions"
                         [enableFiltering]="true">
      <ion-button fill="clear">
        <ion-icon name="search"></ion-icon>
      </ion-button>
    </app-action-dropdown>
  </ion-item>
  <ion-item *ngIf="!hideRecipient" class="floating-label">
    <ion-input formControlName="recipientAddress"
               [label]="'dict.emailToAddress' | translate"
               labelPlacement="floating">
    </ion-input>
    <app-form-controls-errors [control]="form.controls['recipientAddress']"></app-form-controls-errors>
  </ion-item>
  <ion-item *ngIf="!hideSubject" class="floating-label">
    <ion-input formControlName="subject"
               [readonly]="readOnlySubject"
               [label]="'dict.emailSubject' | translate"
               labelPlacement="floating">
    </ion-input>
    <app-form-controls-errors [control]="form.controls['subject']"></app-form-controls-errors>
  </ion-item>
  <ng-container *ngIf="!hideBody">
    <ion-item *ngIf="!readOnlyBody" class="floating-label">
      <ion-textarea formControlName="body"
                    [label]="'dict.emailBody' | translate"
                    labelPlacement="floating">
      </ion-textarea>
      <app-form-controls-errors [control]="form.controls['body']"></app-form-controls-errors>
    </ion-item>
    <div *ngIf="readOnlyBody"
         class="flex-grow-1 p-1 bg-light"
         [innerHTML]="form.controls['body'].value | safeHtml">
    </div>
  </ng-container>
</form>
