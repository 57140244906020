import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {
  AbstractControl, ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors, Validator,
  Validators
} from "@angular/forms";
import {AppMessage} from "./email-message.models";
import {HateoasList, LoadState} from "core";
import {ActionBarItem} from "shared";
import {AccountApiService} from "account/account.api.service";

@Component({
  selector: "app-email-message",
  templateUrl: "./email-message.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EmailMessageComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailMessageComponent), multi: true}]
})
export class EmailMessageComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() hideRecipient = false;
  @Input() hideSubject = false;
  @Input() readOnlySubject = false;
  @Input() hideBody = false;
  @Input() readOnlyBody = false;
  @Input() created: (x: EmailMessageComponent) => void;

  form: FormGroup;
  onTouched: () => void;
  loadState = new LoadState();
  addressBookChooseActions: Array<ActionBarItem>;

  constructor(private accountApiService: AccountApiService) {
  }

  ngOnInit() {
    this.loadState.setInit();
    this.form = new FormGroup({
      "recipientAddress": new FormControl("", [Validators.required]),
      "recipientName": new FormControl("", [Validators.required]),
      "subject": new FormControl("", this.hideSubject ? null : [Validators.required]),
      "body": new FormControl("", this.hideBody ? null : [Validators.required])
    });
    this.created(this);
    if (!this.hideRecipient) {
      this.accountApiService.readEmailAddressBook()
        .subscribe((addressBook: HateoasList<AppMessage>) => {
          this.addressBookChooseActions = addressBook.items.map(x => {
            return {
              title: `${x.recipientName} <${x.recipientAddress}>`,
              actionValue: x,
              handler: (a: ActionBarItem) => this.writeValue(a.actionValue)
            } as ActionBarItem;
          });
        });
    }
  }

  writeValue(value: AppMessage) {
    this.setValue(value as AppMessage);
  }

  private setValue(value: AppMessage) {
    if (value) {
      const newValue = Object.assign({}, value);
      this.form.patchValue(newValue);
    }
    this.loadState.setOk();
  }

  registerOnChange(fn: (value: AppMessage) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : {invalidAddress: {message: "Email message is invalid"}};
  }
}
