import {Component, Input} from "@angular/core";

@Component({
  selector: "app-star-rating",
  templateUrl: "./star-rating.component.html",
  styleUrls: ["./star-rating.component.scss"]
})
export class StarRatingComponent {
  @Input() ratingsCount = 0;
  @Input() ratingScore = 0;
}
