import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ServiceWorkerModule} from "@angular/service-worker";
import {IonicModule} from "@ionic/angular";
import {ImagePicker} from "@ionic-native/image-picker/ngx";
import {Chooser} from "@ionic-native/chooser/ngx";
import {ThemeDetection} from "@ionic-native/theme-detection/ngx";
import {ScreenOrientation} from "@ionic-native/screen-orientation/ngx";
import {TourIonPopoverModule} from "ngx-ui-tour-ionic";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {CoreModule} from "core/core.module";
import {register} from "swiper/element/bundle";
import {environment} from "environment";

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    IonicModule.forRoot({mode: "ios"}),
    TourIonPopoverModule,
    ServiceWorkerModule.register("ngsw-worker.js", {enabled: environment.isWeb && environment.production}),
    NgxGoogleAnalyticsModule.forRoot(environment.gaTrackingCode),
    NgxGoogleAnalyticsRouterModule
  ],
  declarations: [],
  providers: environment.isWeb || true ? [
    ImagePicker,
    Chooser,
    ThemeDetection,
    ScreenOrientation,
    // {
    //   provide: RouteReuseStrategy,
    //   useClass: CustomReuseStrategy
    // }
  ] : []
})
export class AppModule {
}

register();
