import {Component, EventEmitter, forwardRef, Input, OnDestroy, Output} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";
import {ArrayService, SubSink} from "core";
import {debounceTime} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {DynamicFieldValue} from "dynamic-forms/dynamic-forms.model";

export interface SeriesInfo {
  seriesId: string;
  increment: number;
}

@Component({
  selector: "app-series",
  templateUrl: "./series.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SeriesComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => SeriesComponent), multi: true}]
})
export class SeriesComponent implements ControlValueAccessor, Validator, OnDestroy {
  @Input() caption: string;
  @Input() readOnly = false;
  @Input() seriesLoader$: (subfieldId: string, take: number, orderBy: string, dataFilters: Array<any>) => Observable<Array<Array<DynamicFieldValue>>>;

  @Output() focused = new EventEmitter<{dataType: "string" | "number" | "date", cssPath: string}>();

  form: FormGroup;
  onTouched: () => void;
  private sub = new SubSink();

  constructor(private arrayService: ArrayService) {
    this.form = new FormGroup({
      "seriesId": new FormControl(""),
      "increment": new FormControl(1, [Validators.required])
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  writeValue(value: SeriesInfo) {
    this.sub.unsubscribe(true);
    const series$ = !value && this.seriesLoader$
      ? this.seriesLoader$.apply(this, ["seriesId", 1, "increment", []])
      : of([[{
        fieldId: "seriesId",
        value: value?.seriesId || ""
      } as DynamicFieldValue, {
        fieldId: "increment",
        value: value?.increment || 1
      } as DynamicFieldValue]]);

    series$.subscribe((seriesList: Array<Array<DynamicFieldValue>>) => {
      const seriesIdField = seriesList.length && seriesList[0].find(f => f.fieldId.endsWith("seriesId"));
      const incrementField = seriesList.length && seriesList[0].find(f => f.fieldId.endsWith("increment"));
      this.form.patchValue({
        seriesId: seriesIdField?.value || "",
        increment: +(incrementField?.value || "0") + (value ? 0 : 1)
      });
      setTimeout(() => {
        this.sub.sink = this.form.get("seriesId").valueChanges
          .pipe(debounceTime(1000))
          .subscribe(() => this.refreshIncrement());
      }, 100);
    });
  }

  registerOnChange(fn: (value: SeriesInfo) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : {invalid: {message: "SeriesInfo is invalid"}};
  }

  private refreshIncrement() {
    if (!this.seriesLoader$ || this.readOnly) {
      return;
    }
    this.seriesLoader$.apply(this, ["increment", 1, [{fieldId: "seriesId", value: this.form.value["seriesId"]}]])
      .subscribe((seriesList: Array<Array<DynamicFieldValue>>) => {
        const incrementField = seriesList.length && seriesList[0].find(f => f.fieldId.endsWith(".increment"));
        this.form.patchValue({
          increment: +(incrementField?.value || "0") + 1
        });
      });
  }

  handleKeyUp($event: KeyboardEvent) {
    this.form.patchValue({
      seriesId: this.form.value["seriesId"].toString().toUpperCase()
    });
  }
}
