import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule} from "@angular/router";
import {TourStepTemplateComponent} from "ngx-ui-tour-ionic";
import {AppModule} from 'app.module';
import {AppComponent} from './app.component';
import {routes} from './app.routes';

@NgModule({
  imports: [
    AppModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      paramsInheritanceStrategy: "always",
      // preloadingStrategy: PreloadAllModules
    }),
    TourStepTemplateComponent
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModuleRouted {
}
