import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {concatMap, Observable, Subscriber, tap, throwError} from "rxjs";
import {ApiRouterService} from "./api-router.service";
import {Sorting} from "./sorting";
import {Pagination} from "./pagination";
import {ToasterService} from "../../shared/toaster/toaster.service";
import {environment} from "environment";
import {catchError} from "rxjs/operators";

@Injectable({providedIn: "root"})
export class ApiService {

  static buildQuery(objects: Array<Pagination | Sorting | any>): string {
    let qry = "";

    objects.forEach(object => {
      if (object) {
        const properties = object instanceof Pagination
          ? ["page", "pageSize"]
          : Object.getOwnPropertyNames(object);
        properties.forEach(p => {
          const value = object[p];
          if (value !== undefined && value !== null) {
            if (value instanceof Array) {
              value.forEach(v => {
                qry = qry + `&${p}=${v}`;
              });
            } else {
              qry = qry + `&${p}=${value}`;
            }
          }
        });
      }
    });
    return qry;
  }

  constructor(private http: HttpClient,
              private toasterService: ToasterService,
              private apiRouterService: ApiRouterService) {
  }

  private httpCall(method: string, routeId: string, params: any, payload: any, options: any = {}): Observable<any> {
    if (!payload) {
      payload = params;
    }
    const requestOptions = Object.assign({
      body: payload
    }, options);

    return this.apiRouterService
      .getRouteById(routeId, params)
      .pipe(concatMap((url: string) => this.http.request(method, url, requestOptions)))
      .pipe(catchError((error: HttpErrorResponse) => {
        const x$ = (options?.responseType === "blob")
          ? this.parseErrorBlob(error)
          : throwError(() => error);
        return x$
          .pipe(tap(() => {
            console.error(error);
            this.toasterService.error(error);
          }))
          .pipe(catchError((error) => {
            console.error(error);
            this.toasterService.error(error);
            return throwError(() => error);
          }));
      }));
  }

  private parseErrorBlob(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    return new Observable(x => {
      (error.error as Blob).text().then((errText: string) => {
        const i1 = errText.indexOf(" ");
        const i2 = errText.indexOf("\n");
        if (i1 !== -1 && i2 !== -1) {
          errText = errText.substring(i1, i2).trim();
        }
        error.error.message = errText;
        x.error(error);
        x.complete();
      });
    });
  }

  get(routeId: string, params: any): Observable<any> {
    return this.httpCall("GET", routeId, params, null);
  }

  download(method: "GET" | "POST" = "GET", routeId: string, params: any, payload: any = null): Observable<any> {
    return new Observable(o => {
      this.httpCall(method, routeId, params, payload, {observe: "response", responseType: "blob"})
        .subscribe((response: {headers: HttpHeaders, body: Blob}) => {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(response.headers.get("content-disposition"));
          let fileName: string;
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }
          const a = document.createElement("a");
          document.body.appendChild(a);
          const blobUrl = URL.createObjectURL(response.body);
          a.href = blobUrl;
          a.download = fileName;
          a.click();
          setTimeout(() => {
            URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
            o.next();
            o.complete();
          });
        }, (err: HttpErrorResponse) => {
          o.error(err);
          o.complete();
        });
    });
  }

  post(routeId: string, params: any, payload: any): Observable<any> {
    return this.httpCall("POST", routeId, params, payload, null);
  }

  upload(routeId: string, params: any, files: Array<File>, additionalPayload: any = null): Observable<any> {
    const formData = new FormData();
    files.forEach(f => formData.append("file", f, f.name));
    if (additionalPayload) {
      Object.keys(additionalPayload).forEach(k => {
        formData.append(k, additionalPayload[k]);
      });
    }
    return this.post(routeId, params, formData);
  }

  uploadS3(routeId: string, file: File): Observable<string> {
    return new Observable((x: Subscriber<string>) => {
      this.post(routeId, null, {name: file.name})
        .subscribe(async (presigned: {url: string}) => {
          try {
            const uploadResp = await fetch(presigned.url, {
              method: "PUT",
              body: file
            });
            if (!uploadResp.ok) {
              throw new Error(uploadResp.statusText);
            }
            x.next(uploadResp.url);
          } catch (err) {
            x.error(err);
            x.complete();
          }
        }, err => {
          console.error(err);
          x.error(err);
          x.complete();
        });
    });
  }

  put(routeId: string, params: any, payload: any): Observable<any> {
    return this.httpCall("PUT", routeId, params, payload, null);
  }

  patch(routeId: string, params: any, payload: any): Observable<any> {
    return this.httpCall("PATCH", routeId, params, payload, null);
  }

  delete(routeId: string, params: any, payload?: any): Observable<any> {
    return this.httpCall("DELETE", routeId, params, payload, null);
  }

  getAuthImageUrl(url: string): Observable<string> {
    return new Observable(o => {
      if (environment.production) {
        this.apiRouterService.getRouteById("files", {path: url})
          .subscribe((responseUrl: string) => {
            o.next(responseUrl);
            o.complete();
          });
      } else {
        this.httpCall("GET", "files", {path: url}, null, {responseType: "blob"})
          .subscribe((response) => {
            // x.next(this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(response)));
            o.next(URL.createObjectURL(response));
            o.complete();
          }, (error) => {
            if (error.url) {
              o.next(error.url);
            } else {
              o.error(error);
            }
            o.complete();
          });
      }
    });
  }

  downloadFile(url: string): Observable<any> {
    return this.apiRouterService.getRouteById("files", {path: url})
      .pipe(tap((responseUrl: string) => window.open(responseUrl)));
  }
}
