<div class="overflow-hidden p-2" [ngClass]="routerLink ? 'cursor-pointer' : ''" title="{{text | translate}}">
  <div class="d-flex align-items-center justify-content-between">
    <app-icon [name]="icon" [ngClass]="bgIconClass" class="sqr-lg mr-2"></app-icon>
    <div class="d-flex flex-grow-1 align-items-center justify-content-between overflow-hidden">
      <div class="text-ellipsis" [translate]="text"></div>
      <div class="h2 px-2 m-0" [innerText]="value"></div>
    </div>
    <ion-icon name="chevron-forward-outline" color="gray" class="sqr-xs"></ion-icon>
  </div>
</div>
