import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {map, filter} from "rxjs/operators";

export interface MqEvent<T> {
  payload: T;
  fromSocket: boolean;
}

@Injectable({providedIn: "root"})
export class MqService {
  private subject = new Subject<any>();

  pub(queue: string, payload: MqEvent<any> | any): void {
    return this.subject.next({queue, payload});
  }

  evt(queue: string): Observable<any> {
    return this.subject.asObservable()
      .pipe(
        filter(m => m.queue === queue),
        map(m => m.payload)
      );
  }
}
