import {Component, forwardRef, Input, QueryList, ViewChildren} from "@angular/core";
import {ControlValueAccessor, FormArray, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {IonInput} from "@ionic/angular";
import {KeypressUtilsService} from "core";

export interface OptionModel {
  id: string;
  name: string;
}

@Component({
  selector: "app-options-list",
  templateUrl: "./options-list.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OptionsListComponent), multi: true}]
})
export class OptionsListComponent implements ControlValueAccessor {
  @Input() readOnly = false;
  @ViewChildren("inputId") inputItems: QueryList<IonInput>;

  rows: FormArray;

  constructor(private keypressUtilsService: KeypressUtilsService) {
    this.rows = new FormArray([]);
  }

  isValidValue(x: OptionModel) {
    return x && x.id && x.name;
  }

  writeValue(value: Array<OptionModel>) {
    if (!value || !(value instanceof Array)) {
      value = [];
    }
    this.rows.clear();
    value.forEach((v: OptionModel) => this.rows.push(new FormControl(v)));
    if (!this.rows.length || this.isValidValue(this.rows.at(this.rows.length - 1).value)) {
      this.addNewRow();
    }
  }

  registerOnChange(fn: (value: OptionModel) => void) {
    this.rows.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
  }

  private addNewRow() {
    this.rows.push(new FormControl({id: "", value: ""}));
  }

  onFocusout($event: any, index: number, field: "id" | "name") {
    const curr = this.rows.at(index).value;
    curr[field] = $event.target.value;
    this.processValue(curr, index);
  }

  onKeyDown($event: any, index: number, field: "id" | "name") {
    if (!this.keypressUtilsService.isEnterKey($event) && !this.keypressUtilsService.isTabKey($event)) {
      return;
    }
    const curr = this.rows.at(index).value;
    curr[field] = $event.target.value;
    this.processValue(curr, index);
    this.focusNewField();
    return $event.preventDefault();
  }

  clearInput(index: number) {
    this.processValue({id: "", name: ""}, index);
  }

  private processValue(value: OptionModel, index: number) {
    if (!this.isValidValue(value) && index < this.rows.length - 1) {
      this.rows.removeAt(index);
      return;
    }
    if (value && index === this.rows.length - 1) {
      this.addNewRow();
    }
    this.rows.at(index).setValue(value);
  }

  private focusNewField() {
    setTimeout(() => {
      this.inputItems.last.setFocus();
    }, 10);
  }
}
