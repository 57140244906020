<div id="particles-js">
  <div class="page d-flex flex-column justify-content-center align-items-center">
    <div class="inner-circle">
      <ion-fab-button color="secondary" (click)="goBack()">
        <ion-icon name="return-up-back"></ion-icon>
      </ion-fab-button>
      <div class="font-weight-bolder text-center">404</div>
    </div>
    <div class="h4 color-white mt-5">Oops!</div>
  </div>
</div>
