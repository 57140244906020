import {Component, EventEmitter, Input, Output} from "@angular/core";
import {PopoverController} from "@ionic/angular";
import {ColorPickerComponent} from "shared";

@Component({
  selector: "app-color-picker-modal",
  templateUrl: "./color-picker-modal.component.html",
  styleUrls: ["./color-picker-modal.component.scss"]
})
export class ColorPickerModalComponent {
  @Input() color: string;
  @Input() cssClass: string;
  @Input() clearColor = "#000";
  @Input() caption = 'dict.color';
  @Output() submit = new EventEmitter<string>();

  constructor(private popoverController: PopoverController) {
  }

  async pickColor(ev: any) {
    const popover = await this.popoverController.create({
      component: ColorPickerComponent,
      componentProps: {
        color: this.color,
        clearColor: this.clearColor,
        cssClass: "m-2"
      },
      event: ev,
      showBackdrop: false,
      animated: true
    });
    await popover.present();
    const {data} = await popover.onDidDismiss();
    this.submit.emit(data);
  }
}

