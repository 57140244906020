import {FileModel, HateoasItem} from "core";
import {Workspace} from "workspace/workspace.model";
import {DocumentType} from "workspace-taxonomy/workspace-taxonomy.model";
import {UserProfile} from "account/account.model";
import {DynamicFieldValue} from "dynamic-forms/dynamic-forms.model";
import {Exporter} from "shop/shop.models";

export interface Document {
  id?: number;
  uiId?: string;
  workspaceId?: number;
  workspace?: Workspace;
  ownerUserId?: string;
  ownerUser?: UserProfile;
  creationTime?: string; // | Date;
  changeTime?: string; // | Date;
  source?: DocumentSourceType;
  originalFileType?: string;
  size?: number;
  contentUrl?: string;
  thumbUrl?: string;
  hasPdf?: boolean;
  pdfUrl?: string;
  pageCount?: number;
  issueDate?: Date;
  name?: string;
  hasText?: boolean;
  isSeen?: boolean;
  text?: string;
  pipelineStatus?: DocumentPipelineStatus;
  box?: DocumentBox;
  events?: Array<DocumentEvent>;
  allowedActions?: DocumentAllowedActions;

  documentType?: DocumentType;
  documentData?: DocumentData;
  exportStatus?: {
    status?: string;
    errors?: string;
  };
  errors?: Array<string>;
  isProcessing?: boolean;
  progress?: DocumentProgress;

  viewMode?: DocumentViewMode;
  blob?: FileModel;
  uploadError?: string;
}

export interface DocumentAllowedActions {
  allowAssignWorkspace?: boolean;
  allowRetryParse?: boolean;
  allowClassify?: boolean;
  allowUnclassify?: boolean;
  allowReject?: boolean;
  allowExtractData?: boolean;
  allowOperate?: boolean;
  allowRename?: boolean;
  allowArchive?: boolean;
  allowUnarchive?: boolean;
  allowDelete?: boolean;
  allowExport?: boolean;
  allowDeleteJoinSources?: boolean;
  allowSplitToPages?: boolean;
  allowEditOriginalImage?: boolean;
}

export interface DocumentProgress {
  value?: number;
  percent?: number;
}

export interface DocumentTotals {
  documentTypeName: string;
  fieldId: string;
  fieldName: string;
  sum: number;
}

export interface DocumentData {
  issueDate?: Date;
  headerData?: Array<DynamicFieldValue>;
  footerData?: Array<DynamicFieldValue>;
  detailData?: Array<Array<DynamicFieldValue>>;
  briefData?: {[key: string]: string};
}

export interface DocumentOperateRequest {
  name?: string;
  documentData?: DocumentData;
  isFormDataValid?: boolean;
}

export interface DocumentEvent {
  timestamp: Date;
  userId: string;
  pipelineStatus: DocumentPipelineStatus;
  message: string;
}

export enum DocumentBox {
  Search = -1,
  All = 100,
  Registry = 0,
  Unclassified = 1,
  Working = 4,
  Archive = 5
}

export enum DocumentPipelineStatus {
  Deleted = -1000,

  UploadError = -2,
  Uploading = -1,
  Imported = 0,
  RetryRequested = 1,

  PdfCreationFailed = 2,

  Transferable = 40,
  AssignedToWorkspace = 50,

  Unclassified = 60,
  Classified = 70,
  OperatedAI = 90,
  OperatedManual = 91,

  Rejected = 102,

  Archived = 301
}

export const DocumentRegistryStatuses = [
  DocumentPipelineStatus.Imported,
  DocumentPipelineStatus.PdfCreationFailed,
  DocumentPipelineStatus.Transferable
];
export const DocumentUnclassifiedBoxStatuses = [
  DocumentPipelineStatus.AssignedToWorkspace,
  DocumentPipelineStatus.Unclassified];
export const DocumentWorkingBoxStatuses = [
  DocumentPipelineStatus.Classified,
  DocumentPipelineStatus.OperatedAI,
  DocumentPipelineStatus.OperatedManual,
  DocumentPipelineStatus.Rejected];
export const DocumentArchiveBoxStatuses = [
  DocumentPipelineStatus.Archived];

export enum DocumentSourceType {
  Upload = 0,
  Email = 1,
  IntegrationDropbox = 3,
  Manual = 4,
  Join = 5,
  Split = 6,
  Archive = 7,
  SpvAnaf = 8
}

export enum DocumentFileType {
  Acrobat = 1,
  Rich = 2,
  Spreadsheet = 3,
  Presentation = 4,
  Html = 5,
  Text = 6,
  Image = 7,
  Archive = 8
}

export interface SupportedMimeType {
  ext: string;
  mime: string;
  typeName: string;
  type: DocumentFileType;
}

export interface WebPreview {
  url: string;
  title: string;
  imageUrl: string;
  snippet: string;
  fileType: DocumentFileType;
  mimeType: string;
  size: number;
  lastModified: number;
  isAccepted: boolean;
}

export const DocumentFileTypes = [
  DocumentFileType.Acrobat,
  DocumentFileType.Rich,
  DocumentFileType.Spreadsheet,
  DocumentFileType.Presentation,
  DocumentFileType.Html,
  DocumentFileType.Text,
  DocumentFileType.Image,
  DocumentFileType.Archive
];

export enum DocumentExportStatus {
  NotExported = 1,
  Exported = 2
}

export enum DocumentViewMode {
  Pdf = "pdf",
  Txt = "txt",
  Events = "events",
  Renderings = "renderings"
}

export const DocumentViewModes = [DocumentViewMode.Pdf, DocumentViewMode.Txt, DocumentViewMode.Events, DocumentViewMode.Renderings];

export interface DocumentRendering extends HateoasItem {
  id?: number;
  name: string;
  creationTime: Date;
  size: number;
  fileUrl: string;
  thumbUrl: string;
}

export const DocumentMetaFields: Array<string> = [
  "id", "creationTime", "changeTime", "size", "name", "issueDate", "pageCount",
  "thumbUrl", "pdfUrl",
  "documentType.id", "documentType.name",
  "ownerUser.id", "ownerUser.email", "ownerUser.userName", "ownerUser.fullName"]
  .map(x => `document.meta.${x}`);

export const DocumentHostFields: Array<string> = [
  "workspace.id", "workspace.name", "workspace.cui", "workspace.j",
  "workspace.jointStock", "workspace.caen",
  "workspace.bank", "workspace.swift", "workspace.iban",
  "workspace.country", "workspace.region", "workspace.city", "workspace.zipCode", "workspace.address",
  "workspace.phoneNumber", "workspace.email", "workspace.web"]
  .map(x => `document.meta.${x}`);

export interface ExportRequest {
  exporterId?: number;
  includeAlreadyExported?: boolean;
  exporter: Exporter;
}
