<div class="d-flex align-items-center w-100">
  <ion-input class="flex-grow-1"
             [type]="viewType"
             inputmode="text"
             [formControl]="formControl"
             (ionBlur)="onTouched()"
             [label]="placeholder | translate"
             labelPlacement="floating">
  </ion-input>
  <ion-button fill="clear" (click)="switchView()">
    <ion-icon [name]="viewType === 'password' ? 'eye-outline' : 'eye-off-outline'"></ion-icon>
  </ion-button>
</div>
