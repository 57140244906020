import {Injectable} from "@angular/core";
import {ActionSheetController, MenuController, ModalController, Platform, PopoverController} from "@ionic/angular";
import {Observable} from "rxjs";
import {ActivePageComponentService, DateInterval, NavService, TranslateInterpolation} from "core";
import {ActionBarItem, ActionBarItemType, CheckListComponent, ModalAnyComponent, SearchBoxComponent, YearMonthPickerComponent} from "shared";
import {ActionDropdownOptionsComponent} from "./action-dropdown-options.component";

@Injectable({providedIn: "root"})
export class ActionBarItemService {
  private lastEvent: UIEvent | string | any;

  constructor(private menuController: MenuController,
              private nav: NavService,
              private modalController: ModalController,
              private popoverController: PopoverController,
              private actionSheetController: ActionSheetController,
              private translateInterpolation: TranslateInterpolation,
              private platform: Platform,
              private activePageComponentService: ActivePageComponentService) {
  }

  computeShortcut(a: ActionBarItem): string {
    if (!a.hotKey || !this.platform.is("desktop")) {
      return "";
    }
    const isMac = this.platform.testUserAgent("Mac");
    // const isMac = deviceDetectorService.os === "Mac";
    let ret = "";
    if (a.hotKey.includes("meta")) {
      ret += isMac ? "⌘" : "Win";
    }
    if (a.hotKey.includes("control")) {
      ret += `${(ret ? "+" : "")}${isMac ? "^" : "Ctrl"}`;
    }
    if (a.hotKey.includes("alt")) {
      ret += `${(ret ? "+" : "")}${isMac ? "⌥" : "Alt"}`;
    }
    if (a.hotKey.includes("shift")) {
      ret += `${(ret ? "+" : "")}${isMac ? "⇧" : "Shift"}`;
    }
    if (ret && (a.hotKey.includes("."))) {
      ret += "+";
    }
    const dotIdx = a.hotKey.lastIndexOf(".");
    const plainKey = dotIdx !== -1
      ? a.hotKey.substring(dotIdx + 1)
      : null;
    if (plainKey) {
      ret += plainKey.toUpperCase();
    }
    if (plainKey === "space") {
      ret += "Space";
    }
    if (plainKey === "delete") {
      ret += "Del";
    }
    if (plainKey === "enter") {
      ret += "Enter";
    }
    if (plainKey === "tab") {
      ret += "Tab";
    }
    if (plainKey === "backspace") {
      ret += "Backspace";
    }
    if (plainKey === "esc") {
      ret += "Esc";
    }
    return ret;
  }

  isHidden(a: ActionBarItem): boolean {
    const isHidden = (typeof a?.isHidden === "function")
      ? a.isHidden.apply(null)
      : a?.isHidden;
    return isHidden
      || !a
      || !!a.isHiddenOnPlatforms?.find(p => this.platform.is(p))
      || ((a.type === ActionBarItemType.Link || !a.type) && a?.actions instanceof Array && !a?.actions?.length && !a.handler && !a.routerLink && !a.isDisabled);
  }

  isDisabled(a: ActionBarItem): boolean {
    if (typeof a?.isDisabled === "function") {
      return a.isDisabled.apply(null, [a]);
    }
    return a?.isDisabled;
  }

  getTitle(a: ActionBarItem): string {
    if (typeof a?.title === "function") {
      return a.title.apply(null, [a]);
    }
    if (typeof (a.title) === "string") {
      return a.title;
    }
    const platform = this.platform.width() >= 768 ? "desktop" : "mobile";
    const titlePlatform = a.title && Object.keys(a.title).find(p => p === platform);
    return (titlePlatform && a.title[titlePlatform]) || "";
  }

  getAnyTitle(a: ActionBarItem): string {
    if (typeof (a.title) === "string") {
      return a.title;
    }
    const titlePlatform = a.title && Object.keys(a.title).find(p => a.title[p]);
    return (titlePlatform && a.title[titlePlatform]) || "";
  }

  cssClass(a: ActionBarItem): string {
    if (typeof a?.cssClass === "function") {
      return a.cssClass.apply(null, [a]);
    }
    return a?.cssClass;
  }

  cssStyle(a: ActionBarItem): {[p: string]: any} {
    if (typeof a?.cssStyle === "function") {
      return a.cssStyle.apply(null, [a]);
    }
    return a?.cssStyle;
  }

  icon(a: ActionBarItem): string {
    if (typeof a?.icon === "function") {
      return a.icon.apply(null, [a]);
    }
    return a?.icon;
  }

  actionValue(a: ActionBarItem): string {
    if (typeof a?.actionValue === "function") {
      return a.actionValue.apply(null, [a]);
    }
    return a?.actionValue;
  }

  async callAction(actionBarItem: ActionBarItem, $event: UIEvent) {
    try {
      await this.popoverController.dismiss();
      await this.menuController.close();
    } catch (e) {
    }
    if (this.isDisabled(actionBarItem)) {
      return;
    }
    let actionBarItemActions: any = actionBarItem.actions;
    if (actionBarItem.actions instanceof Observable) {
      actionBarItemActions = await actionBarItem.actions.toPromise();
    }

    const isDesktop = this.platform.width() >= 768;
    const hasActions = !!actionBarItemActions?.length;
    const isMultiselect = hasActions && actionBarItem.type === ActionBarItemType.LinkMultiselect;

    if (isDesktop) {
      if (actionBarItem.type === ActionBarItemType.Segment) {
      } else if (hasActions) {
        await this.presentPopover(actionBarItem, actionBarItemActions, this.lastEvent || $event);
        this.lastEvent = $event;
        return;
      } else if ([ActionBarItemType.YearMonthPicker, ActionBarItemType.ColorPicker].includes(actionBarItem.type)) {
        const augmentedActionBarItem: ActionBarItem = Object.assign({}, actionBarItem, {
          actions: [actionBarItem],
          actionsTitle: actionBarItem.title
        });
        await this.presentPopover(augmentedActionBarItem, actionBarItemActions, $event);
        this.lastEvent = $event;
        return;
      }
    } else {
      if (isMultiselect) {
        await this.presentMultiSelectModal(actionBarItem, actionBarItemActions);
        this.lastEvent = $event;
        return;
      } else if (hasActions) {
        await this.presentActionsSheet(actionBarItemActions, actionBarItem.actionsTitle, this.lastEvent || $event);
        this.lastEvent = $event;
        return;
      } else if (actionBarItem.type === ActionBarItemType.Search) {
        await this.presentInputTextModal(actionBarItem);
        this.lastEvent = $event;
        return;
      } else if (actionBarItem.type === ActionBarItemType.YearMonthPicker) {
        await this.presentYearMonthPickerModal(actionBarItem);
        this.lastEvent = $event;
        return;
      } else if (actionBarItem.type === ActionBarItemType.TextBox) {
        await this.presentInputTextModal(actionBarItem);
        this.lastEvent = $event;
        return;
      }
    }
    this.lastEvent = null;
    return this.callActionHandler(actionBarItem, $event);
  }

  callActionHandler(actionBarItem: ActionBarItem, $event) {
    if (actionBarItem.handler) {
      actionBarItem.handler.apply(null, [actionBarItem, $event]);
    }
    if (actionBarItem.routerLink) {
      this.nav.go(actionBarItem.routerLink).then(() => {
        if (actionBarItem.type !== ActionBarItemType.Shortcut) {
          return;
        }
        setTimeout(() => {
          const activeComponent = this.activePageComponentService.activeComponent as any;
          // console.log(activeComponent);
          if (!actionBarItem.componentMethodFn) {
            return;
          }
          const fn = activeComponent[actionBarItem.componentMethodFn] as Function;
          if (!fn) {
            return;
          }
          setTimeout(() => fn.apply(activeComponent, actionBarItem.componentMethodFnArgs), 300);
        }, 100);
      });
    }
  }

  private async presentInputTextModal(actionBarItem: ActionBarItem) {
    const modal = await this.modalController.create({
      component: ModalAnyComponent,
      cssClass: "responsive-modal",
      componentProps: {
        title: actionBarItem.title,
        itemComponent: SearchBoxComponent,
        itemValue: actionBarItem.actionValue,
        itemProps: {
          hasBorder: true,
          value: actionBarItem.actionValue,
          placeholder: actionBarItem.title,
          autofocus: 1000
        },
        submitCaption: "dict.ok"
      }
    });
    modal.onDidDismiss().then((x: {data: string, role: any}) => {
      actionBarItem.actionValue = x.data;
      actionBarItem.handler.apply(null, [actionBarItem, x.data]);
    });
    await modal.present();
  }

  private async presentYearMonthPickerModal(actionBarItem: ActionBarItem) {
    const modal = await this.modalController.create({
      component: ModalAnyComponent,
      cssClass: "responsive-modal",
      componentProps: {
        title: actionBarItem.title,
        itemComponent: YearMonthPickerComponent,
        itemValue: actionBarItem.actionValue,
        submitCaption: "Ok",
        actions: [{
          type: ActionBarItemType.Link,
          icon: "trash",
          color: "light",
          handler: () => this.modalController.dismiss({dateFrom: null, dateTo: null} as DateInterval)
        } as ActionBarItem]
      }
    });
    modal.onDidDismiss().then((x: {data: DateInterval, role: any}) => {
      if (x.data) {
        actionBarItem.actionValue = x.data;
        actionBarItem.handler.apply(null, [actionBarItem, x.data]);
      }
    });
    await modal.present();
  }

  private async presentMultiSelectModal(actionBarItem: ActionBarItem, actionBarItemActions: Array<ActionBarItem>) {
    const modal = await this.modalController.create({
      component: ModalAnyComponent,
      cssClass: "responsive-modal",
      componentProps: {
        title: actionBarItem.title,
        itemComponent: CheckListComponent,
        itemProps: {
          options: actionBarItemActions.map(a => {
            return {id: a.actionValue, name: this.translateInterpolation.translate(this.getTitle(a))};
          })
        },
        itemValue: actionBarItem.actionValue,
        submitCaption: "Ok",
        actions: [{
          type: ActionBarItemType.Link,
          icon: "trash",
          color: "light",
          handler: () => this.modalController.dismiss([])
        } as ActionBarItem]
      }
    });
    modal.onDidDismiss().then((x: {data: Array<any>, role: any}) => {
      if (x.data) {
        actionBarItem.actionValue = x.data;
        actionBarItem.handler.apply(null, [actionBarItem, x.data]);
      }
    });
    await modal.present();
  }

  private async presentPopover(actionBarItem: ActionBarItem, actionBarItemActions: Array<ActionBarItem>, $event: any) {
    try {
      await this.popoverController.dismiss();
    } catch (e) {
    }
    let actions: Array<ActionBarItem> = [];
    const titleItem: ActionBarItem = actionBarItem.actionsTitle && {
      type: ActionBarItemType.Label,
      title: actionBarItem.actionsTitle
    };
    if (titleItem) {
      actions.push(titleItem);
    }
    if (actionBarItem && actionBarItem.type === ActionBarItemType.YearMonthPicker) {
      actions.push(actionBarItem);
    }
    if (actionBarItem && actionBarItem.type === ActionBarItemType.ColorPicker) {
      actions.push(actionBarItem);
    }
    if (actionBarItemActions) {
      actions = actions.concat(actionBarItemActions);
    }
    let popoverHeight = actions.reduce((sum: number, a: ActionBarItem) => {
      const aHeight = a.type === ActionBarItemType.YearMonthPicker || a.type === ActionBarItemType.ColorPicker
        ? 286
        : a.type === ActionBarItemType.Bool
          ? 44
          : 42;
      return sum + aHeight;
    }, 0);
    if (actions.find(a => a.type === ActionBarItemType.Bool)) {
      popoverHeight += 72;
    }
    if (popoverHeight > 435) {
      popoverHeight = 435;
    }
    const popover = await this.popoverController.create({
      component: ActionDropdownOptionsComponent,
      componentProps: {
        actions,
        enableFiltering: actionBarItem.enableFiltering,
        isMultiselect: actionBarItem.type === ActionBarItemType.LinkMultiselect,
        actionValue: actionBarItem.actionValue
      },
      // cssClass: "action-bar",
      cssClass: $event.clientY >= window.innerHeight - popoverHeight
        ? "at-bottom"
        : "action-bar",
      event: $event,
      showBackdrop: false,
      animated: true,
      reference: "event",
      arrow: false,
      size: "auto"
    });
    popover.onDidDismiss().then((x: {data: any, role: undefined | "backdrop"}) => {
      if (x.role === "backdrop") {
        this.lastEvent = null;
      }
      if (x.data) {
        actionBarItem.actionValue = x.data;
        actionBarItem.handler.apply(null, [actionBarItem, x.data]);
      }
    });
    return await popover.present();
  }

  private async presentActionsSheet(actions: Array<ActionBarItem>, title: string, ev: any) {
    const actionSheet = await this.actionSheetController.create({
      header: (title && this.translateInterpolation.translate(title))
        || (actions[0].type === ActionBarItemType.Label && this.translateInterpolation.translate(this.getTitle(actions[0])))
        || (actions[0].type === ActionBarItemType.Refresh && this.translateInterpolation.translate(this.getTitle(actions[0])))
        || undefined,
      buttons: actions
        .filter(x => !this.isHidden(x)
          && x.title
          && x.type !== ActionBarItemType.Refresh
          && x.type !== ActionBarItemType.Separator
          && x.type !== ActionBarItemType.Label)
        .map(x => {
            return {
              text: this.translateInterpolation.translate(this.getTitle(x)),
              role: x.color && x.color.indexOf("danger") !== -1 ? "destructive" : "",
              icon: x.parent?.actionValue?.length
                ? x.parent.actionValue
                  .findIndex(pav => JSON.stringify(pav) === JSON.stringify(x.actionValue)) !== -1
                  ? "checkmark"
                  : this.icon(x)
                : this.icon(x),
              data: x.actionValue,
              handler: () => this.callAction(x, ev) // x.value !== "true")
            };
          }
        ).concat([{
          text: this.translateInterpolation.translate("dict.cancel"),
          role: "cancel",
          icon: null,
          data: null,
          handler: () => null
        }]),
      animated: true,
    });
    return await actionSheet.present();
  }

  private async presentActionsPopover(actions: Array<ActionBarItem>, title: string, ev: any, enableFiltering = false, isMultiselect = false, limitItemsDisplay = 0) {
    try {
      await this.popoverController.dismiss();
    } catch (e) {
    }
    const titleItem: ActionBarItem = title && {
      type: ActionBarItemType.Label,
      title: title
    };
    const visibleActions = actions.filter(a => !this.isHidden(a));
    const popover = await this.popoverController.create({
      component: ActionDropdownOptionsComponent,
      componentProps: {
        actions: titleItem
          ? [titleItem].concat(visibleActions)
          : visibleActions,
        enableFiltering,
        isMultiselect,
        limitItemsDisplay
      },
      // cssClass: ev.clientY >= window.innerHeight - 100 ? `bottom-right-${visibleActions.length}` : "",
      cssClass: ev.clientY >= window.innerHeight - 100 ? "at-bottom" : "",
      event: ev,
      showBackdrop: false,
      animated: true,
      reference: "event",
      arrow: false,
      size: "auto"
    });
    popover.onDidDismiss().then((x: {data: any, role: undefined | "backdrop"}) => {
      if (x.role === "backdrop") {
        this.lastEvent = null;
      }
    });
    return await popover.present();
  }

  async presentLinkActions(actions: Array<ActionBarItem>, title: string, ev: any, enableFiltering = false, isMultiselect = false, limitItemDisplay = 0) {
    if (!actions?.length) {
      return;
    }
    const isDesktop = this.platform.width() >= 768;
    if (isDesktop) {
      return this.presentActionsPopover(actions, title, ev, enableFiltering, isMultiselect, limitItemDisplay);
    }
    return this.presentActionsSheet(actions, title, ev);
  }
}
