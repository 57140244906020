import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output} from "@angular/core";
import {SwiperOptions} from "swiper/types";
import Swiper from "swiper";

@Directive({
  selector: "[appSwiper]"
})
export class SwiperDirective implements AfterViewInit, OnDestroy {
  @Input() config?: SwiperOptions;
  @Output() slideChange = new EventEmitter<Swiper>();
  @Output() swiperInit = new EventEmitter<Swiper>();

  constructor(private el: ElementRef<HTMLElement & {initialize: () => void, swiper: Swiper}>) {
  }

  ngAfterViewInit() {
    Object.assign(this.el.nativeElement, this.config);

    this.el.nativeElement.initialize();
    this.swiperInit.emit(this.el.nativeElement.swiper);
    this.el.nativeElement.swiper.on("slideChange", (e: Swiper) => this.slideChange.emit(e));
  }

  ngOnDestroy() {
    this.el.nativeElement.swiper.off("slideChange");
  }
}
