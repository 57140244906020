import {Observable} from "rxjs";
import {ClipboardService} from "ngx-clipboard";

export interface ShareData {
  title?: string;
  receiver?: string;
  body?: string;
  url?: string;
  image?: string;
  tags?: string;
}

export enum ShareMethod {
  Link = "link",
  Window = "window"
}

export interface ShareType {
  name?: string;
  target?: string;
  method?: ShareMethod;
  shareUrl?: {
    desktop?: string | ((...args) => Observable<any>);
    android?: string | ((...args) => Observable<any>);
    ios?: string | ((...args) => Observable<any>);
  };
  shareParams?: {[key: string]: string};
}

export const ShareTypes = {
  viber: {
    name: "Viber",
    method: ShareMethod.Link,
    shareUrl: {
      android: "viber://forward?",
      ios: "viber://forward?"
    },
    shareParams: {
      text: "{{body}}\n{{url}}"
    }
  } as ShareType,
  // telegram: ShareType;
  // messenger: ShareType;
  whatsapp: {
    name: "WhatsApp",
    method: ShareMethod.Link,
    shareUrl: {
      desktop: "https://web.whatsapp.com/send?",
      android: "whatsapp://send?",
      ios: "https://api.whatsapp.com/send?"
    },
    shareParams: {
      text: "{{body}}\n{{url}}"
    }
  } as ShareType,
  sms: {
    name: "SMS",
    method: ShareMethod.Link,
    shareUrl: {
      desktop: "sms:?",
      ios: "sms:&"
    },
    shareParams: {
      body: "{{body}}\n{{url}}"
    }
  } as ShareType,
  email: {
    name: "Email",
    method: ShareMethod.Link,
    shareUrl: {
      desktop: "mailto:?"
    },
    shareParams: {
      to: "{{receiver}}",
      subject: "{{title}}",
      body: "{{body}}\n{{url}}"
    }
  } as ShareType,
  clipboard: {
    name: "clipboard",
    shareUrl: {
      desktop: (clipboardService: ClipboardService, shareData: ShareData): Observable<any> => {
        clipboardService.copy(shareData.url);
        return clipboardService.copyResponse$;
      }
    },
    shareParams: {
      to: "{{to}}",
      subject: "{{title}}",
      body: "{{body}}\n{{url}}"
    }
  } as ShareType
};
