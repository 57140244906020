import {Component} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {AngularNodeViewComponent} from "ngx-tiptap";
import {mergeAttributes} from "@tiptap/core";
import {FieldDataType} from "dynamic-forms/dynamic-forms.model";
import {ModalAnyComponent} from "shared";
import {ReportDesignerFieldSettingsComponent} from "../report-designer-field-settings/report-designer-field-settings.component";

@Component({
  selector: "app-tiptap-node-field",
  templateUrl: "./tiptap-node-field.component.html",
  styleUrls: ["./tiptap-node-field.component.scss"]
})
export class TipTapNodeFieldComponent extends AngularNodeViewComponent {
  constructor(private modalController: ModalController) {
    super();
  }

  protected readonly FieldDataType = FieldDataType;

  onImageResizeEnd($event: {width: number; height: number}) {
    const pos = this.editor.state.selection.from;
    this.editor.chain()
      .focus()
      .updateAttributes("field", mergeAttributes(this.editor.getAttributes("field"), {fieldWidth: $event.width, fieldHeight: $event.height}))
      .setNodeSelection(pos)
      .run();
  }

  async openFieldSettings() {
    const modal = await this.modalController.create({
      component: ModalAnyComponent,
      cssClass: "responsive-modal",
      componentProps: {
        title: this.editor.getAttributes("field").fieldName,
        itemComponent: ReportDesignerFieldSettingsComponent,
        itemProps: {editor: this.editor}
      }
    });
    await modal.present();
  }
}
