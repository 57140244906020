export interface YearMonthPickerOptions {
  closeOnSelect?: boolean; // Closes dropdown on month selection
  disabledDates?: Date[]; // Disable individual dates
  disabledDateRanges?: [Date, Date][]; // Disable date ranges
  format?: string; // Format for year (yyyy, yy, mmmm, mmm, mm)
  resetYearOnBlur?: boolean; // Reset year view in dropdown on blur
  yearMax?: number; // Minimum year range
  yearMin?: number; // Maximum year range
  yearStart?: number; // Dialog opens to this no matter what date selected
  selectRange?: boolean;
}

export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const monthAbbrev = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
