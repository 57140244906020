@if (node.attrs.fieldDataType === FieldDataType.ImageUrl) {
  <div class="position-relative"
       appResizable
       [snapResolution]="10"
       [disabled]="!editor.getAttributes('field').fieldWidth"
       (resizeEnd)="onImageResizeEnd($event)"
       [style.width.px]="node.attrs.fieldWidth"
       [style.height.px]="node.attrs.fieldHeight">
    <img class="w-100 h-100" src="assets/app-icons/logo-actas.1280x1280.png"/>
  </div>
} @else {
  <span (dblclick)="openFieldSettings()">{{node.attrs.fieldName}}</span>
}
