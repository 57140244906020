import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren} from "@angular/core";
import {MenuController} from "@ionic/angular";
import {HotkeyEvent, HotkeyMap, HotkeysService, SubSink} from "core";
import {blinkAnimation} from "../ng-animations/animations";
import {ActionBarItemService} from "./action-bar-item.service";
import {ActionBarItem, ActionBarItemSlot, ActionBarItemType, ActionBarType} from "./action.model";
import {SearchBoxComponent} from "shared";

@Component({
  selector: "app-action-bar-side-menu-button",
  template: `
    <ion-fab-button *ngIf="icon" [color]="color" class="no-shadow m-0 sqr-md" (click)="openSideMenu()">
      <ion-icon [name]="icon"></ion-icon>
    </ion-fab-button>
    <div *ngIf="!icon" class="cursor-pointer" (click)="openSideMenu()">
      <ng-content></ng-content>
    </div>`
})
export class ActionBarSideMenuButtonComponent {
  @Input() icon = "";
  @Input() actionBarId?: string;
  @Input() color = "primary";

  constructor(private menuController: MenuController) {
  }

  openSideMenu() {
    this.menuController.enable(true, this.actionBarId)
      .then(() => this.menuController.open(this.actionBarId).then());
  }
}

@Component({
  selector: "app-action-bar",
  templateUrl: "./action-bar.component.html",
  styleUrls: ["./action-bar.component.scss"],
  animations: [blinkAnimation]
})
export class ActionBarComponent implements OnInit, OnDestroy {
  @Input() actionBarId?: string;
  @Input() type: ActionBarType = ActionBarType.Toolbar;
  @Input() actions: Array<ActionBarItem>;
  @Input() color = "white";
  @Input() cssClass = "";

  @Input() set dropOptions(value: {fabIcon?: string, collapseOnCount?: number}) {
    this._dropOptions = Object.assign({}, {fabIcon: "ellipsis-horizontal", collapseOnCount: 2}, value);
  }

  @Input() sideMenuOptions: {side?: "start" | "end", contentId?: string} = {side: "start", contentId: "main"};
  @Input() sideBarOptions: {isExpanded: boolean, isClosed: boolean} = {isExpanded: false, isClosed: null};
  @Input() bCrumbsOptions: {showLastSeparator: boolean} = {showLastSeparator: false};

  @Output() toggleSideBar = new EventEmitter<boolean>();

  @ViewChildren(SearchBoxComponent) searchBoxes: QueryList<SearchBoxComponent>;

  ActionBarItemType = ActionBarItemType;
  ActionBarType = ActionBarType;
  private sub = new SubSink();
  private isHandlingStarted = false;
  protected _dropOptions: {fabIcon?: string, collapseOnCount?: number} = {fabIcon: "ellipsis-horizontal", collapseOnCount: 2};

  constructor(private menuController: MenuController,
              private hotKeysService: HotkeysService,
              private actionBarItemService: ActionBarItemService) {
  }

  ngOnInit() {
    // console.log("ngOnInit ActionBAr");
    this.hotKeysService.addShortcuts(this.collectAllHotkeys(this.actions))
      .subscribe(x => this.sub.sink = x.subscribe((e: HotkeyEvent) => this.handleHotkey(e, this.actions)));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  get actionsStart(): Array<ActionBarItem> {
    return this.actions.filter(x => x.slot === ActionBarItemSlot.Start);
  }

  get actionsMain(): Array<ActionBarItem> {
    return this.actions.filter(x => !x.slot || x.slot === ActionBarItemSlot.Main);
  }

  get actionsEnd(): Array<ActionBarItem> {
    return this.actions.filter(x => x.slot === ActionBarItemSlot.End);
  }

  get hasChips(): boolean {
    return this.actions.some(x => [ActionBarItemType.LinkMultiselect, ActionBarItemType.YearMonthPicker].indexOf(x.type) !== -1 && !this.isHidden(x) && !!x.actionValueTexts?.length);
  }

  handleHotkey(e: HotkeyEvent, actions: Array<ActionBarItem>) {
    const candidateActions = actions.filter(x => !this.isHidden(x) && !x.isDisabled);
    for (let i = 0; i < candidateActions.length; i++) {
      const action = candidateActions[i];
      if (!action.hotKey) {
        if ((action.actions as any)?.length) {
          this.handleHotkey(e, action.actions as Array<ActionBarItem>);
        }
        continue;
      }
      if (action.hotKey === e.hotKey) {
        if (action.type === ActionBarItemType.SearchAnimate) {
          const searchComponent = this.searchBoxes.find(x => x.isAnimated && x.clearIconName === "close-outline" && x.autofocus === 10 && x.iconCssClass === action.iconCssClass && x.cssClass === action.cssClass);
          searchComponent?.showInputBox(true);
        } else {
          this.handleAction(action, e);
        }
      }
    }
  }

  handleAction(actionBarItem: ActionBarItem, $event: UIEvent | any) {
    if (this.isHandlingStarted) {
      return;
    }
    this.isHandlingStarted = true;
    setTimeout(() => this.isHandlingStarted = false, 100);
    if (this.actionBarId) {
      this.menuController.close(this.actionBarId);
    }
    this.actionBarItemService.callAction(actionBarItem, $event);
  }

  removeValueAndHandleAction(action: ActionBarItem, $event: any, value: any = null) {
    const isArray = action.actionValue instanceof Array;
    if (isArray) {
      const idx = action.actionValue.findIndex(x => x === value);
      if (idx === -1) {
        return;
      }
      action.actionValue.splice(idx, 1);
    } else {
      action.actionValue = value;
    }
    setTimeout(() => this.actionBarItemService.callActionHandler(action, $event));
  }

  isHidden(a: ActionBarItem): boolean {
    return this.actionBarItemService.isHidden(a);
  }

  isDisabled(a: ActionBarItem): boolean {
    return this.actionBarItemService.isDisabled(a);
  }

  getTitle(a: ActionBarItem): string {
    return this.actionBarItemService.getTitle(a);
  }

  itemCssClass(a: ActionBarItem): string {
    return this.actionBarItemService.cssClass(a);
  }

  itemCssStyle(a: ActionBarItem): {[p: string]: any} {
    return this.actionBarItemService.cssStyle(a);
  }

  itemIcon(a: ActionBarItem): string {
    return this.actionBarItemService.icon(a);
  }

  itemActionValue(a: ActionBarItem): string {
    return this.actionBarItemService.actionValue(a);
  }

  async openActions(actions: Array<ActionBarItem>, $event: any) {
    console.log($event);
    await this.actionBarItemService.presentLinkActions(actions, null, $event);
  }

  handleIonSegmentChange(a: ActionBarItem, $event: any) {
    a.actionValue = $event.detail.value;
    this.handleAction(a, $event);
  }

  async handleIonInputChange(a: ActionBarItem, $event: any) {
    a.actionValue = $event.target.value;
    await this.handleAction(a, $event);
  }

  async handleIonCheckboxChange(a: ActionBarItem, $event: any) {
    a.actionValueTexts = $event.detail.checked ? [$event.detail.checked] : [];
    await this.handleAction(a, $event);
  }

  private collectAllHotkeys(from: Array<ActionBarItem>, ret: Array<HotkeyMap> = null) {
    if (!ret) {
      ret = [];
    }
    if (!from) {
      return ret;
    }
    from.forEach(x => {
      if (x.actions) {
        this.collectAllHotkeys(x.actions as Array<ActionBarItem>, ret);
      } else if (x.hotKey) {
        ret.push({
          hotKey: x.hotKey,
          description: this.actionBarItemService.getAnyTitle(x),
          icon: this.itemIcon(x)
        });
      }
    });
    return ret;
  }
}
