import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ModalController} from "@ionic/angular";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator,
  Validators
} from "@angular/forms";
import {of} from "rxjs";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {FileModel, FileSource, LoadState} from "core";
import {DocumentsApiService} from "documents/documents.api.service";
import {SupportedMimeType, WebPreview} from "documents/documents.model";

@Component({
  selector: "app-weblink-input",
  templateUrl: "./weblink-input.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => WeblinkInputComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => WeblinkInputComponent), multi: true}]
})
export class WeblinkInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() acceptedMimeTypes: Array<SupportedMimeType>;
  @Input() label: string;
  @Input() created: (x: WeblinkInputComponent) => void;

  loadState = new LoadState();
  form: FormGroup;
  onTouched: () => void;

  constructor(private activatedRoute: ActivatedRoute,
              private modalController: ModalController,
              private documentsApiService: DocumentsApiService) {
    this.form = new FormGroup({
      "url": new FormControl("", [Validators.required]),
      "fileModel": new FormControl(null, [Validators.required])
    });
    this.form.get("url").valueChanges
      .pipe(tap(() => {
        this.loadState.setWorking();
        this.form.get("fileModel").setValue(null);
      }))
      .pipe(debounceTime(500))
      .pipe(switchMap((url: string) => this.documentsApiService.previewWeblink(url).pipe(catchError(() => of(null)))))
      .pipe(map((webPreview: WebPreview) => webPreview?.isAccepted ? webPreview : null))
      .subscribe((webPreview: WebPreview) => {
        this.form.get("fileModel").setValue(webPreview?.isAccepted ? {
          source: FileSource.Weblink,
          name: webPreview.title || webPreview.url,
          type: webPreview.mimeType,
          size: webPreview.size,
          contentUrl: webPreview.url,
          previewImageUrl: webPreview.imageUrl,
          lastModified: webPreview.lastModified
        } : null);
        this.loadState.setOk();
      });
  }

  ngOnInit() {
    this.loadState.setOk();
    this.created(this);
  }

  writeValue(value: {value: string, fileModel: FileModel}) {
    this.form.setValue(value);
  }

  registerOnChange(fn: (value: {value: string, fileModel: FileModel}) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : {invalid: {message: "Url is invalid"}};
  }
}
