import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class RedirectService {
  post(obj: object, url: string, target: string = "_self") {
    const form = document.createElement("form");
    form.target = target;
    form.method = "POST"; // or "post" if appropriate
    form.action = url;
    Object.keys(obj).forEach((param) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = param;
      input.setAttribute("value", obj[param]);
      form.appendChild(input);
    });
    document.body.appendChild(form);
    form.submit();
  }
}
