<form [formGroup]="form"
      class="row no-gutters">
  <ion-item class="floating-label no-margin col-3">
    <ion-input formControlName="field"
               type="string" spellcheck="false"
               [readonly]="readOnly"
               [label]="'exporterFilterRule.field' | translate"
               labelPlacement="floating">
    </ion-input>
    <ion-icon name="trash" color="danger" class="cursor-pointer sqr-xs"
              (click)="remove.emit()">
    </ion-icon>
  </ion-item>

  <div class="pl-2 col-9">
    <app-exporter-filter-rule-operation *ngFor="let opControl of ops().controls; let idx=index"
                                        [formControl]="$any(opControl)"
                                        (remove)="ops().removeAt(idx)">
    </app-exporter-filter-rule-operation>
    @if (!readOnly) {
      <ion-button class="sqr-md"
                  fill="outline"
                  (click)="addNewOp()">
        <ion-icon name="add"></ion-icon>
      </ion-button>
    }
  </div>
</form>
