<form [formGroup]="form">
  <input formControlName="exchangeRate" type="hidden">
  <div class="row no-gutters">
    <ion-item appIonFloatingLabel class="col-12 col-md-4 valueFrom">
      <ion-label>{{label | translate}} {{'currencyExchange.valueFrom' | translate}}</ion-label>
      <div style="width: 35%">
        <ion-input formControlName="currencyFrom"
                   type="text" spellcheck="false" aria-labelledby=""
                   [readonly]="readOnly"
                   (focusin)="focused.emit({dataType: 'string', cssPath:'currencyFrom'})">
        </ion-input>
      </div>
      <ion-input formControlName="valueFrom"
                 type="number" spellcheck="false" aria-labelledby=""
                 [readonly]="readOnly"
                 (focusin)="focused.emit({dataType: 'number', cssPath:'valueFrom'})">
      </ion-input>
    </ion-item>

    <ion-item class="col-12 col-md-4 px-md-1 floating-label exchangeDate">
      <app-date-picker formControlName="exchangeDate"
                       [readOnly]="readOnly"
                       [label]="'currencyExchange.exchangeDate' | translate"
                       (inputClick)="focused.emit({dataType: 'date', cssPath: 'exchangeDate'})">
      </app-date-picker>
    </ion-item>

    <ion-item appIonFloatingLabel class="col-12 col-md-4 valueTo">
      <ion-label>{{(label || 'currencyExchange.valueTo') | translate}} (x {{form.get('exchangeRate').value}})</ion-label>
      <div style="width: 35%">
        <ion-input formControlName="currencyTo"
                   type="text" spellcheck="false" aria-labelledby=""
                   [readonly]="readOnly"
                   (focusin)="focused.emit({dataType: 'string', cssPath: 'currencyTo'})">
        </ion-input>
      </div>
      <ion-input formControlName="valueTo"
                 type="number" spellcheck="false" aria-labelledby=""
                 [readonly]="readOnly"
                 (focusin)="focused.emit({dataType: 'number', cssPath:'valueTo'})">
      </ion-input>
    </ion-item>
  </div>
</form>
