import {Component, Input, OnInit} from "@angular/core";
import {ActionBarItem} from "./action.model";
import {ActionBarItemService} from "./action-bar-item.service";
import {Observable} from "rxjs";

@Component({
  selector: "app-action-dropdown",
  templateUrl: "action-dropdown.component.html",
  styles: [":host {display: contents}"]
})
export class ActionDropdownComponent implements OnInit {
  @Input() actions: Array<ActionBarItem> | Observable<Array<ActionBarItem>>;
  @Input() autoOpenOnElement: HTMLDivElement;
  @Input() enableFiltering = false;
  @Input() isMultiselect = false;
  @Input() limitItemsDisplay: number;

  constructor(private actionBarItemService: ActionBarItemService) {
  }

  async ngOnInit() {
    if (this.autoOpenOnElement) {
      await this.openOptions({target: this.autoOpenOnElement});
    }
  }

  async openOptions(ev: any) {
    if (Array.isArray(this.actions)) {
      await this.actionBarItemService.presentLinkActions(this.actions as Array<ActionBarItem>, null, ev, this.enableFiltering, this.isMultiselect, this.limitItemsDisplay);
    } else {
      this.actions.subscribe(async (x: Array<ActionBarItem>) => {
        await this.actionBarItemService.presentLinkActions(x, null, ev, this.enableFiltering, this.isMultiselect, this.limitItemsDisplay);
      });
    }
  }
}
