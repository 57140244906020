import {Injectable} from "@angular/core";
import {ModalController, Platform} from "@ionic/angular";
import {catchError, filter, map} from "rxjs/operators";
import {Observable, of, Subject, tap} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {TourService} from "ngx-ui-tour-ionic";
import {IonStepOption} from "ngx-ui-tour-ionic/lib/step-option.interface";
import {ApiService, SubSink} from "core";

export interface TourInfo {
  tour: string;
  steps: Array<{
    anchor: string;
    content: {
      [key: string]: {title: string, message: string, endBtnTitle?: string, width?: string}
    }
  }>;
}

@Injectable({providedIn: "root"})
export class TourExtService {
  constructor(private platform: Platform,
              private tourService: TourService,
              private modalController: ModalController,
              private translateService: TranslateService,
              private apiService: ApiService) {
  }

  end$ = new Subject<string>();

  private currentTopModalId: string;
  private timerId: any;
  private sub = new SubSink();

  init(tourId: string, timeout = 1000) {
    const isDesktop = this.platform.width() >= 768;
    if (!tourId || !isDesktop) {
      return;
    }
    this.getTopModalId()
      .subscribe((topModalId: string) => {
        this.currentTopModalId = topModalId;
        this.timerId = setTimeout(() => {
          this.getStatus(tourId)
            .subscribe((status) => {
              if (status === "start") {
                this.startTour(tourId);
              } else {
                console.log(`Tour end (not started): ${tourId}`);
                this.end$.next(tourId);
              }
            });
        }, timeout);
      });
  }

  destroy() {
    this.sub.unsubscribe();
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  private startTour(tourId: string) {
    if (!tourId) {
      return;
    }
    this.sub.unsubscribe(true);
    this.getTopModalId()
      .pipe(filter((topModalId: string) => topModalId === this.currentTopModalId))
      .subscribe(() => {
        const lang = this.translateService.currentLang;
        fetch(`./assets/tours/${tourId}.tour.json`)
          .then(res => res.json())
          .then((tourInfo: TourInfo) => {
            console.log(tourInfo);
            const steps: IonStepOption[] = tourInfo.steps.map(x => {
              return Object.assign({
                anchorId: x.anchor,
                content: x.content[lang]?.message,
                title: `💡 ${x.content[lang]?.title || ""}`,
                enableBackdrop: false,
                popoverClass: "tour",
                isOptional: true,
                duplicateAnchorHandling: "registerLast",
                prevBtnTitle: this.translateService.instant("dict.prev"),
                nextBtnTitle: this.translateService.instant("dict.next"),
                endBtnTitle: this.translateService.instant(x.content[lang]?.endBtnTitle || "dict.end")
              }, x.content[lang]?.width ? {stepDimensions: {width: x.content[lang]?.width}} as any : null);
            });
            this.tourService.initialize(steps);
            this.tourService.start();
            this.sub.sink = this.tourService.end$.subscribe(() => {
              this.setStatus(tourId, "end")
                .subscribe(() => {
                  console.log(`Tour end: ${tourId}`);
                  this.end$.next(tourId);
                });
            });
          });
      });
  }

  private getStatus(tourId: string): Observable<"start" | "end"> {
    return this.apiService.get("tours/{tourId}/status", {tourId})
      .pipe(catchError(() => of({status: "end"})))
      .pipe(map((res: {status: "start" | "end"}) => res.status));
  }

  setStatus(tourId: string, status: "start" | "end"): Observable<any> {
    if (!tourId) {
      of({});
    }
    return this.apiService.put("tours/{tourId}/status", {tourId}, {status})
      .pipe(tap(() => console.log(`Set tour status: ${tourId}, ${status}`)));
  }

  restart(tourId: string) {
    if (!tourId) {
      return;
    }
    this.setStatus(tourId, "start")
      .pipe(tap(() => console.log(`Tour restart: ${tourId}`)))
      .subscribe(() => this.init(tourId, 0));
  }

  private getTopModalId(): Observable<any> {
    return new Observable(s => {
      this.modalController.getTop().then((el: HTMLIonModalElement) => {
        s.next(el?.id);
        s.complete();
      }, () => {
        s.next(null);
        s.complete();
      });
    }).pipe(catchError(() => of(null)));
  }
}
