<form [formGroup]="form">
  <div class="row no-gutters align-items-center">
    @if (caption) {
      <ion-label class="col-12 col-md-6 font-weight-bold"
                 [translate]="caption">
      </ion-label>
    }
    <ion-item class="col-12 col-md-3 floating-label no-margin seriesId"
              [class.error]="form.controls['seriesId'].invalid">
      <ion-input formControlName="seriesId"
                 class="text-uppercase"
                 type="string" spellcheck="false"
                 [readonly]="readOnly"
                 (focusin)="focused.emit({dataType: 'string', cssPath: 'seriesId'})"
                 (keyup)="handleKeyUp($event)"
                 [label]="'series.seriesId' | translate"
                 labelPlacement="floating">
      </ion-input>
      <app-form-controls-errors [control]="form.controls['seriesId']"></app-form-controls-errors>
    </ion-item>

    <ion-item class="col-12 col-md-3 pl-md-1 floating-label no-margin increment"
              [class.error]="form.controls['increment'].invalid">
      <ion-input formControlName="increment"
                 type="number" spellcheck="false"
                 [readonly]="readOnly"
                 (focusin)="focused.emit({dataType: 'number', cssPath: 'increment'})"
                 [label]="'series.increment' | translate"
                 labelPlacement="floating">
      </ion-input>
      <app-form-controls-errors [control]="form.controls['increment']"></app-form-controls-errors>
    </ion-item>
  </div>
</form>
<!--{{form.value | json}}-->
<!--{{form.valid}} > {{form.controls['seriesId'].errors | json}}-->
