import {Directive, Input, ElementRef, Renderer2, OnInit} from "@angular/core";
import {IdentityService} from "core";

@Directive({
  selector: "[appIfUserCanAny]"
})
export class IfUserCanAnyDirective implements OnInit {
  @Input() claims: Array<object>;

  constructor(public el: ElementRef,
              public renderer: Renderer2,
              private identitySvc: IdentityService) {
  }

  ngOnInit() {
    if (!this.identitySvc.canAny(this.claims)) {
      this.renderer.setStyle(this.el.nativeElement, "display", "none");
    }
  }
}
