import {Directive, ElementRef, Output, Renderer2, EventEmitter, OnDestroy, Input} from "@angular/core";
import {DomUtilsService, EvSink} from "core";

@Directive({
  selector: "[appMovable]"
})
export class MovableDirective implements OnDestroy {
  @Input() set appMovable(value: boolean) {
    if (value) {
      this.mouseDownListener = this.renderer.listen(this.element.nativeElement, "mousedown", this.onMoveStart.bind(this));
    } else {
      if (this.mouseDownListener) {
        this.mouseDownListener();
      }
    }
  }

  @Input() snapResolution = 0;
  @Output() moveEnd = new EventEmitter<{x: number, y: number}>();

  private mouseDownListener: Function;
  private evSink = new EvSink();
  private start: {x: number; y: number};
  private moveOffset: {x: number, y: number};

  constructor(private renderer: Renderer2,
              private element: ElementRef,
              private domUtilsService: DomUtilsService) {
  }

  ngOnDestroy() {
    this.evSink.unsubscribe();
    if (this.mouseDownListener) {
      this.mouseDownListener();
    }
  }

  private onMoveStart(event: MouseEvent) {
    this.start = this.domUtilsService.getClientCoords(event);

    if (event.stopPropagation) {
      event.stopPropagation();
    }
    if (event.preventDefault) {
      event.preventDefault();
    }
    event.cancelBubble = true;
    event.returnValue = false;

    this.evSink.sink = this.renderer.listen(document, "mousemove", this.onMove.bind(this));
    this.evSink.sink = this.renderer.listen(document, "mouseup", this.onMoveEnd.bind(this));
  }

  private onMoveEnd(event: MouseEvent) {
    this.evSink.unsubscribe();
    if (!this.moveOffset) {
      return;
    }
    let x = this.element.nativeElement.offsetLeft + this.moveOffset.x;
    let y = this.element.nativeElement.offsetTop + this.moveOffset.y;
    if (this.snapResolution) {
      x = Math.round(x / this.snapResolution) * this.snapResolution;
      y = Math.round(y / this.snapResolution) * this.snapResolution;
      this.renderer.removeStyle(this.element.nativeElement, "transform");
      this.renderer.setStyle(this.element.nativeElement, "left", x + "px");
      this.renderer.setStyle(this.element.nativeElement, "top", y + "px");
    }
    this.start = null;
    this.moveOffset = null;
    this.moveEnd.emit({x, y});
  }

  private onMove(event: MouseEvent) {
    if (!this.start) {
      this.start = null;
      return;
    }
    const newPos = this.domUtilsService.getClientCoords(event);
    this.moveOffset = {
      x: newPos.x - this.start.x,
      y: newPos.y - this.start.y
    };
    this.renderer.setStyle(this.element.nativeElement, "transform", `translate3d(${this.moveOffset.x}px, ${this.moveOffset.y}px, 0`);
  }
}
