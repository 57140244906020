<!--#itemContent-->
<ng-template #itemContent
             let-action="action"
             let-hideText="hideText"
             let-title=title>
  @if (action.photoUrl) {
    <app-auth-img [@blink]="action.isBlinking ? 'large' : 'normal'"
                  [url]="action.photoUrl"
                  [ngClass]="action.iconCssClass"
                  [class.mr-1]="!hideText && title"
                  style="width:2rem">
      @if (action.icon) {
        <ion-icon [ngClass]="action.iconCssClass"
                  [name]="itemIcon(action)"
                  [color]="action.color">
        </ion-icon>
      }
    </app-auth-img>
  }
  @if (action.icon && !action.photoUrl) {
    <ion-icon [@blink]="action.isBlinking ? 'large' : 'normal'"
              [ngClass]="action.iconCssClass"
              [class.mr-1]="!hideText && title"
              [name]="itemIcon(action)"
              [color]="action.color">
    </ion-icon>
  }
  @if (!hideText) {
    <div>{{title | translate}}</div>
  }
  @if (action.description) {
    <div class="d-flex align-items-center color-gray">
      <div class="text-ellipsis font-size-sm" [innerText]="action.description | translateInterpolation"></div>
    </div>
  }
  @if (action.badgeValue) {
    <div class="{{action.badgeCssClass}} d-flex align-items-center justify-content-center font-size-xs font-weight-bold opacity-09 position-absolute"
         style="right: 0"
         [innerText]="action.badgeValue">
    </div>
  }
</ng-template>

<!--#breadcrumbsTemplate-->
<ng-template #breadcrumbsTemplate
             let-actions="actions">
  @if (actions?.length) {
    <ol class="d-flex align-items-center justify-content-start b-crumbs"
        [class.hide-last-separator]="!bCrumbsOptions.showLastSeparator">
      <ng-container *ngFor="let action of actions">
        @if (!isHidden(action)) {
          <li (click)="handleAction(action, $event)"
              class="d-flex align-items-center justify-content-center b-crumb"
              [class.selected]="!!itemActionValue(action)">
            <ng-container *ngTemplateOutlet="itemContent;context:{action, hideText: false, title: getTitle(action)}"></ng-container>
          </li>
        }
      </ng-container>
    </ol>
  }
</ng-template>

<!--#aHorizItemTemplate-->
<ng-template #aHorizItemTemplate
             let-action="action"
             let-hideText="hideText">
  @if (action.type === ActionBarItemType.Separator) {
    <ion-label [hidden]="isHidden(action)"
               color="gray"
               class="mx-1"
               [ngClass]="itemCssClass(action)"
               [ngStyle]="action.cssStyle">
      |
    </ion-label>
  }
  @if (!action.actions && action.type === ActionBarItemType.Label) {
    <ion-label [hidden]="isHidden(action)"
               [color]="isDisabled(action) ? 'gray' : (action.color || 'black')"
               [ngClass]="itemCssClass(action)"
               [ngStyle]="itemCssStyle(action)"
               [tourAnchor]="action.tourAnchor"
               (click)="handleAction(action, $event)">
      <ng-container *ngTemplateOutlet="itemContent;context:{action, hideText, title: getTitle(action)}"></ng-container>
    </ion-label>
  }
  @if ((!action.actions && (!action.type || action.type === ActionBarItemType.Link || action.type === ActionBarItemType.Refresh || action.type === ActionBarItemType.YearMonthPicker || action.type === ActionBarItemType.ColorPicker))
  || (action.actions && action.type !== ActionBarItemType.Segment && action.type !== ActionBarItemType.Breadcrumbs)) {
    <ion-button [hidden]="isHidden(action)"
                [disabled]="isDisabled(action)"
                [ngClass]="itemCssClass(action)"
                [ngStyle]="itemCssStyle(action)"
                [tourAnchor]="action.tourAnchor"
                (click)="handleAction(action, $event)">
      <ng-container *ngTemplateOutlet="itemContent;context:{action, hideText, title: getTitle(action)}"></ng-container>
    </ion-button>
  }
  @if (!action.actions && action.type === ActionBarItemType.FilePicker) {
    <ion-button [hidden]="isHidden(action)"
                [disabled]="isDisabled(action)"
                [ngClass]="itemCssClass(action)"
                [ngStyle]="itemCssStyle(action)"
                appFilePicker
                [accept]="action.acceptedMimeTypes"
                [isMultiple]="action.multiple"
                [tourAnchor]="action.tourAnchor"
                (picked)="handleAction(action, $event)">
      <ng-container *ngTemplateOutlet="itemContent;context:{action, hideText, title: getTitle(action)}"></ng-container>
    </ion-button>
  }
  @if (action.type === ActionBarItemType.Bool) {
    <ion-item [hidden]="isHidden(action)"
              [tourAnchor]="action.tourAnchor"
              class="input no-border">
      <ion-checkbox [checked]="action.actionValueTexts?.length"
                    (ionChange)="handleIonCheckboxChange(action, $event)"
                    justify="start"
                    labelPlacement="end">
        <span [translate]="action.title"></span>
      </ion-checkbox>
    </ion-item>
  }
  @if (action.type === ActionBarItemType.Segment) {
    <ion-segment [hidden]="isHidden(action)"
                 [value]="itemActionValue(action)"
                 [ngClass]="itemCssClass(action)"
                 [ngStyle]="itemCssStyle(action)"
                 [tourAnchor]="action.tourAnchor"
                 (ionChange)="!isDisabled(action) && handleIonSegmentChange(action, $event)">
      <ng-container *ngFor="let sub of action.actions">
        @if (!isHidden(sub)) {
          <ion-segment-button [value]="itemActionValue(sub)"
                              layout="icon-start">
            @if (sub.icon) {
              <ion-icon [name]="itemIcon(sub)"></ion-icon>
            }
            <ion-label [translate]="sub.title"></ion-label>
          </ion-segment-button>
        }
      </ng-container>
    </ion-segment>
  }
  @if (action.type === ActionBarItemType.TextBox) {
    <div [hidden]="isHidden(action)">
      <ion-item class="input"
                [tourAnchor]="action.tourAnchor">
        <ion-input [placeholder]="action.title | translate"
                   [value]="itemActionValue(action)"
                   (ionChange)="handleIonInputChange(action, $event)">
        </ion-input>
      </ion-item>
    </div>
  }
  @if (!isHidden(action) && action.type === ActionBarItemType.Breadcrumbs && action?.actions) {
    <ng-container
      *ngTemplateOutlet="breadcrumbsTemplate;context:{actions: action.actions}">
    </ng-container>
  }
  @if (action.type === ActionBarItemType.Search && !action.actions) {
    <app-search-box [hidden]="isHidden(action)"
                    [placeholder]="action.title | translate"
                    [value]="itemActionValue(action)"
                    [hasBorder]="true"
                    [color]="action.color"
                    [tourAnchor]="action.tourAnchor"
                    (submit)="handleAction(action, $event)">
    </app-search-box>
  }
  @if (action.type === ActionBarItemType.SearchAnimate && !action.actions) {
    <app-search-box [color]="action.color"
                    [hidden]="isHidden(action)"
                    [placeholder]="action.title | translate"
                    [value]="itemActionValue(action)"
                    [hasBorder]="true"
                    [isAnimated]="true"
                    [iconCssClass]="action.iconCssClass"
                    [cssClass]="itemCssClass(action)"
                    [ngStyle]="itemCssStyle(action)"
                    [clearIconName]="'close-outline'"
                    [autofocus]="10"
                    [tourAnchor]="action.tourAnchor"
                    (submit)="handleAction(action, $event)">
    </app-search-box>
  }
  @if (action.type === ActionBarItemType.NgContent) {
    <div>
      <ng-content select="[action]"></ng-content>
    </div>
  }
</ng-template>

<!--#aVertTemplate-->
<ng-template #aVertTemplate
             let-action="action"
             let-isExpanded="isExpanded"
             let-hideLabel="hideLabel"
             let-indent="indent">
  @if (action.type === ActionBarItemType.Separator) {
    <div class="dropdown-divider bg-transparent"></div>
  }
  @if (action.type === ActionBarItemType.NgContent) {
    <div>
      <ng-content select="[v-action]"></ng-content>
    </div>
  }
  @if (action.type !== ActionBarItemType.Separator && action.type !== ActionBarItemType.NgContent) {
    <ion-item [hidden]="isHidden(action)"
              [button]="action.type!==ActionBarItemType.Label"
              [detail]="false"
              [color]="action.color || color || 'white'"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}"
              [ngClass]="{'overflow-visible':!!action.badgeValue}"
              class="height-md"
              (click)="(!isExpanded || (isExpanded && !action.actions)) && handleAction(action, $event)"
              [tourAnchor]="action.tourAnchor">
      @if (action.photoUrl) {
        <app-auth-img [url]="action.photoUrl"
                      [style.padding-left.px]="indent * 10"
                      class="d-flex radius-circle align-self-center">
          @if (action.icon) {
            <ion-icon [ngClass]="action.iconCssClass"
                      [name]="itemIcon(action)">
            </ion-icon>
          }
        </app-auth-img>
      }
      @if (action.icon && !action.photoUrl) {
        <ion-icon [ngClass]="action.iconCssClass"
                  [style.padding-left.px]="indent * 10"
                  class="align-self-center"
                  [name]="itemIcon(action)">
        </ion-icon>
      }
      @if (!hideLabel) {
        <ion-label class="align-self-center"
                   [class.mr-4]="action.additionalAction"
                   [class.pr-6]="action.additionalAction"
                   [ngClass]="itemCssClass(action)"
                   [ngStyle]="itemCssStyle(action)">
          @if (action.title) {
            <div class="text-ellipsis"
                 [innerText]="action.title | translateInterpolation">
            </div>
          }
          @if (action.description) {
            <div class="text-ellipsis font-size-sm"
                 [innerText]="action.description | translateInterpolation">
            </div>
          }
        </ion-label>
      }
      @if (action.badgeValue) {
        <div class="position-absolute badge badge-{{action.badgeColor}} {{action.badgeCssClass}}"
             style="right: 12px; top: 12px"
             [innerText]="action.badgeValue">
        </div>
      }
      @if (action.additionalAction) {
        <ion-button [ngClass]="action.additionalAction?.cssClass"
                    class="position-absolute horiz-right d-flex align-items-center justify-content-center cursor-pointer additional-action"
                    (click)="handleAction(action.additionalAction, $event)">
          @if (action.additionalAction?.icon) {
            <ion-icon [ngClass]="action.additionalAction?.iconCssClass"
                      class="m-0"
                      [name]="itemIcon(action.additionalAction)">
            </ion-icon>
          }
        </ion-button>
      }
    </ion-item>
  }
  @if (isExpanded && action.type !== ActionBarItemType.NgContent) {
    <ng-container *ngFor="let sa of action.actions">
      <ng-container
        *ngTemplateOutlet="aVertTemplate;context:{action: sa, isExpanded: true, indent: indent + 1}">
      </ng-container>
    </ng-container>
  }
</ng-template>

<!--#chipContent-->
<ng-template #chipContent
             let-action="action"
             let-index="index"
             let-value="value">
  @if (action.actionValueTexts[index]) {
    <div class="d-inline-flex align-items-center justify-content-between badge badge-outline badge-primary font-size-sm mr-1 p-0">
      <div class="pl-1" [translate]="action.actionValueTexts[index]"></div>
      <ion-icon name="close-outline"
                class="d-inline-block p-1 cursor-pointer"
                (click)="removeValueAndHandleAction(action, $event, value)">
      </ion-icon>
    </div>
  }
</ng-template>

<!--#aChipTemplate-->
<ng-template #aChipTemplate
             let-action="action">
  @if (action.actionValueTexts?.length && !isHidden(action)) {
    @if (action.type === ActionBarItemType.LinkMultiselect) {
      <div class="d-inline-block px-1">
        <div class="font-size-sm mb-1" [translate]="action.title"></div>
        <ng-container *ngFor="let value of action.actionValue; let index=index">
          <ng-container *ngTemplateOutlet="chipContent;context:{action,index,value}">
          </ng-container>
        </ng-container>
      </div>
    }
    @if (action.type === ActionBarItemType.YearMonthPicker) {
      <div class="d-inline-block px-1">
        <div class="font-size-sm mb-1" [translate]="action.title"></div>
        <ng-container *ngTemplateOutlet="chipContent;context:{action,index:0,value:{dateFrom: null, dateTo: null}}">
        </ng-container>
      </div>
    }
  }
</ng-template>

<!--Toolbar-->
@switch (type) {
  @case (ActionBarType.Toolbar) {
    <ion-toolbar [color]="color"
                 [ngClass]="cssClass"
                 class="nav-bar">
      @if (actionsStart?.length) {
        <ion-buttons slot="start">
          <ng-container *ngFor="let action of actionsStart">
            <ng-container *ngTemplateOutlet="aHorizItemTemplate;context:{action}"></ng-container>
          </ng-container>
        </ion-buttons>
      }
      @if (actionsMain?.length) {
        <ion-buttons>
          <ng-container *ngFor="let action of actionsMain">
            <ng-container *ngTemplateOutlet="aHorizItemTemplate;context:{action}"></ng-container>
          </ng-container>
        </ion-buttons>
      }
      @if (actionsEnd?.length) {
        <ion-buttons slot="end">
          <ng-container *ngFor="let action of actionsEnd">
            <ng-container *ngTemplateOutlet="aHorizItemTemplate;context:{action}"></ng-container>
          </ng-container>
        </ion-buttons>
      }
    </ion-toolbar>
    @if (hasChips) {
      <div class="px-2 pb-3 bg-white">
        <ng-container *ngFor="let action of actions">
          <ng-container *ngTemplateOutlet="aChipTemplate;context:{action}"></ng-container>
        </ng-container>
      </div>
    }
  }

  <!--Buttons-->
  @case (ActionBarType.Buttons) {
    <div class="d-flex align-items-center">
      <ng-container *ngFor="let action of actions">
        <ng-container *ngTemplateOutlet="aHorizItemTemplate;context:{action: action}"></ng-container>
      </ng-container>
    </div>
  }

  <!--Iconbar-->
  @case (ActionBarType.IconBar) {
    <ion-toolbar class="icon-bar d-flex align-items-start w-100 h-100"
                 [color]="color">
      <div class="row no-gutters">
        <ion-button *ngFor="let action of actions"
                    [hidden]="isHidden(action)"
                    [color]="action.color || color || 'white'"
                    [disabled]="isDisabled(action)"
                    (click)="handleAction(action, $event)"
                    [ngClass]="itemCssClass(action)"
                    [ngStyle]="itemCssStyle(action)"
                    class="col d-flex align-items-center sqr-md">
          @if (action.photoUrl) {
            <app-auth-img [url]="action.photoUrl"
                          class="d-flex radius-circle align-self-center sqr-sm">
              @if (action.icon) {
                <ion-icon class="sqr-sm"
                          [ngClass]="action.iconCssClass"
                          [name]="itemIcon(action)">
                </ion-icon>
              }
            </app-auth-img>
          }
          @if (action.icon && !action.photoUrl) {
            <ion-icon [ngClass]="action.iconCssClass"
                      class="align-self-center sqr-sm"
                      [name]="itemIcon(action)">
            </ion-icon>
          }
          @if (action.badgeValue) {
            <div class="badge badge-{{action.badgeColor}} {{action.badgeCssClass}} font-size-sm opacity-09 position-absolute"
                 [style.margin]="'0 0 20px 20px'"
                 [innerText]="action.badgeValue">
            </div>
          }
        </ion-button>
      </div>
    </ion-toolbar>
  }

  <!--Drop-->
  @case (ActionBarType.Drop) {
    @if (actions?.length === 1 && actions[0].type === ActionBarItemType.FilePicker) {
      <ion-fab-button [hidden]="isHidden(actions[0])"
                      [color]="actions[0].color"
                      [ngClass]="actions[0].cssClass"
                      [class.color-gray]="isDisabled(actions[0])"
                      appFilePicker
                      [accept]="actions[0].acceptedMimeTypes"
                      [isMultiple]="actions[0].multiple"
                      (picked)="handleAction(actions[0], $event)">
        <ion-icon [name]="actions[0]?.icon || _dropOptions.fabIcon"
                  [ngClass]="actions[0]?.iconCssClass">
        </ion-icon>
      </ion-fab-button>
    }
    @if (actions?.length >= _dropOptions.collapseOnCount) {
      @if (ngContentRef.children.length === 0) {
        <ion-fab-button (click)="openActions(actions, $event)">
          <ion-icon [name]="_dropOptions.fabIcon || _dropOptions.fabIcon"
                    [ngClass]="actions[0]?.iconCssClass">
          </ion-icon>
        </ion-fab-button>
      }
      <div #ngContentRef (click)="openActions(actions, $event)">
        <ng-content></ng-content>
      </div>
    } @else {
      <div class="d-flex align-items-center flex-row-reverse">
        <ion-fab-button *ngFor="let action of actions; let isFirst = first"
                        [class.mr-1]="!isFirst"
                        [hidden]="isHidden(action)"
                        [color]="action.color"
                        [ngClass]="itemCssClass(action)"
                        [ngStyle]="itemCssStyle(action)"
                        [disabled]="isDisabled(action)"
                        (click)="handleAction(action, $event)">
          <ion-icon [name]="action.icon || _dropOptions.fabIcon"
                    [ngClass]="actions[0]?.iconCssClass">
          </ion-icon>
        </ion-fab-button>
      </div>
    }
  }

  <!--Sidemenu-->
  @case (ActionBarType.SideMenu) {
    <ion-menu [side]="sideMenuOptions?.side || 'start'" [contentId]="sideMenuOptions?.contentId || 'main'"
              [menuId]="actionBarId">
      <ion-header>
        <ng-content select="[start]"></ng-content>
        <ng-container *ngFor="let action of actionsStart">
          <ng-container *ngTemplateOutlet="aVertTemplate;context:{action: action}"></ng-container>
        </ng-container>
      </ion-header>

      <ion-content color="white">
        <ng-container *ngFor="let action of actionsMain">
          <ng-container *ngTemplateOutlet="aVertTemplate;context:{action: action}"></ng-container>
        </ng-container>
      </ion-content>

      <ion-footer>
        <ng-content select="[end]"></ng-content>
        <ng-container *ngFor="let action of actionsEnd">
          <ng-container *ngTemplateOutlet="aVertTemplate;context:{action: action}"></ng-container>
        </ng-container>
      </ion-footer>
    </ion-menu>
  }

  <!--Sidebar-->
  @case (ActionBarType.SideBar) {
    <ng-sidebar-container [style.opacity]="sideBarOptions?.isClosed === null ? 0 : 1"
                          class="d-none d-md-block">
      <ng-sidebar
        [opened]="!sideBarOptions?.isClosed"
        mode="push"
        [dock]="true"
        dockedSize="4rem"
        [keyClose]="false"
        [trapFocus]="false"
        [autoFocus]="false"
        position="left"
        sidebarClass="{{cssClass}} shadow-sm overflow-x-hidden overflow-y-auto"
        [class.sidenav--closed]="sideBarOptions?.isClosed">

        <div class="d-flex flex-row h-100">
          <div class="w-100">
            <ng-container *ngFor="let action of actions">
              <ng-container
                *ngTemplateOutlet="aVertTemplate;context:{action: action, isExpanded: sideBarOptions?.isExpanded, hideLabel: sideBarOptions?.isClosed, indent: 0}">
              </ng-container>
            </ng-container>
            <div class="safe-padding"></div>
          </div>
        </div>

        <div class="toggle"
             style="z-index: 1"
             (click)="toggleSideBar.emit(!sideBarOptions?.isClosed)">
          <ion-icon size="sqr-sm"
                    class="m-0"
                    [name]="sideBarOptions?.isClosed ? 'chevron-forward-outline' : 'chevron-back-outline'">
          </ion-icon>
        </div>
      </ng-sidebar>
    </ng-sidebar-container>
  }

  <!--Breadcrumbs-->
  @case (ActionBarType.Breadcrumbs) {
    <ng-container
      *ngTemplateOutlet="breadcrumbsTemplate;context:{actions}">
    </ng-container>
  }

  <!--Segment-->
  @case (ActionBarType.Segment) {
    <ion-segment [ngClass]="cssClass">
      <ng-container *ngFor="let action of actions">
        @if (!isHidden(action)) {
          <ion-segment-button (click)="handleAction(action, $event)"
                              [translate]="action.title">
          </ion-segment-button>
        }
      </ng-container>
    </ion-segment>
  }
}
