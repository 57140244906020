<div [style.visibility]="ratingsCount ? 'visible' : 'hidden'"
     class="line my-1">
  <div class="star-rating">
    <div class="star-rating-inner" [style.width.%]="(ratingScore || 0) * 100/5"></div>
  </div>
  <div class="line font-size-sm">
    <div [innerText]="ratingScore"></div>
    <div class="color-gray" [innerText]="'(' + ratingsCount + ')'"></div>
  </div>
</div>
