import {Injectable} from "@angular/core";
import {StorageMap} from "@ngx-pwa/local-storage";
import {IndexedDbStorage} from "./indexeddb-storage";

@Injectable({providedIn: "root"})
export class BrowserStorageService {
  volatile: IndexedDbStorage;
  global: IndexedDbStorage;
  idb: IndexedDbStorage;

  constructor(private storage: StorageMap) {
    this.volatile = new IndexedDbStorage("volatile", this.storage);
    this.global = new IndexedDbStorage("global", this.storage);
    this.idb = new IndexedDbStorage("idb", this.storage);
  }
}
