import {TableCell} from "@tiptap/extension-table-cell";
import {ElementRef} from "@angular/core";

export function getTableCellExt(el: ElementRef) {
  return TableCell.extend({
    addAttributes() {
      return {
        ...this.parent?.(),
        // colwidthpr: {
        //   default: null,
        //   parseHTML: el => el.getAttribute("colwidthpr"),
        //   renderHTML: attrs => {
        //     const widthPx = attrs.colwidth && attrs.colwidth[0];
        //     const widthPr = widthPx && (widthPx * 100 / el.nativeElement.clientWidth).toFixed(0);
        //     return {
        //       colwidthpr: widthPr
        //     };
        //   }
        // },
        bgcolor: {
          default: null,
          parseHTML: el => el.getAttribute("bgcolor"),
          renderHTML: attrs => attrs.bgcolor ? {bgcolor: attrs.bgcolor} : {}
        }
      };
    }
  });
}
