import {Injector} from "@angular/core";
import {mergeAttributes, Node} from "@tiptap/core";
import {AngularNodeViewRenderer} from "ngx-tiptap";
import {TipTapNodeFieldComponent} from "./tiptap-node-field.component";
import {FieldDataType} from "dynamic-forms/dynamic-forms.model";

const FieldNodeExtension = (injector: Injector): Node => {
  return Node.create({
    name: "field",
    group: "inline",
    inline: true,
    atom: true,
    selectable: true,
    draggable: true,
    addAttributes() {
      return {
        fieldId: {default: ""},
        fieldName: {default: ""},
        fieldDataType: {default: FieldDataType.String},
        fieldFormatDate: {default: "dd.MM.yyyy"},
        fieldFormatNumericDecimals: {default: 2},
        fieldFormatNumericThousandsSeparator: {default: ","},
        fieldFormatBoolean: {default: ""},
        fieldWidth: {default: ""},
        fieldHeight: {default: ""},

        fieldTransformCase: {default: ""},
        fieldTransformTrim: {default: ""}
      };
    },
    parseHTML() {
      return [{tag: "app-tiptap-node-field"}];
    },
    renderHTML({HTMLAttributes}) {
      return ["app-tiptap-node-field", mergeAttributes(HTMLAttributes)];
    },
    addNodeView() {
      return AngularNodeViewRenderer(TipTapNodeFieldComponent, {injector});
    },
  });
};

export default FieldNodeExtension;
