<div class="d-flex align-items-center justify-content-between">
  <ion-item class="input width-xl cursor-pointer"
            [class.error]="formControl.invalid"
            (click)="yearClick()">
    @if (!isEditState) {
      <ion-input [formControl]="formControl"
                 readonly>
      </ion-input>
    } @else {
      <ion-input #yearEditInput
                 type="number"
                 autocomplete="off"
                 [min]="options?.yearMin || 0"
                 [max]="options?.yearMax || 9999"
                 [maxlength]="4"
                 [formControl]="formControl"
                 (ionBlur)="yearBlur()">
      </ion-input>
    }
  </ion-item>
  <div>
    @if (!options.yearMin || formControl.value > options.yearMin) {
      <ion-button color="light"
                  class="sqr-md mr-1"
                  (click)="yearBack()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    }
    @if (!options.yearMax || formControl.value < options.yearMax) {
      <ion-button color="light"
                  class="sqr-md"
                  (click)="yearForward()">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-button>
    }
  </div>
</div>
