<form [formGroup]="form" class="condensed">
  <div class="row no-gutters align-items-center">
    @if (caption) {
      <ion-label class="col-12 col-md-4 font-weight-bold mb-4"
                 [translate]="caption">
      </ion-label>
    }
    <ion-item class="col-12 col-md-4 floating-label pl-md-1 cui"
              [class.error]="form.controls['cui'].invalid">
      @if (form.value['isVat']) {
        <ion-text class="color-gray mr-1">RO</ion-text>
      }
      <ion-input formControlName="cui"
                 [readonly]="readOnly"
                 (focusin)="!readOnly && focused.emit({dataType: 'string', cssPath: 'cui'})"
                 [label]="'fiscalInfo.cui' | translate"
                 labelPlacement="floating"
                 appTypeAhead
                 [taIsActive]="true"
                 [dataLoader$]="typeAheadLoader$"
                 [taAllowNonListed]="true"
                 (taSelected)="handleTypeAheadSelected($event)">
      </ion-input>
      <app-form-controls-errors [control]="form.controls['cui']"></app-form-controls-errors>
    </ion-item>
    <ion-item class="col-12 col-md-4 floating-label pl-md-1 isVat input">
      <ion-checkbox formControlName="isVat"
                    justify="start"
                    labelPlacement="end">
        <span translate="workspace.isVatScope"></span>
      </ion-checkbox>
    </ion-item>
  </div>
  <div class="row no-gutters">
    <ion-item class="col-12 floating-label name"
              [class.error]="form.controls['name'].invalid">
      <ion-input formControlName="name"
                 [readonly]="readOnly"
                 (focusin)="!readOnly && focused.emit({dataType: 'string', cssPath: 'name'})"
                 [label]="'fiscalInfo.name' | translate"
                 labelPlacement="floating">
      </ion-input>
      <app-form-controls-errors [control]="form.controls['name']"></app-form-controls-errors>
    </ion-item>
  </div>
  <div class="row no-gutters">
    <ion-item class="col-12 col-md-4 floating-label j"
              [class.error]="form.controls['j'].invalid">
      <ion-input formControlName="j"
                 [readonly]="readOnly"
                 (focusin)="!readOnly && focused.emit({dataType: 'string', cssPath:'j'})"
                 [label]="'fiscalInfo.j' | translate"
                 labelPlacement="floating">
      </ion-input>
      <app-form-controls-errors [control]="form.controls['j']"></app-form-controls-errors>
    </ion-item>
    <ion-item class="col-12 col-md-8 pl-md-1 floating-label caen">
      <ion-input formControlName="caen"
                 [readonly]="readOnly"
                 (focusin)="!readOnly && focused.emit({dataType: 'string', cssPath:'caen'})"
                 [label]="'fiscalInfo.caen' | translate"
                 labelPlacement="floating">
      </ion-input>
      <app-form-controls-errors [control]="form.controls['caen']"></app-form-controls-errors>
    </ion-item>
  </div>
  <div class="row no-gutters">
    <ion-item class="col-12 col-md-4 floating-label contactName">
      <ion-input formControlName="contactName"
                 [readonly]="readOnly"
                 (focusin)="!readOnly && focused.emit({dataType: 'string', cssPath:'contactName'})"
                 [label]="'fiscalInfo.contactName' | translate"
                 labelPlacement="floating">
      </ion-input>
      <app-form-controls-errors [control]="form.controls['contactName']"></app-form-controls-errors>
    </ion-item>
    <ion-item class="col-12 col-md-4 pl-md-1 floating-label contactPhone">
      <ion-input formControlName="contactPhone"
                 [readonly]="readOnly"
                 (focusin)="!readOnly && focused.emit({dataType: 'string', cssPath:'contactPhone'})"
                 [label]="'fiscalInfo.contactPhone' | translate"
                 labelPlacement="floating">
      </ion-input>
      <app-form-controls-errors [control]="form.controls['contactPhone']"></app-form-controls-errors>
    </ion-item>
    <ion-item class="col-12 col-md-4 pl-md-1  floating-label contactPhone">
      <ion-input formControlName="contactEmail"
                 [readonly]="readOnly"
                 (focusin)="!readOnly && focused.emit({dataType: 'string', cssPath:'contactEmail'})"
                 [label]="'fiscalInfo.contactEmail' | translate"
                 labelPlacement="floating">
      </ion-input>
      <app-form-controls-errors [control]="form.controls['contactEmail']"></app-form-controls-errors>
    </ion-item>
  </div>
  <app-address [formControl]="addressFormControl"
               [readonly]="readOnly">
  </app-address>
</form>
