export interface Address {
  country?: string;
  region?: string;
  city?: string;
  zipCode?: string;
  line1?: string;
  phoneNumber?: string;
  email?: string;
  web?: string;
}

export const EmptyAddress: Address = {
  country: "RO",
  region: "",
  city: "",
  zipCode: "",
  line1: "",
  phoneNumber: "",
  email: "",
  web: ""
};

export const regions: Array<{id: string, name: string}> = [
  {id: "AB", name: "Alba"},
  {id: "AR", name: "Arad"},
  {id: "AG", name: "Argeș"},
  {id: "BC", name: "Bacău"},
  {id: "BH", name: "Bihor"},
  {id: "BN", name: "Bistrița-Năsăud"},
  {id: "BT", name: "Botoșani"},
  {id: "BV", name: "Brașov"},
  {id: "BR", name: "Brăila"},
  {id: "B", name: "București"},
  {id: "BZ", name: "Buzău"},
  {id: "CS", name: "Caraș-Severin"},
  {id: "CL", name: "Călărași"},
  {id: "CJ", name: "Cluj"},
  {id: "CT", name: "Constanța"},
  {id: "CV", name: "Covasna"},
  {id: "DB", name: "Dâmbovița"},
  {id: "DJ", name: "Dolj"},
  {id: "GL", name: "Galați"},
  {id: "GR", name: "Giurgiu"},
  {id: "GJ", name: "Gorj"},
  {id: "HR", name: "Harghita"},
  {id: "HD", name: "Hunedoara"},
  {id: "IL", name: "Ialomița"},
  {id: "IS", name: "Iași"},
  {id: "IF", name: "Ilfov"},
  {id: "MM", name: "Maramureș"},
  {id: "MH", name: "Mehedinți"},
  {id: "MS", name: "Mureș"},
  {id: "NT", name: "Neamț"},
  {id: "OT", name: "Olt"},
  {id: "PH", name: "Prahova"},
  {id: "SM", name: "Satu Mare"},
  {id: "SJ", name: "Sălaj"},
  {id: "SB", name: "Sibiu"},
  {id: "SV", name: "Suceava"},
  {id: "TR", name: "Teleorman"},
  {id: "TM", name: "Timiș"},
  {id: "TL", name: "Tulcea"},
  {id: "VS", name: "Vaslui"},
  {id: "VL", name: "Vâlcea"},
  {id: "VR", name: "Vrancea"}
];
