import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {NavService} from "../nav/nav.service";

@Injectable({providedIn: "root"})
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private nav: NavService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(tap((err: any) => {
        if (err.status === 401) {
          this.nav.go("/auth/logout");
        }
      }));
  }
}
