import {concatMap, Observable, Subscriber, throwError} from "rxjs";
import {StorageMap} from "@ngx-pwa/local-storage";
import {filter, map, mergeMap} from "rxjs/operators";

export class IndexedDbStorage {
  constructor(private collection: string,
              private storage: StorageMap) {
  }

  private getCollectionKey(key: string): string {
    return `${this.collection}§${key}`;
  }

  store(key: string, value: any): Observable<any> {
    const collectionKey = this.getCollectionKey(key);
    return this.storage.set(collectionKey, value);
  }

  get(key: string): Observable<any | Array<any>> {
    const collectionKey = this.getCollectionKey(key);
    return this.storage.get(collectionKey);
  }

  getAsArray<T>(key: string): Observable<Array<T>> {
    const collectionKey = this.getCollectionKey(key);
    return new Observable((o: Subscriber<Array<T>>) => {
      this.storage.get(collectionKey)
        .subscribe((array: any | Array<T>) => {
          if (!array) {
            array = [];
          }
          if (!(array instanceof Array)) {
            array = [array];
          }
          o.next(array);
          o.complete();
        }, err => {
          o.error(err);
          o.complete();
        });
    });
  }

  add<T>(key: string, value: T): Observable<T> {
    return this.getAsArray<T>(key)
      .pipe(concatMap((items: Array<T>) => {
        items.push(value);
        return this.store(key, items);
      }))
      .pipe(map(() => value));
  }

  remove<T>(key: string, predicate: (item: T) => boolean): Observable<null> {
    return this.getAsArray(key)
      .pipe(concatMap((items: Array<T>) => {
        const idx = items.findIndex(predicate);
        if (idx !== -1) {
          items.splice(idx, 1);
        }
        return this.store(key, items);
      })).pipe(map(() => null));
  }

  update<T>(key: string, predicate: (item: T) => boolean, newValue: T): Observable<T> {
    return this.getAsArray(key)
      .pipe(concatMap((items: Array<T>) => {
        const idx = items.findIndex(predicate);
        if (idx === -1) {
          throwError(() => new Error("Item not found"));
        }
        items[idx] = newValue;
        return this.store(key, items);
      })).pipe(map(() => newValue));
  }

  count(key: string): Observable<number> {
    return this.getAsArray(key)
      .pipe(map((items: Array<any>) => items?.length));
  }

  clear(key?: string): Observable<any> {
    return new Observable(x => {
      if (!key) {
        this.storage.keys().pipe(
          filter((k: string) => k.startsWith(`${this.collection}§`)),
          mergeMap((k: string) => this.storage.delete(k))
        ).subscribe({
          complete: () => {
            x.next();
            x.complete();
          }
        });
      } else {
        const collectionKey = this.getCollectionKey(key);
        this.storage.delete(collectionKey)
          .subscribe(() => {
            x.next();
            x.complete();
          }, err => {
            x.error(err);
            x.complete();
          });
      }
    });
  }

  observe(key: string): Observable<any> {
    const collectionKey = this.getCollectionKey(key);
    return this.storage.watch(collectionKey);
  }

  check(): Observable<{isSupported: boolean, quota?: number, usage?: number}> {
    return new Observable(x => {
      this.storage.set("test", "test")
        .subscribe(() => {
          this.storage.delete("test");
          if (navigator.storage && navigator.storage.estimate) {
            navigator.storage.estimate().then(quota => {
              x.next({isSupported: true, quota: quota.quota, usage: quota.usage});
            }, () => {
              x.next({isSupported: true});
            });
          } else {
            x.next({isSupported: true});
          }
        }, () => {
          x.next({isSupported: false});
        });
    });
  }
}
