import {AbstractControl, ValidatorFn} from "@angular/forms";

export function fieldsMatchValidator(compareWithFieldName: string, errorMessage: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const otherValue = control.parent && control.parent.controls && control.parent.controls[compareWithFieldName] && control.parent.controls[compareWithFieldName].value;
    return otherValue !== control.value ? {passwordsMismatch: {message: errorMessage}} : null;
  };
}
export function equalsValidator(value: string, errorMessage: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return value !== control.value ? {equalsValidator: {message: errorMessage}} : null;
  };
}
