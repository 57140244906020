import {Component, EventEmitter, forwardRef, Input, OnDestroy, Output} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors, Validator,
  Validators
} from "@angular/forms";
import {ArrayService, FiscalInfoApiData, FiscalInfoApiService, RxJsExtService, SubSink} from "core";
import {Address, EmptyAddress} from "shared";
import {map} from "rxjs/operators";
import {Observable, of} from "rxjs";

export interface FiscalInfo {
  isVat: boolean;
  cui: string;
  j: string;
  name: string;
  caen: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  country: string;
  region: string;
  city: string;
  zipCode: string;
  line1: string;
  phoneNumber: string;
  email: string;
  web: string;
}

@Component({
  selector: "app-fiscal-info",
  templateUrl: "./fiscal-info.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FiscalInfoComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => FiscalInfoComponent), multi: true}]
})
export class FiscalInfoComponent implements ControlValueAccessor, Validator, OnDestroy {
  @Input() caption: string;
  @Input() readOnly = false;
  @Output() focused = new EventEmitter<{dataType: "string" | "number" | "date", cssPath: string}>();

  form: FormGroup;
  onTouched: () => void;
  private sub = new SubSink();
  addressFormControl: FormControl;
  private isInitialized = false;

  constructor(private arrayService: ArrayService,
              private fiscalApiService: FiscalInfoApiService) {
    this.form = new FormGroup({
      "isVat": new FormControl(false),
      "cui": new FormControl("", [Validators.required]),
      "j": new FormControl(""),
      "name": new FormControl("", [Validators.required]),
      "caen": new FormControl(""),
      "contactName": new FormControl(""),
      "contactPhone": new FormControl(""),
      "contactEmail": new FormControl(""),
      "country": new FormControl("RO", [Validators.required]),
      "region": new FormControl("", [Validators.required]),
      "city": new FormControl("", [Validators.required]),
      "zipCode": new FormControl(""),
      "line1": new FormControl("", [Validators.required]),
      "phoneNumber": new FormControl(""),
      "email": new FormControl(""),
      "web": new FormControl("")
    });
    this.addressFormControl = new FormControl();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  writeValue(value: FiscalInfo) {
    this.isInitialized = false;
    this.sub.unsubscribe(true);
    this.sub.sink = this.addressFormControl.valueChanges
      .subscribe((address: Address) => {
        this.form.patchValue(Object.assign({}, address, {country: EmptyAddress.country}));
      });

    const empty = Object.assign({
      isVat: false,
      cui: "",
      j: "",
      name: "",
      caen: "",
      contactName: "",
      contactPhone: "",
      contactEmail: ""
    }, EmptyAddress) as FiscalInfo;
    this.form.setValue(Object.assign({}, this.arrayService.smartAssign(empty, value), {country: EmptyAddress.country}));
    this.addressFormControl.setValue(Object.assign({}, this.arrayService.smartAssign(EmptyAddress, value)));
    setTimeout(() => this.isInitialized = true, 100);
  }

  registerOnChange(fn: (value: FiscalInfo) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : {invalid: {message: "FiscalInfo is invalid"}};
  }

  private refreshFiscalInfo() {
    const cui = this.form.value["cui"];
    if (!cui || this.readOnly) {
      return;
    }
    this.fiscalApiService.readFiscalData(cui)
      .subscribe((fiscalData: FiscalInfoApiData) => {
        this.patchForm(Object.assign({cui}, fiscalData));
      }, (err: HttpErrorResponse) => {
        console.log(err);
        this.form.patchValue({address: EmptyAddress});
      });
  }

  get typeAheadLoader$(): (search: string) => Observable<Array<{caption: string, value: any}>> {
    return (search: string) => this.fiscalApiService.searchFiscalData(search)
      .pipe(map((rows: Array<FiscalInfoApiData>) => {
        return rows.map(row => {
          return {
            caption: `${row?.cui} - ${row?.name}`,
            value: row
          };
        }).filter(x => !!x.value);
      }));
  }

  handleTypeAheadSelected($event: {input: string, value: FiscalInfoApiData, isValid: boolean}) {
    if (!this.isInitialized) {
      return;
    }
    if ($event.value) {
      const fiscalData = $event.value;
      this.patchForm(fiscalData);
    } else if ($event.input) {
      this.form.patchValue({cui: $event.input});
      this.refreshFiscalInfo();
    }
  }

  private patchForm(fiscalData: FiscalInfoApiData) {
    this.form.patchValue(Object.assign({}, EmptyAddress, {
      isVat: fiscalData?.isVat || false,
      cui: fiscalData?.cui || "",
      name: fiscalData?.name || "",
      j: fiscalData?.companyInfo?.j || "",
      caen: fiscalData?.companyInfo?.caen || "",
      contactName: fiscalData?.companyInfo?.legalRepresentative?.name,
      contactPhone: fiscalData?.companyInfo?.legalRepresentative?.phone,
      contactEmail: fiscalData?.companyInfo?.legalRepresentative?.email
    }));
    this.addressFormControl.patchValue(Object.assign({}, EmptyAddress, {
      region: fiscalData?.companyInfo?.region || "",
      city: fiscalData?.companyInfo?.city || "",
      line1: fiscalData?.companyInfo?.address || "",
      zipCode: fiscalData?.companyInfo?.zipCode || "",
      phoneNumber: fiscalData?.companyInfo?.phone || ""
    }));
  }
}
