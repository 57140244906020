export enum LoadStates {
  Init = "init",
  Reload = "reload",
  ScrollForMore = "scroll-for-more",
  Working = "working",
  Ok = "ok",
  Error = "error"
}

export class LoadState {
  private _prevState: LoadStates;
  private _state: LoadStates;
  private _error: any;

  constructor() {
    this._state = null;
    this._error = null;
  }

  set state(state: LoadStates) {
    this._prevState = this._state;
    this._state = state;
  }

  get state(): LoadStates {
    return this._state;
  }

  // get prevState(): LoadState {
  //   const ret = new LoadState();
  //   ret.state = this._prevState;
  //   ret.setError(this._error);
  //   return ret;
  // }

  setInit() {
    this.state = LoadStates.Init;
  }

  setReload() {
    this.state = LoadStates.Reload;
  }

  setWorking() {
    this.state = LoadStates.Working;
  }

  setOk() {
    this.state = LoadStates.Ok;
  }

  setError(error: any) {
    this._error = error;
    if (error) {
      this.state = LoadStates.Error;
    }
  }

  setLoading(condition: boolean) {
    this.state = condition ? LoadStates.Reload : LoadStates.ScrollForMore;
  }

  get error(): any {
    return this._error;
  }

  get isNull(): boolean {
    return !this.state;
  }

  get isWorking(): boolean {
    return this.state === LoadStates.Init || this.state === LoadStates.Reload || this.state === LoadStates.Working;
  }

  get isIdle(): boolean {
    return this.state !== LoadStates.Init;
  }

  get isOk(): boolean {
    return this.state === LoadStates.Ok;
  }

  get isError(): boolean {
    return this.state === LoadStates.Error;
  }
}
