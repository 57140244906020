import {Component, forwardRef, Input} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator,
  Validators
} from "@angular/forms";
import {Address, EmptyAddress, regions} from "./address.model";

@Component({
  selector: "app-address",
  templateUrl: "./address.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AddressComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => AddressComponent), multi: true}]
})
export class AddressComponent implements ControlValueAccessor, Validator {
  @Input() readonly: boolean;
  @Input() showEContact = false;
  regions = regions;
  form: FormGroup;
  onTouched: () => void;

  constructor() {
    this.form = new FormGroup({
      "country": new FormControl(EmptyAddress.country, [Validators.required]),
      "region": new FormControl(EmptyAddress.region, [Validators.required]),
      "city": new FormControl(EmptyAddress.city, [Validators.required]),
      "zipCode": new FormControl(EmptyAddress.zipCode),
      "line1": new FormControl(EmptyAddress.line1, [Validators.required]),
      "phoneNumber": new FormControl(EmptyAddress.phoneNumber),
      "email": new FormControl(EmptyAddress.email),
      "web": new FormControl(EmptyAddress.web)
    });
  }

  writeValue(value: Address) {
    if (value) {
      const newValue = Object.assign({}, EmptyAddress, value, {country: EmptyAddress.country});
      delete (newValue as any).fullAddress;
      this.form.setValue(newValue);
    }
  }

  registerOnChange(fn: (value: Address) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : {invalidAddress: {message: "Address is invalid"}};
  }
}
