<form [formGroup]="form" class="row no-gutters">
  <ion-item appIonFloatingLabel
            class="col-3">
    <ion-label translate="exporterFilterRule.operation.type"></ion-label>
    <select class="m-0 p-0 border-0 w-100"
            formControlName="type">
      @for (x of opTypes; track x) {
        <option [value]="x">{{ 'exporterFilterRule.operation.' + x | translate }}</option>
      }
    </select>
    <ion-icon name="trash" color="danger" class="cursor-pointer sqr-xs"
              (click)="remove.emit()">
    </ion-icon>
  </ion-item>
  @switch (filterOpType) {
    @case (FilterOperationType.SearchReplace) {
      <ion-item class="floating-label col-3 pl-1">
        <ion-input [formControl]="$any(params().at(0))"
                   type="string" spellcheck="false"
                   [readonly]="readOnly"
                   [label]="'exporterFilterRule.operation.searchReplace.search' | translate"
                   labelPlacement="floating">
        </ion-input>
      </ion-item>
      <ion-item class="floating-label col-3 pl-1">
        <ion-input [formControl]="$any(params().at(1))"
                   type="string" spellcheck="false"
                   [readonly]="readOnly"
                   [label]="'exporterFilterRule.operation.searchReplace.replace' | translate"
                   labelPlacement="floating">
        </ion-input>
      </ion-item>
      <ion-item class="input col-3 pl-1"
                [title]="'exporterFilterRule.operation.searchReplace.isCaseSensitive' | translate">
        <ion-checkbox [formControl]="$any(params().at(2))"
                      justify="start"
                      labelPlacement="end">
          <span translate="exporterFilterRule.operation.searchReplace.isCaseSensitive"></span>
        </ion-checkbox>
      </ion-item>
    }
    @case (FilterOperationType.FormatNumber) {
      <ion-item class="floating-label col-3 pl-1">
        <ion-input [formControl]="$any(params().at(0))"
                   type="number" spellcheck="false"
                   [readonly]="readOnly"
                   [label]="'exporterFilterRule.operation.formatNumber.decimalPlaces' | translate"
                   labelPlacement="floating">
        </ion-input>
      </ion-item>
      <ion-item class="floating-label col-3 pl-1">
        <ion-input [formControl]="$any(params().at(1))"
                   type="string" spellcheck="false"
                   [readonly]="readOnly"
                   [label]="'exporterFilterRule.operation.formatNumber.thousandsSeparator' | translate"
                   labelPlacement="floating">
        </ion-input>
      </ion-item>
    }
    @case (FilterOperationType.FormatDate) {
      <ion-item class="floating-label col-3 pl-1">
        <ion-input [formControl]="$any(params().at(0))"
                   type="string" spellcheck="false"
                   [readonly]="readOnly"
                   [label]="'exporterFilterRule.operation.formatDate.format' | translate"
                   labelPlacement="floating">
        </ion-input>
      </ion-item>
    }
    @case (FilterOperationType.Trim) {
      <ion-item appIonFloatingLabel
                class="col-3 pl-1">
        <ion-label translate="exporterFilterRule.operation.trim.type"></ion-label>
        <select class="m-0 p-0 border-0 w-100"
                [formControl]="$any(params().at(0))">
          <option *ngFor="let x of trimTypes" [value]="x">{{ x }}</option>
        </select>
      </ion-item>
    }
    @case (FilterOperationType.Casing) {
      <ion-item appIonFloatingLabel
                class="col-3 pl-1">
        <ion-label translate="exporterFilterRule.operation.casing.type"></ion-label>
        <select class="m-0 p-0 border-0 w-100"
                [formControl]="$any(params().at(0))">
          <option *ngFor="let x of casingTypes" [value]="x">{{ x }}</option>
        </select>
      </ion-item>
    }
  }
</form>
