export interface HateoasLink {
  href: string;
  method: string;
  rel: string;
}

export interface HateoasItem extends Object {
  id?: any;
  // links?: Array<HateoasLink>;
}

export interface HateoasList<T> {
  items: Array<T>;
  // links: Array<HateoasLink>;
  count: number;
  totalCount: number;
}

export const EmptyHateoasList: HateoasList<any> = {
  items: [],
  count: 0,
  totalCount: 0
};

export interface CrudRights {
  allowCreate: boolean;
  allowRead: boolean;
  allowEdit: boolean;
  allowDelete: boolean;
}
