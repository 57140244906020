import {Component, Input} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {ApiHttpErrorResponse} from "core";

@Component({
  selector: "app-httperror-details",
  templateUrl: "./httperror-details.component.html"
})
export class HttpErrorDetailsComponent {
  @Input() error: HttpErrorResponse | ApiHttpErrorResponse;
  @Input() color = "danger";
}
