import {
  Directive,
  Input,
  ElementRef,
  EventEmitter,
  Output,
  OnDestroy, OnInit
} from "@angular/core";

@Directive({
  selector: "[appVisibilityChange]"
})
export class VisibilityChangeDirective implements OnInit, OnDestroy {
  @Input() rootMargin = "";
  @Output() visibilityChange = new EventEmitter<{elementRef: ElementRef, isVisible: boolean}>();

  private observer: IntersectionObserver;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.observer = new IntersectionObserver(
      ([e]) => {
        this.visibilityChange.emit({elementRef: this.el, isVisible: e.intersectionRatio === 1});
      },
      {
        root: document,
        rootMargin: this.rootMargin,
        threshold: 1
      }
    );
    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    this.observer.unobserve(this.el.nativeElement);
    this.observer.disconnect();
  }
}
