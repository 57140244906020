@if (loadState.isWorking) {
  <app-preloader [hideCircle]="isPullToRefresh" class="overlay-fixed"></app-preloader>
}

<!--<ion-refresher [disabled]="!refreshActionBarItem"-->
<!--               (ionRefresh)="handleRefresh($event)"-->
<!--               slot="fixed">-->
<!--  <ion-refresher-content></ion-refresher-content>-->
<!--</ion-refresher>-->

<div class="page-header mt-6">
  <div appVisibilityChange
       class="d-none d-md-block py-0 color-dark"
       [rootMargin]="'-33px 0px'"
       (visibilityChange)="stickBreadcrumbs($event)">
    <app-action-bar [type]="ActionBarType.Breadcrumbs"
                    [actions]="bCrumbs">
    </app-action-bar>
  </div>
  <ion-toolbar class="d-flex align-items-center mb-4"
               appVisibilityChange
               [rootMargin]="'-48px 0px'"
               (visibilityChange)="stickTitle($event)">
    <div id="app-title"
         class="font-size-xxl font-weiboldght-bolder"
         [translate]="pageTitle"
         (click)="titleClick.emit($event)"
         tourAnchor="page-title">
    </div>
    @if (closeActionBarItem || tourId) {
      <ion-buttons slot="primary">
        @if (tourId && isDesktop) {
          <ion-button color="black"
                      (click)="handleHelp()"
                      tourAnchor="page-help">
            <ion-icon name="help-outline" class="sqr-sm badge badge-outline badge-dark"></ion-icon>
          </ion-button>
        }
        @if (closeActionBarItem) {
          <ion-button color="black" (click)="handleClose($event)">
            <ion-icon name="close-outline" class="sqr-sm"></ion-icon>
          </ion-button>
        }
      </ion-buttons>
    }
  </ion-toolbar>
  @if (searchActionBarItem) {
    <ion-toolbar [style.z-index]="0"
                 class="d-block d-md-none">
      <ion-searchbar color="light"
                     [debounce]="500"
                     [placeholder]="searchActionBarItem.title.toString() | translate"
                     (ionChange)="handleSearch($event)"
                     class="p-0">
      </ion-searchbar>
    </ion-toolbar>
  }
</div>

@if (isActionsReady && desktopActions?.length) {
  <div class="d-none d-md-block shadow-sm-bottom radius-xs page-actions"
       appVisibilityChange
       [rootMargin]="'-69px 100px'"
       (visibilityChange)="stickActions($event)"
       tourAnchor="page-actions">
    <app-action-bar [type]="ActionBarType.Toolbar"
                    [actions]="desktopActions"
                    [color]="actionsToolbarColor">
    </app-action-bar>
  </div><!-- 69px = roundup($nav-bar-height + 1rem, px) -->
}

<div class="pb-2"></div>

@if (loadState.isIdle && !loadState.isError) {
  <div class="position-relative animated faster fadeIn">
    <ng-content></ng-content>
    <div class="pb-6"></div>
    @if (loadState.isError) {
      <div class="position-absolute trbl-0 d-flex align-items-center justify-content-center">
        <app-httperror-details style="width: 70%"
                               [error]="loadState.error">
        </app-httperror-details>
      </div>
    }
  </div>
}
@if (mobileSmartBottomActions?.length) {
  <app-action-bar [actions]="mobileSmartBottomActions"
                  [type]="ActionBarType.Buttons"
                  class="d-block mx-4">
  </app-action-bar>
}

<!--
<ion-infinite-scroll threshold="100px"
                     [disabled]="!infiniteScrollActionBarItem"
                     (ionInfinite)="handleInfiniteScroll($event)">
  <ion-infinite-scroll-content></ion-infinite-scroll-content>
</ion-infinite-scroll>
-->
@if (mobileNormalActions?.length) {
  <app-action-bar class="d-block d-md-none left-4 position-fixed v-bottom"
                  [type]="ActionBarType.Drop"
                  [actions]="mobileNormalActions">
  </app-action-bar>
}
