import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

export enum FileSource {
  FileSys = "file-system",
  Camera = "camera",
  Gallery = "gallery",
  Weblink = "weblink",
}

export interface FileModel {
  source: FileSource;
  name: string;
  type?: string;
  size?: number;
  contentUrl?: string;
  previewImageUrl?: string;
  lastModified?: number;
  arrayBuffer?: ArrayBuffer;
}

@Injectable({providedIn: "root"})
export class FileService {
  private arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
    const typedArray = new Uint8Array(arrayBuffer);
    const str = typedArray.reduce((data, byte) => data + String.fromCharCode(byte), "");
    return btoa(str);
  }

  private dataUrlToU8Arr(dataUrl: string) {
    if (dataUrl.startsWith("http")) {
      return null;
    }
    const arr = dataUrl.split(",");
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return u8arr;
  }

  base64ToDataUrl(base64: string, type: string) {
    return `data:${type};base64,${base64}`;
  }

  toFileModel(file: File, source: FileSource): Observable<FileModel> {
    return new Observable(o => {
      // if (!file.type.startsWith("image/")) {
      //   o.next({
      //     source,
      //     name: file.name,
      //     type: file.type,
      //     size: file.size,
      //     lastModified: file.lastModified
      //   });
      //   o.complete();
      //   return;
      // }
      const arrayBufferFileReader: FileReader = new FileReader();
      arrayBufferFileReader.readAsArrayBuffer(file);
      arrayBufferFileReader.onloadend = () => {
        const arrayBuffer = arrayBufferFileReader.result as ArrayBuffer;
        const dataUrl = this.base64ToDataUrl(this.arrayBufferToBase64(arrayBuffer), file.type);
        o.next({
          source,
          name: file.name,
          type: file.type,
          size: file.size,
          lastModified: file.lastModified,
          contentUrl: dataUrl,
          arrayBuffer
        });
        o.complete();
      };
    });
  }

  toFile(fileModel: FileModel): File {
    const u8Arr = this.dataUrlToU8Arr(fileModel.contentUrl);
    if (!u8Arr) {
      return null;
    }
    const blob = new Blob([u8Arr], {
      type: fileModel.type
    }) as any;
    blob.name = fileModel.name;
    blob.lastModified = fileModel.lastModified;
    return blob;
  }
}
