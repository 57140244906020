import {Component, EventEmitter, forwardRef, Input, OnInit, Output, QueryList, ViewChildren} from "@angular/core";
import {ControlValueAccessor, FormArray, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: "app-check-list",
  templateUrl: "./check-list.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckListComponent), multi: true}]
})
export class CheckListComponent implements OnInit, ControlValueAccessor {
  @Input() readonly = false;
  @Input() options: Array<{id: string, name: string, photoUrl?: string}>;
  @Input() created: (x: CheckListComponent) => void;

  @ViewChildren("check") checkItems: QueryList<any>;
  formArray: FormArray;
  onTouched: () => void;

  constructor() {
    this.formArray = new FormArray([]);
  }

  ngOnInit() {
    this.created(this);
  }

  writeValue(value: Array<string>) {
    this.formArray.clear();
    if (value?.length) {
      value.forEach(v => this.formArray.push(new FormControl(v)));
    }
  }

  registerOnChange(fn: (value: string) => void) {
    this.formArray.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  changed($event: any, index: number) {
    const checked = $event.target.checked;
    const optionId = this.options[index].id;
    const idx = this.formArray.controls.findIndex(c => c.value === optionId);
    if (checked) {
      if (idx === -1) {
        this.formArray.push(new FormControl(optionId));
      }
    } else {
      if (idx !== -1) {
        this.formArray.removeAt(idx);
      }
    }
    return $event.preventDefault();
  }

  isChecked(optionId) {
    return this.formArray.controls.findIndex(c => c.value === optionId) !== -1;
  }
}
