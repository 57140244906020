import {
  Directive,
  ElementRef,
  EventEmitter,
  Output,
  OnDestroy, OnInit, NgZone
} from "@angular/core";

@Directive({
  selector: "[appSizeChange]"
})
export class SizeChangeDirective implements OnInit, OnDestroy {
  @Output() sizeChanged = new EventEmitter<{elementRef: ElementRef, width: number, height: number}>();

  private observer: ResizeObserver;
  private lastWidth;
  // private lastHeight;

  constructor(private el: ElementRef,
              private zone: NgZone) {
  }

  ngOnInit() {
    this.observer = new ResizeObserver((entries: Array<ResizeObserverEntry>) => {
      this.zone.run(() => {
        const width = entries[0].contentRect.width;
        const height = entries[0].contentRect.height;
        if (width === this.lastWidth) {
          return;
        }
        this.lastWidth = width;
        // this.lastHeight = height;
        this.sizeChanged.emit({elementRef: this.el, width, height});
      });
    });
    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    this.observer.unobserve(this.el.nativeElement);
    this.observer.disconnect();
  }
}
