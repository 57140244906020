<form [formGroup]="form">
  <ion-item class="floating-label">
    <ion-input formControlName="url" [appAutofocus]="1000" autofocus="true"
               [label]="label | translate"
               labelPlacement="floating">
    </ion-input>
    <app-form-controls-errors [control]="form.controls['url']"></app-form-controls-errors>
  </ion-item>
</form>
<div class="w-100 d-flex flex-column align-items-center justify-content-center position-relative"
     style="height: 44vh;">
  <app-file-thumb *ngIf="form.get('fileModel')?.value"
                  style="height: 80%"
                  class="text-center py-2"
                  [mimeTypes]="acceptedMimeTypes"
                  [file]="form.get('fileModel')?.value">
  </app-file-thumb>
  <div class="py-1" [innerText]="form.get('fileModel')?.value?.name"></div>
  <div *ngIf="!form.get('fileModel')?.value"
       class="color-gray font-size-sm"
       translate="dict.noPreview">
  </div>
</div>
