@if (loadState.isWorking) {
  <app-preloader class="sqr-xl d-flex justify-content-center align-items-center"></app-preloader>
} @else if (loadState.isOk) {
  <app-auth-img class="sqr-xl hover"
                [url]="url">
    <ng-content></ng-content>
  </app-auth-img>
  @if (!readOnly) {
    <div class="img-overlay sqr-xl d-flex justify-content-center overflow-hidden">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <label (click)="pickFile()"
               class="radius-circle bg-primary sqr-md cursor-pointer d-flex justify-content-center align-items-center"
               data-popup="lightbox">
          <ion-icon name="image"></ion-icon>
        </label>
      </div>
      <ion-icon name="trash"
                class="position-absolute bottom-right-0 cursor-pointer p-1"
                (click)="reset.emit()">
      </ion-icon>
    </div>
  }
}
