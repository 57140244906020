import {Directive, Input, ElementRef, OnInit} from "@angular/core";

@Directive({
  selector: "[appAutofocus]"
})
export class AutofocusDirective implements OnInit {
  @Input() appAutofocus: number;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    if (this.appAutofocus) {
      setTimeout(() => {
        if (this.el.nativeElement.setFocus) {
          return this.el.nativeElement.setFocus();
        }
        this.el.nativeElement.focus();
      }, this.appAutofocus || 10);
    }
  }
}
