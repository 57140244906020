import {Pipe, PipeTransform} from "@angular/core";
import defaultExport from "dayjs";

@Pipe({name: "amFormat"})
export class FormatPipe implements PipeTransform {
  static fixDateValue(value: number | string | Date): string {
    if (!value) {
      return "";
    }
    if (value === +value) {
      value = new Date(+value);
    }
    if (value instanceof Date) {
      return defaultExport(value).format("YYYY-MM-DD");
    }

    const k = value.toString().indexOf(".");
    if (k === -1) {
      return value.toString();
    }
    return value.toString().substring(0, k) + "Z";
  }

  transform(value: number | string | Date, format: string): string {
    if (!value) {
      return "";
    }
    const dateValue = value instanceof Date
      ? value
      : new Date(Date.parse(FormatPipe.fixDateValue(value)));
    return dateValue.getFullYear() > 1
      ? defaultExport(dateValue).format(format)
      : "";
  }
}
