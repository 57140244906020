import {Pipe, PipeTransform} from "@angular/core";
import {TranslateInterpolation} from "core";

@Pipe({name: "translateInterpolation"})
export class TranslateInterpolationPipe implements PipeTransform {
  constructor(private translateInterpolation: TranslateInterpolation) {
  }

  transform(interpolatedText: string) {
    return this.translateInterpolation.translate(interpolatedText);
  }
}
