export class EvSink {
  private listeners: Array<Function>;

  constructor() {
    this.listeners = [];
  }

  set sink(listener: Function) {
    this.listeners.push(listener);
  }

  unsubscribe() {
    this.listeners.forEach(f => f());
  }

  get length() {
    return this.listeners?.length || 0;
  }
}
