import {Component, Input} from "@angular/core";

@Component({
  selector: "app-preloader2",
  templateUrl: "./preloader2.component.html",
  styleUrls: ["./preloader2.component.scss"]
})
export class Preloader2Component {
  @Input() color = "success";
}
