import {Platforms} from "@ionic/core";
import {Observable} from "rxjs";

export enum ActionBarItemType {
  Separator = "separator",
  Label = "label",
  Link = "link",
  Bool = "bool",
  TextBox = "text-box",
  Search = "search",
  SearchAnimate = "search-animate",
  FilePicker = "file-picker",
  YearMonthPicker = "year-month-picker",
  Segment = "segment",
  Breadcrumbs = "breadcrumbs",
  LinkMultiselect = "link-multiselect",
  ColorPicker = "color-picker",

  Close = "close",
  InfiniteScroll = "infinite-scroll",
  Refresh = "refresh",
  NgContent = "ng-content",
  Shortcut = "shortcut"
}

export enum ActionBarType {
  Toolbar = "toolbar",
  Buttons = "buttons",
  IconBar = "iconbar",
  Drop = "drop",
  SideBar = "sidebar",
  SideMenu = "sidemenu",
  Breadcrumbs = "breadcrumbs",
  Segment = "segment",
}

export enum ActionBarItemSlot {
  Start = "start",
  Main = "main",
  End = "end",
}

export enum ActionBarItemPos {
  Default = "default",
  SmartBottomMobile = "smart-bottom-mobile"
}

export interface ActionBarItemShortcutKey {
  ctrl?: boolean;
  alt?: boolean;
  shift?: boolean;
  key?: string;
  keyCode?: number;
}

export interface ActionBarItem {
  type?: ActionBarItemType;
  slot?: ActionBarItemSlot;
  pos?: ActionBarItemPos;

  isDisabled?: boolean | Function;
  isHidden?: boolean | Function;
  isHiddenOnPlatforms?: Array<Platforms>;
  isBlinking?: boolean;

  actionValue?: any | Array<any>;
  actionValueTexts?: Array<string>;

  parent?: ActionBarItem;
  title?: string | Function | {
    [key: string]: string
  };
  description?: string;
  icon?: string | Function;
  iconCssClass?: string;
  photoUrl?: string;
  badgeValue?: string;
  badgeColor?: string;
  badgeCssClass?: string;

  color?: string;
  cssClass?: string | Function;
  cssStyle?: {[p: string]: any} | Function;
  handler?: Function;
  routerLink?: string;

  multiple?: boolean;
  acceptedMimeTypes?: string;
  actions?: Array<ActionBarItem> | Observable<any>;
  actionsTitle?: string;
  additionalAction?: ActionBarItem;
  hotKey?: string;
  tourAnchor?: string;
  enableFiltering?: boolean;

  id?: string;
  x?: number;
  y?: number;
  componentMethodFn?: string;
  componentMethodFnArgs?: Array<any>;
}
