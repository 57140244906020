import {Inject, Injectable} from "@angular/core";
import {EventManager} from "@angular/platform-browser";
import {DOCUMENT} from "@angular/common";
import {merge, Observable, Subscriber} from "rxjs";
import {ModalController, PopoverController} from "@ionic/angular";
import {AppEventsService} from "core";

export interface HotkeyEvent extends KeyboardEvent {
  hotKey: string;
}

export interface HotkeyMap {
  hotKey: string;
  description?: string;
  icon?: string;
}

@Injectable({providedIn: "root"})
export class HotkeysService {
  constructor(private eventManager: EventManager,
              private modalController: ModalController,
              private popoverController: PopoverController,
              private appEventsService: AppEventsService,
              @Inject(DOCUMENT) private document: Document) {
    // const disposeCheatSheet = this.eventManager.addEventListener(this.document as any, `keydown.shift.?`, (e) => {
    //   if (actualContextId !== this.activeContextId || this.isFocusedOnInput(e)) {
    //     return;
    //   }
    //   const actualStack = this.stack.find(stk => stk.contextId === actualContextId);
    //   console.log(actualStack.hotKeys);
    // });
  }

  private stack: Array<{contextId: string, hotKeys: Array<HotkeyMap>}> = [];
  private activeContextId: string;
  private hotKeyCount = 0;

  private addShortcut(hotkeyMap: HotkeyMap, contextId: string): Observable<HotkeyEvent> {
    const actualContextId = this.activeContextId = this.addContextKey(hotkeyMap, contextId);
    return new Observable((s: Subscriber<HotkeyEvent>) => {
      const dispose = this.eventManager.addEventListener(this.document as any, `keydown.${hotkeyMap.hotKey}`, (e) => {
        if (actualContextId !== this.activeContextId || this.isFocusedOnInput(e)) {
          return;
        }
        const actualStack = this.stack.find(stk => stk.contextId === actualContextId);
        this.hotKeyCount = this.hotKeyCount || actualStack.hotKeys.filter(x => x.hotKey === hotkeyMap.hotKey).length;
        console.log("emitting", hotkeyMap.hotKey, this.hotKeyCount);
        this.hotKeyCount--;
        if (this.hotKeyCount <= 0) {
          console.log("cancelling", hotkeyMap.hotKey, this.hotKeyCount);
          this.cancelEvt(e);
        }
        if (hotkeyMap.hotKey === "shift.?") {
          console.log("cheatsheet", actualStack.hotKeys);
          this.appEventsService.notifyShowHotkeysCheatsheet(actualStack.hotKeys);
        } else {
          s.next(Object.assign(e, {hotKey: hotkeyMap.hotKey}));
        }
      });
      return () => {
        console.log(`Disposing ${actualContextId}-${hotkeyMap.hotKey}`);
        this.clearContextKey(hotkeyMap, actualContextId);
        dispose();
      };
    });
  }

  private addContextKey(hotkeyMap: HotkeyMap, contextId: string): string {
    let context = contextId
      ? this.stack.find(x => x.contextId === contextId)
      : this.stack[this.stack.length - 1];
    if (context) {
      if (!context.hotKeys) {
        context.hotKeys = [];
      }
      if (!context.hotKeys.map(x => x.hotKey).includes(hotkeyMap.hotKey)) {
        context.hotKeys.push(hotkeyMap);
      }
    } else {
      context = {contextId, hotKeys: [hotkeyMap]};
      this.stack.push(context);
    }
    // console.log(`Stack: ${JSON.stringify(this.stack)}`, this.activeContextId);
    return context.contextId;
  }

  private clearContextKey(hotkeyMap: HotkeyMap, contextId: string) {
    const contextIdx = this.stack.findIndex(x => x.contextId === contextId);
    if (contextIdx === -1) {
      return;
    }
    const context = this.stack[contextIdx];
    context.hotKeys.splice(context.hotKeys.map(x => x.hotKey).indexOf(hotkeyMap.hotKey), 1);
    if (!context.hotKeys.length) {
      this.stack.splice(contextIdx, 1);
      if (this.activeContextId === contextId) {
        this.activeContextId = this.stack.length
          ? this.stack[this.stack.length - 1].contextId
          : null;
      }
    }
    // console.log(`Stack: ${JSON.stringify(this.stack)}`, this.activeContextId);
  }

  private cancelEvt(e) {
    if (!e) {
      return;
    }
    if (e.preventDefault) {
      e.preventDefault();
    }
    if (e.stopImmediatePropagation) {
      e.stopImmediatePropagation();
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    e.cancelBubble = true;
    e.returnValue = false;
  }

  private isFocusedOnInput(e): boolean {
    const targetType = e?.target?.tagName;
    if (!targetType) {
      return false;
    }
    return e?.target?.isContentEditable || ["INPUT", "TEXTAREA", "SELECT"].indexOf(targetType.toUpperCase()) !== -1;
  }

  addShortcuts(shortcuts: Array<HotkeyMap>): Observable<Observable<HotkeyEvent>> {
    const actualShortcuts = [...shortcuts, {hotKey: "shift.?", description: ""} as HotkeyMap];
    return new Observable((o: Subscriber<Observable<HotkeyEvent>>) => {
      setTimeout(() => {
        Promise.all([this.modalController.getTop(), this.popoverController.getTop()])
          .then((res: [HTMLIonModalElement, any]) => {
            const ctxId = res[0]?.id || res[1]?.id || "global";
            // console.log("ctx", ctxId);
            o.next(merge(...actualShortcuts.map(x => this.addShortcut(x, ctxId))));
            o.complete();
          }, (err) => {
            console.log(err);
          });
      }, 500);
    });
  }
}
