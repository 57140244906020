import {HttpErrorResponse} from "@angular/common/http";
import {ApiHttpErrorResponse} from "core";

export class ErrorString {
  transform(error: HttpErrorResponse | ApiHttpErrorResponse | any): string {
    if (!error) {
      return "";
    }
    if (error.error?.errors) {
      let ret = "";
      const keys = Object.keys(error.error?.errors);
      keys.forEach(k => {
        ret += `${k}: ${error.error?.errors[k][0]}\n`;
      });
      return ret.trim();
    }
    return error.error?.message
      || ((typeof error.error === "string") && error.error)
      || error.message
      || `${error.status} ${error.statusText}`;
  }
}
