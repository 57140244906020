import {mapToCanActivate, Routes} from "@angular/router";
import {AuthGuard} from "core";
import {Page403Component, Page404Component} from "shared";
import {environment} from "environment";

export const routes: Routes = [{
  path: "access-denied",
  canActivate: mapToCanActivate([AuthGuard]),
  component: Page403Component,
  data: {claims: [{logged: "true"}]}
}, {
  path: "",
  canActivate: mapToCanActivate([AuthGuard]),
  loadChildren: () => import("./main/main.module.routed").then(m => m.MainModuleRouted),
  data: {claims: [{logged: "true"}]}
}, {
  path: "auth",
  loadChildren: () => import("./auth/auth.module.routed").then(m => m.AuthModuleRouted)
}, environment.isWeb ? {
  path: "public",
  loadChildren: () => import("./public/public.module.routed").then(m => m.PublicModuleRouted)
} : null, {
  path: "**",
  component: Page404Component
}].filter(x => x);
