import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FileModel} from "core";
import {SupportedMimeType} from "documents/documents.model";

@Component({
  selector: "app-file-thumb",
  templateUrl: "./file-thumb.component.html",
  styleUrls: ["./file-thumb.component.scss"]
})
export class FileThumbComponent {
  @Input() mimeTypes: Array<SupportedMimeType>;

  @Input() set file(value: FileModel) {
    if (value?.type.startsWith("image/")) {
      this.mimePreviewImageUrl = this.previewImageUrl = value.contentUrl;
      return;
    }
    const mime = this.mimeTypes.find(m => m.mime.startsWith(value.type));
    this.mimePreviewImageUrl = this.previewImageUrl = mime
      ? `~/svg/mime-${mime.typeName.toLowerCase()}.svg`
      : "~/svg/document.svg";
    if (value?.previewImageUrl) {
      this.previewImageUrl = value.previewImageUrl;
    }
  }

  @Input() cssStyle: string;
  @Output() loaded = new EventEmitter<any>();
  @Output() error = new EventEmitter<any>();

  previewImageUrl: string;
  mimePreviewImageUrl: string;
}
