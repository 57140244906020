import {Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Subject, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map} from "rxjs/operators";

@Directive({
  selector: "[appDebounceInput]"
})
export class DebounceInputDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 500;
  @Input() minLength = 1;
  @Output() debounceInput = new EventEmitter<string>();
  private subject = new Subject();
  private subscription: Subscription;

  ngOnInit() {
    this.subscription = this.subject.pipe(
      map((event: any) => event.target.value),
      filter(res => res.length > this.minLength),
      debounceTime(this.debounceTime),
      distinctUntilChanged()
    ).subscribe(e => this.debounceInput.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener("keyup", ["$event"])
  keyUpEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.subject.next(event);
  }
}
