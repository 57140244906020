import {Component, EventEmitter, forwardRef, Input, Output} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor, FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";
import {ArrayService, SubSink} from "core";

export enum FilterOperationType {
  None,
  Skip,
  SearchReplace,
  FormatNumber,
  FormatDate,
  Trim,
  Casing
}

export interface ExporterFilterRuleOperation {
  type: FilterOperationType;
  params?: string[];
}

@Component({
  selector: "app-exporter-filter-rule-operation",
  templateUrl: "./exporter-filter-rule-operation.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ExporterFilterRuleOperationComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => ExporterFilterRuleOperationComponent), multi: true}]
})
export class ExporterFilterRuleOperationComponent implements ControlValueAccessor, Validator {
  @Input() readOnly = false;
  @Output() remove = new EventEmitter();

  form: FormGroup;
  onTouched: () => void;
  FilterOperationType = FilterOperationType;
  opTypes: Array<FilterOperationType> = [
    FilterOperationType.None,
    FilterOperationType.Skip,
    FilterOperationType.SearchReplace,
    FilterOperationType.FormatNumber,
    FilterOperationType.FormatDate,
    FilterOperationType.Trim,
    FilterOperationType.Casing
  ];
  trimTypes = ["start-end", "start", "end", "duplicated-spaces", "all-spaces"];
  casingTypes = ["SentenceCase", "lowercase", "UPPERCASE", "snake_case", "kebab-case", "camelCase"];
  private sub = new SubSink();

  constructor(private arrayService: ArrayService) {
    this.form = new FormGroup({
      "type": new FormControl(FilterOperationType.None, [Validators.required]),
      "params": new FormArray([])
    });
    setInterval(() => {
      console.log(Number(this.form.value["type"]));
    }, 1000);
  }

  writeValue(value: ExporterFilterRuleOperation) {
    this.sub.unsubscribe(true);
    const val = this.arrayService.smartAssign({
      type: FilterOperationType.None,
      params: []
    } as ExporterFilterRuleOperation, value) as ExporterFilterRuleOperation;
    this.form.get("type").setValue(val.type);
    this.params().clear();
    val.params.forEach(this.addNewParam.bind(this));
    setTimeout(() => {
      this.sub.sink = this.form.get("type").valueChanges
        .subscribe((opType: FilterOperationType) => {
          const ctrls =
            +opType === FilterOperationType.SearchReplace
              ? [new FormControl(""), new FormControl(""), new FormControl(false)]
              : +opType === FilterOperationType.FormatNumber
                ? [new FormControl(2), new FormControl(",")]
                : +opType === FilterOperationType.FormatDate
                  ? [new FormControl("YYYY.MM.DD")]
                  : +opType === FilterOperationType.Trim
                    ? [new FormControl("start-end")]
                    : +opType === FilterOperationType.Casing
                      ? [new FormControl("SentenceCase")]
                      : [];
          this.params().clear();
          ctrls.forEach(ctrl => this.params().push(ctrl));
        });
    }, 100);
  }

  registerOnChange(fn: (value: ExporterFilterRuleOperation) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : {invalid: {message: "ExporterFilterRuleOperation is invalid"}};
  }

  params(): FormArray {
    return this.form.controls["params"] as FormArray;
  }

  addNewParam(param: string = null) {
    this.params().push(new FormControl(param || ""));
  }

  get filterOpType(): FilterOperationType {
    return Number(this.form.value["type"]);
  }
}
