import {Injectable} from "@angular/core";
import {fromEvent, merge, Observable, Observer} from "rxjs";
import {map, tap} from "rxjs/operators";

@Injectable({providedIn: "root"})
export class InternetConnectionService {
  public observe(): Observable<boolean> {
    return merge(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })).pipe(tap((isOnline: boolean) => {
      console.log(`Online: ${isOnline}`);
    }));
  }

  public isConnected() {
    return navigator.onLine;
  }
}
