import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {MqService} from "../mq/mq.service";
import {ActionBarItem} from "shared";
import {HotkeyMap} from "core";

@Injectable({providedIn: "root"})
export class AppEventsService {
  constructor(private mqService: MqService) {
  }

  notifyAppLoaded() {
    this.mqService.pub("app.events.loaded", null);
  }

  onAppLoaded(): Observable<any> {
    return this.mqService.evt("app.events.loaded");
  }

  notifyAppUnloaded() {
    this.mqService.pub("app.events.unloaded", null);
  }

  onAppUnloaded(): Observable<any> {
    return this.mqService.evt("app.events.unloaded");
  }

  notifyAppShown() {
    this.mqService.pub("app.events.shown", null);
  }

  onAppShown(): Observable<any> {
    return this.mqService.evt("app.events.shown");
  }

  notifyAppHidden() {
    this.mqService.pub("app.events.hidden", null);
  }

  onAppHidden(): Observable<any> {
    return this.mqService.evt("app.events.hidden");
  }

  notifyAppOffline() {
    this.mqService.pub("app.events.offline", null);
  }

  onAppOffline() {
    return this.mqService.evt("app.events.offline");
  }

  notifyAppOnline() {
    this.mqService.pub("app.events.online", null);
  }

  onAppOnline() {
    return this.mqService.evt("app.events.online");
  }

  notifyAppBackOnline() {
    this.mqService.pub("app.events.backOnline", null);
  }

  onAppBackOnline() {
    return this.mqService.evt("app.events.backOnline");
  }

  notifyShowBreadcrumbs(breadcrumbActions: Array<ActionBarItem>) {
    this.mqService.pub("app.events.showBreadcrumbs", breadcrumbActions);
  }

  onShowBreadcrumbs(): Observable<Array<ActionBarItem>> {
    return this.mqService.evt("app.events.showBreadcrumbs");
  }

  notifyShowAppTitle(appTitle: string) {
    this.mqService.pub("app.events.showAppTitle", appTitle);
  }

  onShowAppTitle(): Observable<string> {
    return this.mqService.evt("app.events.showAppTitle");
  }

  notifyShowHotkeysCheatsheet(hotKeys: Array<HotkeyMap>) {
    this.mqService.pub("app.events.showShowHotkeysCheatsheet", hotKeys);
  }

  onShowShowHotkeysCheatsheet(): Observable<Array<HotkeyMap>> {
    return this.mqService.evt("app.events.showShowHotkeysCheatsheet");
  }
}
