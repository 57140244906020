import {Directive, ElementRef, Input, Output, OnInit, Renderer2, EventEmitter, OnDestroy} from "@angular/core";
import {DomUtilsService, EvSink} from "core";

@Directive({
  selector: "[appResizable]"
})
export class ResizableDirective implements OnInit, OnDestroy {
  @Input() directions: Array<"vertical" | "horizontal"> = ["vertical", "horizontal"];
  @Input() snapResolution = 0;

  @Input() set disabled(value: boolean) {
    this._disabled = value;
    if (!this.cornerEl) {
      return;
    }
    if (!value) {
      this.renderer.addClass(this.cornerEl, "grabber");
    } else {
      this.renderer.removeClass(this.cornerEl, "grabber");
    }
  }

  @Output() resizeEnd = new EventEmitter<{width: number, height: number}>();

  private mouseDownListener: Function;
  private evSink = new EvSink();
  private start: {x: number; y: number; width: number; height: number};
  private _disabled: boolean;
  private cornerEl: any;

  constructor(private renderer: Renderer2,
              private element: ElementRef,
              private domUtilsService: DomUtilsService) {
  }

  ngOnInit() {
    this.cornerEl = this.renderer.createElement("div");
    this.renderer.addClass(this.cornerEl, "grabber");
    this.renderer.appendChild(this.element.nativeElement, this.cornerEl);

    this.mouseDownListener = this.renderer.listen(this.cornerEl, "mousedown", this.onResizeStart.bind(this));
  }

  ngOnDestroy() {
    this.evSink.unsubscribe();
    if (this.mouseDownListener) {
      this.mouseDownListener();
    }
  }

  private onResizeStart(event: MouseEvent) {
    this.start = Object.assign({}, this.domUtilsService.getClientCoords(event), {
      width: this.element.nativeElement.clientWidth,
      height: this.element.nativeElement.clientHeight
    });

    if (event.stopPropagation) {
      event.stopPropagation();
    }
    if (event.preventDefault) {
      event.preventDefault();
    }
    event.cancelBubble = true;
    event.returnValue = false;

    this.evSink.sink = this.renderer.listen(document, "mousemove", this.onResize.bind(this));
    this.evSink.sink = this.renderer.listen(document, "mouseup", this.onResizeEnd.bind(this));
  }

  private onResizeEnd(event: MouseEvent) {
    this.evSink.unsubscribe();
    if (!this.start) {
      return;
    }
    this.start = null;
    let width = this.element.nativeElement.clientWidth + 2;
    let height = this.element.nativeElement.clientHeight + 2;
    if (this.snapResolution) {
      width = Math.round(width / this.snapResolution) * this.snapResolution;
      height = Math.round(height / this.snapResolution) * this.snapResolution;
      this.renderer.setStyle(this.element.nativeElement, "width", width + "px");
      this.renderer.setStyle(this.element.nativeElement, "height", height + "px");
    }
    this.resizeEnd.emit({width, height});
  }

  private onResize(event: MouseEvent) {
    if (!this.start) {
      return;
    }
    const newPos = this.domUtilsService.getClientCoords(event);
    const offset = {
      x: this.start.x - (this.directions.indexOf("horizontal") !== -1 ? newPos.x : 0),
      y: this.start.y - (this.directions.indexOf("vertical") !== -1 ? newPos.y : 0)
    };
    this.renderer.setStyle(this.element.nativeElement, "width", this.start.width - offset.x + "px");
    this.renderer.setStyle(this.element.nativeElement, "height", this.start.height - offset.y + "px");
  }
}
