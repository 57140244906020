import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from "@angular/core";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {IonInput} from "@ionic/angular";

@Component({
  selector: "app-search-box",
  templateUrl: "./search-box.component.html",
  styleUrls: ["./search-box.component.scss"],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SearchBoxComponent), multi: true}]
})
export class SearchBoxComponent implements OnInit, ControlValueAccessor {
  @Input() created: (x: SearchBoxComponent) => void;
  @Input() iconName = "search";
  @Input() clearIconName = "close-circle-outline";
  @Input() placeholder: string;
  @Input() autofocus = 0;
  @Input() hasBorder = false;
  @Input() isAnimated = false;
  @Input() iconCssClass = "";
  @Input() cssClass = "";
  @Input() color = "primary";

  @Input() set value(val: string) {
    this.writeValue(val);
  }

  @Output() submit = new EventEmitter<string>();

  @ViewChild("animatedInput") private animatedInput: IonInput;

  formControl = new FormControl();
  onTouched: () => void;
  isDisabled: boolean;
  isInputBoxVisible = false;

  ngOnInit() {
    if (this.created) {
      this.created(this);
    }
  }

  writeValue(value: string) {
    this.formControl.setValue(value);
  }

  registerOnChange(fn: (value: string) => void) {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

  submitSearch() {
    this.submit.emit((this.formControl?.value || "").toString().trim());
  }

  clear() {
    this.formControl.setValue("");
    this.showInputBox(false);
    this.submitSearch();
  }

  showInputBox(isVisible: boolean) {
    this.isInputBoxVisible = isVisible;
    if (isVisible) {
      setTimeout(() => {
        this.animatedInput.getInputElement()
          .then((inputEl: HTMLInputElement) => {
            inputEl.select();
          });
      }, this.autofocus * 4);
    }
  }
}
