<div *ngFor="let _ of rows.controls; let index=index"
     class="row no-gutters">
  <ion-item class="input col-3 pr-1">
    <ion-input #inputId
               type="text"
               [spellcheck]="false"
               [value]="rows.controls[index].value.id"
               (focusout)="onFocusout($event, index, 'id')"
               (keydown)="onKeyDown($event, index, 'id')"
               [readonly]="readOnly">
    </ion-input>
  </ion-item>
  <ion-item class="input col-9 flex-grow-1">
    <ion-input #inputValue
               type="text"
               [spellcheck]="false"
               [value]="rows.controls[index].value.name"
               (focusout)="onFocusout($event, index, 'name')"
               (keydown)="onKeyDown($event, index, 'name')"
               [readonly]="readOnly">
    </ion-input>
    @if (isValidValue(rows.controls[index].value)) {
      <div class="d-flex align-items-center justify-content-end h-100 width-sm">
        <ion-icon name="close-circle-outline"
                  class="cursor-pointer sqr-xs"
                  (click)="clearInput(index)">
        </ion-icon>
      </div>
    }
  </ion-item>
</div>
