import {Paragraph} from "@tiptap/extension-paragraph";

export function getParagraphExt(fn: Function) {
  return Paragraph.extend({
    addKeyboardShortcuts() {
      return {
        "Shift-Space": () => {
          if (fn) {
            fn.apply(null);
          }
          return null;
        },
      };
    }
  });
}
