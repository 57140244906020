import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "@faubulous/ng2-ui-auth";
import {Observable} from "rxjs";
import {IdentityService} from "./identity.service";
import {NavService} from "../nav/nav.service";
import {CookieService} from "ngx-cookie-service";

@Injectable({providedIn: "root"})
export class AuthGuard {
  constructor(private authService: AuthService,
              private identityService: IdentityService,
              private cookieService: CookieService,
              private nav: NavService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated() && ["login", "register"].indexOf(route.routeConfig.path) !== -1) {
      return false;
    }

    const routeClaims = (route.data && route.data.claims) ? route.data.claims : [];
    if (!routeClaims || !routeClaims.length) {
      return true;
    }

    if (!this.authService.isAuthenticated()) {
      this.cookieService.set("actas.ref.redirect", state.url);
      this.nav.go("/auth/login", {queryParams: route.queryParams});
      return false;
    }

    const canAny = this.identityService.canAny(routeClaims);
    if (!canAny) {
      this.nav.go("/access-denied");
    }

    return true;
  }
}
