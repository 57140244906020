import {Component, Input, OnInit} from "@angular/core";
import {Editor, mergeAttributes} from "@tiptap/core";
import {FormControl, FormGroup} from "@angular/forms";
import {SubSink} from "core";
import {FieldDataType} from "dynamic-forms/dynamic-forms.model";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: "app-report-designer-field-settings",
  templateUrl: "./report-designer-field-settings.component.html",
  styleUrls: ["./report-designer-field-settings.component.scss"]
})
export class ReportDesignerFieldSettingsComponent implements OnInit {
  @Input() editor: Editor;

  fieldDataType: FieldDataType;
  casingTypes = ["", "SentenceCase", "lowercase", "UPPERCASE", "snake_case", "kebab-case", "camelCase"];
  trimTypes = ["", "start-end", "start", "end", "duplicated-spaces", "all-spaces"];

  form = new FormGroup({
    fieldFormatDate: new FormControl(),
    fieldFormatNumericDecimals: new FormControl(),
    fieldFormatNumericThousandsSeparator: new FormControl(),
    fieldFormatBoolean: new FormControl(),
    fieldWidth: new FormControl(),
    fieldHeight: new FormControl(),

    fieldTransformCase: new FormControl(),
    fieldTransformTrim: new FormControl()
  });
  sub = new SubSink();

  protected readonly FieldDataType = FieldDataType;

  constructor() {
  }

  ngOnInit() {
    const attrs = this.editor.getAttributes("field");
    console.log(attrs);

    this.fieldDataType = attrs.fieldDataType;
    this.form.setValue({
      fieldFormatDate: attrs.fieldFormatDate || "dd.MM.yyyy",
      fieldFormatNumericDecimals: attrs.fieldFormatNumericDecimals || 0,
      fieldFormatNumericThousandsSeparator: attrs.fieldFormatNumericThousandsSeparator || "",
      fieldFormatBoolean: attrs.fieldFormatBoolean || "",
      fieldWidth: attrs.fieldWidth || null,
      fieldHeight: attrs.fieldHeight || null,

      fieldTransformCase: attrs.fieldTransformCase || "",
      fieldTransformTrim: attrs.fieldTransformTrim || ""
    });
    this.sub.sink = this.form.valueChanges
      .pipe(debounceTime(100))
      .subscribe((x) => {
        this.editor.chain()
          .focus()
          .updateAttributes("field", mergeAttributes(this.editor.getAttributes("field"), x))
          .run();
      });
  }

  fieldFormatNumeric(x: string) {
    return ["", ".", ","].includes(x)
      ? `1${x}000${x}000`
      : "Words";
  }
}
