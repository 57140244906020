import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {DynamicField, FieldDataType, ComplexFieldDataTypesFields, DynamicFieldValue} from "./dynamic-forms.model";

@Injectable({providedIn: "root"})
export class DynamicFormsService {
  constructor(private translateService: TranslateService) {
  }

  denormalizeField(x: DynamicField): Array<DynamicField> {
    const ret = [];
    if (x.fieldType === FieldDataType.FiscalId) {
      ComplexFieldDataTypesFields[FieldDataType.FiscalId].forEach((dt: DynamicField) => {
        const label = dt.fieldId.indexOf(".") === -1
          ? this.translateService.instant(`fiscalInfo.${dt.fieldId}`)
          : this.translateService.instant(dt.fieldId);
        ret.push({
          fieldId: `${x.fieldId}.${dt.fieldId}`,
          fieldName: `${x.fieldName}.${label}`,
          fieldType: dt.fieldType,
          isRequired: dt.isRequired
        } as DynamicField);
      });
    } else if (x.fieldType === FieldDataType.CurrencyExchange) {
      ComplexFieldDataTypesFields[FieldDataType.CurrencyExchange].forEach((dt: DynamicField) => {
        const label = dt.fieldId.indexOf(".") === -1
          ? this.translateService.instant(`currencyExchange.${dt.fieldId}`)
          : this.translateService.instant(dt.fieldId);
        ret.push({
          fieldId: `${x.fieldId}.${dt.fieldId}`,
          fieldName: `${x.fieldName}.${label}`,
          fieldType: dt.fieldType,
          isRequired: dt.isRequired
        } as DynamicField);
      });
    } else if (x.fieldType === FieldDataType.Series) {
      ComplexFieldDataTypesFields[FieldDataType.Series].forEach((dt: DynamicField) => {
        const label = dt.fieldId.indexOf(".") === -1
          ? this.translateService.instant(`series.${dt.fieldId}`)
          : this.translateService.instant(dt.fieldId);
        ret.push({
          fieldId: `${x.fieldId}.${dt.fieldId}`,
          fieldName: `${x.fieldName}.${label}`,
          fieldType: dt.fieldType,
          isRequired: dt.isRequired
        } as DynamicField);
      });
    } else if (x.fieldType === FieldDataType.ExporterRule) {
      ComplexFieldDataTypesFields[FieldDataType.ExporterRule].forEach((dt: DynamicField) => {
        const label = dt.fieldId.indexOf(".") === -1
          ? this.translateService.instant(`series.${dt.fieldId}`)
          : this.translateService.instant(dt.fieldId);
        ret.push({
          fieldId: `${x.fieldId}.${dt.fieldId}`,
          fieldName: `${x.fieldName}.${label}`,
          fieldType: dt.fieldType,
          isRequired: dt.isRequired
        } as DynamicField);
      });
    } else if (x.fieldType === FieldDataType.Document) {
      this.denormalizeFields(x.documentType?.headerFields)
        .forEach((z: DynamicField) => ret.push({
          fieldId: `${x.fieldId}.DocumentData.HeaderData.${z.fieldId}`,
          fieldName: `${x.fieldName}.${z.fieldName}`,
          fieldType: z.fieldType
        } as DynamicField));
      this.denormalizeFields(x.documentType?.detailFields)
        .forEach((z: DynamicField) => ret.push({
          fieldId: `${x.fieldId}.DocumentData.DetailData.${z.fieldId}`,
          fieldName: `${x.fieldName}.${z.fieldName}`,
          fieldType: z.fieldType
        } as DynamicField));
      this.denormalizeFields(x.documentType?.footerFields)
        .forEach((z: DynamicField) => ret.push({
          fieldId: `${x.fieldId}.DocumentData.FooterData.${z.fieldId}`,
          fieldName: `${x.fieldName}.${z.fieldName}`,
          fieldType: z.fieldType
        } as DynamicField));
    } else {
      ret.push({
        fieldId: x.fieldId,
        fieldName: x.fieldName,
        fieldType: x.fieldType
      } as DynamicField);
    }
    return ret;
  }

  denormalizeFields(src: Array<DynamicField>): Array<DynamicField> {
    if (!src) {
      return [];
    }
    return src.reduce((ret: Array<DynamicField>, x: DynamicField) => {
      ret = ret.concat(this.denormalizeField(x));
      return ret;
    }, []);
  }

  flattenObject(obj: any, parent: string, res = {}): any {
    if (typeof obj !== "object" || !obj) {
      return obj;
    }
    if ((obj.fieldId || obj.FieldId) && (obj.value || obj.Value)) {
      const fieldId = obj.fieldId || obj.FieldId;
      const value = obj.value || obj.Value;
      const propName = parent ? parent + "." + fieldId : fieldId;
      if (typeof value === "object") {
        this.flattenObject(value, propName, res);
      } else {
        res[propName] = value;
      }
    } else if (Array.isArray(obj)) {
      for (const o of obj) {
        this.flattenObject(o, parent, res);
      }
    } else {
      for (const key of Object.keys(obj)) {
        const propName = parent ? parent + "." + key : key;
        if (obj[key] && typeof obj[key] === "object") {
          this.flattenObject(obj[key], propName, res);
        } else {
          res[propName] = obj[key];
        }
      }
    }
    return res;
  }

  denormalizeFieldValue(x: DynamicFieldValue): Array<DynamicFieldValue> {
    if (typeof x.value !== "object" || !x.value) {
      return [x];
    }
    const flatten = this.flattenObject(x.value, x.fieldId);
    return Object.keys(flatten).map(k => {
      return {
        fieldType: x.fieldType,
        fieldId: k,
        value: flatten[k],
        error: x.error
      };
    });
  }

  denormalizeFieldValues(src: Array<DynamicFieldValue>): Array<DynamicFieldValue> {
    if (!src) {
      return [];
    }
    return src.reduce((ret: Array<DynamicFieldValue>, x: DynamicFieldValue) => {
      ret = ret.concat(this.denormalizeFieldValue(x));
      return ret;
    }, []);
  }
}
