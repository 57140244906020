export enum MessageType {
  Email = 1,
  WhatsApp = 2
}

export interface AppMessage {
  type: MessageType;
  fromName: string;
  fromAddress: string;
  recipientName: string;
  recipientAddress: string;
  subject: string;
  body: string;
}
