import {FormatPipe} from "ngx-dayjs9";

export enum SortOrder {
  Ascending = "asc",
  Descending = "desc",
}

export type SplitOnType = "year-month-day" | "alphabetic" | "document-status" | "byte-size-kb";

export class Sorting {
  public title?: string;
  public sortBy?: string;
  public sortOrder?: SortOrder;
  public splitOnType?: SplitOnType;

  constructor(title: string, sortBy: string, sortOrder: SortOrder = SortOrder.Descending, splitOnType: SplitOnType = null) {
    this.title = title;
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
    this.splitOnType = splitOnType;
  }

  public static createSplitOn(field: string, type: SplitOnType): (object: any) => string {
    if (type === "year-month-day") {
      return (x: any): string => {
        const d = new Date(Date.parse(x[field]));
        return new FormatPipe().transform(d, "YYYY-MM-DD");
      };
    } else if (type === "alphabetic") {
      return (x: any): string => {
        return (x[field] || "").toString().substring(0, 1).toUpperCase();
      };
    } else if (type === "document-status") {
      return (x: any): string => {
        return `documents.status.${x[field]}`;
      };
    } else if (type === "byte-size-kb") {
      return (x: any): string => {
        const sizeKb = Math.round(parseFloat((x[field] || 0).toString()) / 1024);
        return sizeKb >= 1024 * 10
          ? "> 10Mb"
          : sizeKb >= 1025 * 5
            ? "> 5Mb"
            : sizeKb >= 1024
              ? "> 1Mb"
              : "< 1Mb";
      };
    }
    return null;
  }
}
