import {AfterViewInit, Directive, ElementRef, Input, OnDestroy, RendererFactory2} from "@angular/core";
import {Platform} from "@ionic/angular";
import {DomUtilsService, ScrollAntiBlock} from "core";

@Directive({
  selector: "[appScrollAntiBlock]"
})
export class ScrollAntiBlockDirective implements AfterViewInit, OnDestroy {
  @Input() scrollOffset = 1;
  @Input() debounceTime = 150;
  @Input() applyTop = true;
  @Input() applyBottom = true;

  @Input() set isDisabled(value: boolean) {
    this._isDisabled = value;
    if (value) {
      this.destroy();
    } else {
      this.apply();
    }
  }

  private _isDisabled: boolean;
  private scrollAntiBlock: ScrollAntiBlock;

  constructor(private elementRef: ElementRef,
              private rendererFactory: RendererFactory2,
              private platform: Platform,
              private domUtils: DomUtilsService) {
  }

  ngAfterViewInit() {
    if (!this._isDisabled) {
      this.apply();
    }
  }

  ngOnDestroy() {
    this.destroy();
  }

  private apply() {
    const renderer = this.rendererFactory.createRenderer(null, null);
    this.scrollAntiBlock = new ScrollAntiBlock(this.elementRef.nativeElement, this.scrollOffset, this.debounceTime, this.applyTop, this.applyBottom, renderer, this.platform, this.domUtils);
    this.scrollAntiBlock.apply();
  }

  private destroy() {
    if (this.scrollAntiBlock) {
      this.scrollAntiBlock.destroy();
    }
  }
}
