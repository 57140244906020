<div class="d-inline-flex align-items-center justify-content-center w-100 h-100">
  <svg class="d-block w-100 h-100 p-0 m-0"
       viewBox="0 0 100 100"
       preserveAspectRatio="xMidYMid">
    <g transform="translate(6 94)">
      <circle class="circle-{{color}}" cx="0" cy="0" r="5">
        <animate attributeName="opacity" dur="1s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1"
                 keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.6">
        </animate>
      </circle>
    </g>
  </svg>
</div>
