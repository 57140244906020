import {Component, forwardRef, Input} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor, FormArray, FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import {ArrayService} from "core";
import {ExporterFilterRule} from "./exporter-filter-rule/exporter-filter-rule.component";

export interface OutputFilter {
  isActive: boolean;
  rules: ExporterFilterRule[];
}

@Component({
  selector: "app-exporter-filter",
  templateUrl: "./exporter-filter.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ExporterFilterComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => ExporterFilterComponent), multi: true}]
})
export class ExporterFilterComponent implements ControlValueAccessor, Validator {
  @Input() caption: string;
  @Input() readOnly = false;

  form: FormGroup;
  onTouched: () => void;

  constructor(private arrayService: ArrayService) {
    this.form = new FormGroup({
      "isActive": new FormControl(true),
      "rules": new FormArray([])
    });
  }

  writeValue(value: OutputFilter) {
    const val = this.arrayService.smartAssign({
      isActive: true,
      rules: []
    } as OutputFilter, value) as OutputFilter;
    this.form.get("isActive").setValue(val.isActive);
    this.rules().clear();
    val.rules.forEach(this.addNewRule.bind(this));
  }

  registerOnChange(fn: (value: OutputFilter) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : {invalid: {message: "ExporterFilterRules are invalid"}};
  }

  rules(): FormArray {
    return this.form.controls["rules"] as FormArray;
  }

  addNewRule(rule: ExporterFilterRule = null) {
    this.rules().push(new FormControl(rule || {field: "", ops: []} as ExporterFilterRule));
  }
}
