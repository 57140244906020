@if (!items.length && loadState.isOk) {
  <div>
    <ng-content></ng-content>
  </div>
}
@if (isInsideModal) {
  <div class="h-100" cdkVirtualScrollingElement appScrollAntiBlock>
    <ng-content select="[top]"></ng-content>
    @if (allowContentFilter && items.length && loadState.isOk) {
      <div class="p-1">
        <ion-item class="input">
          <ion-input #filterInput
                     [placeholder]="'dict.search' | translate"
                     [appAutofocus]="500"
                     (keydown.arrowDown)="doContentFilterBlur($event)"
                     (keydown.enter)="doContentFilterApply($event)"
                     (ionInput)="handleContentFilterChange($event)">
          </ion-input>
        </ion-item>
      </div>
    }
    <cdk-virtual-scroll-viewport [itemSize]="itemHeight"
                                 [minBufferPx]="600"
                                 [maxBufferPx]="800"
                                 appSizeChange
                                 (sizeChanged)="sizeChanged($event.width)">
      <app-item-list-row *cdkVirtualFor="let row of rows; trackBy: rowTrackBy"
                         [style.height.px]="itemHeight"
                         [row]="row"
                         [cols]="cols"
                         [itemComponent]="itemComponent"
                         [itemInputs]="dynamicInputs"
                         [isItemSelected]="isItemSelected.bind(this)"
                         [isItemDisabled]="isItemDisabled.bind(this)">
      </app-item-list-row>
    </cdk-virtual-scroll-viewport>

    @if (countCaption && rows?.length) {
      <ion-item class="summary">
        <ion-label class="d-flex align-items-center justify-content-center">
          @if (filterService?.isActive()) {
            <ion-icon name="filter-outline" class="mr-1 sqr-xs"></ion-icon>
          }
          <span [translate]="countCaption" [translateParams]="{v0: items.length}"></span>
        </ion-label>
      </ion-item>
    }
    <ng-content select="[bottom]"></ng-content>
  </div>
} @else {
  @if (allowContentFilter) {
    <div class="p-1">
      <ion-item class="input">
        <ion-input #filterInput
                   [placeholder]="'dict.search' | translate"
                   [appAutofocus]="500"
                   (keydown.arrowDown)="doContentFilterBlur($event)"
                   (keydown.enter)="doContentFilterApply($event)"
                   (ionInput)="handleContentFilterChange($event)">
        </ion-input>
      </ion-item>
    </div>
  }
  <cdk-virtual-scroll-viewport scrollWindow
                               [itemSize]="itemHeight"
                               [minBufferPx]="600"
                               [maxBufferPx]="800"
                               appSizeChange
                               (sizeChanged)="sizeChanged($event.width)">
    <app-item-list-row *cdkVirtualFor="let row of rows; trackBy: rowTrackBy"
                       [style.height.px]="itemHeight"
                       [row]="row"
                       [cols]="cols"
                       [itemComponent]="itemComponent"
                       [itemInputs]="dynamicInputs"
                       [isItemSelected]="isItemSelected.bind(this)"
                       [isItemDisabled]="isItemDisabled.bind(this)">
    </app-item-list-row>
  </cdk-virtual-scroll-viewport>

  @if (countCaption && rows?.length) {
    <ion-item class="summary">
      <ion-label class="d-flex align-items-center justify-content-center">
        @if (filterService?.isActive()) {
          <ion-icon name="filter-outline" class="mr-1 sqr-xs"></ion-icon>
        }
        <span [translate]="countCaption" [translateParams]="{v0: items.length}"></span>
      </ion-label>
    </ion-item>
  }
}
