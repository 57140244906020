<div *ngFor="let hk of hotKeys"
     class="w-100 row no-gutters justify-content-center align-items-center py-1">
  <div class="col-8 d-flex align-items-center">
    @if (hk.icon && !hk.description) {
      <ion-icon [name]="hk.icon"
                class="mr-2">
      </ion-icon>
    }
    <span [translate]="hk.description"></span>
  </div>
  <td class="col-4 kbd">
    <span class="px-1" *ngFor="let k of hk.hotKeys" [innerHTML]="k | hotKey"></span>
  </td>
</div>
