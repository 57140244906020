import {Directive, ElementRef, Input, Output, OnInit, Renderer2, EventEmitter, OnDestroy} from "@angular/core";
import {DomUtilsService, EvSink} from "core";

@Directive({
  selector: "[appInnerSelectable]"
})
export class InnerSelectableDirective implements OnInit, OnDestroy {
  @Input() set appInnerSelectable(value: boolean) {
    if (value) {
      this.mouseDownListener = this.renderer.listen(this.element.nativeElement, "mousedown", this.onSelectStart.bind(this));
    } else {
      if (this.mouseDownListener) {
        this.mouseDownListener();
      }
    }
  }

  @Input() snapResolution = 0;
  @Output() selectEnd = new EventEmitter<{x: number, y: number, width: number, height: number}>();

  private mouseDownListener: Function;
  private evSink = new EvSink();
  private start: {x: number; y: number};
  private selectionMarkerElement: any;

  constructor(private element: ElementRef,
              private renderer: Renderer2,
              private domUtilsService: DomUtilsService) {
  }

  ngOnInit() {
    this.selectionMarkerElement = this.renderer.createElement("div");
    this.renderer.addClass(this.selectionMarkerElement, "position-absolute");
    this.renderer.setStyle(this.selectionMarkerElement, "border", "1px dashed #AAABB8FF");
    this.renderer.setStyle(this.selectionMarkerElement, "left", "-1px");
    this.renderer.setStyle(this.selectionMarkerElement, "top", "-1px");
    this.renderer.appendChild(this.element.nativeElement, this.selectionMarkerElement);
  }

  ngOnDestroy() {
    this.renderer.removeChild(this.element.nativeElement, this.selectionMarkerElement);
    this.evSink.unsubscribe();
    if (this.mouseDownListener) {
      this.mouseDownListener();
    }
  }

  private onSelectStart(event: MouseEvent) {
    this.start = this.domUtilsService.getClientCoords(event, this.element.nativeElement);
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    if (event.preventDefault) {
      event.preventDefault();
    }
    event.cancelBubble = true;
    event.returnValue = false;

    this.evSink.sink = this.renderer.listen(document, "mousemove", this.onMove.bind(this));
    this.evSink.sink = this.renderer.listen(document, "mouseup", this.onSelectEnd.bind(this));
  }

  private onSelectEnd(event: MouseEvent) {
    this.evSink.unsubscribe();
    if (!this.start) {
      return;
    }
    let x = this.selectionMarkerElement.offsetLeft;
    let y = this.selectionMarkerElement.offsetTop;
    let width = this.selectionMarkerElement.clientWidth;
    let height = this.selectionMarkerElement.clientHeight;
    if (this.snapResolution) {
      x = Math.round(x / this.snapResolution) * this.snapResolution;
      y = Math.round(y / this.snapResolution) * this.snapResolution;
      width = Math.round(width / this.snapResolution) * this.snapResolution;
      height = Math.round(height / this.snapResolution) * this.snapResolution;
    }
    this.selectEnd.emit({x, y, width, height});
    this.renderer.setStyle(this.selectionMarkerElement, "left", "-1px");
    this.renderer.setStyle(this.selectionMarkerElement, "top", "-1px");
    this.renderer.setStyle(this.selectionMarkerElement, "width", 0);
    this.renderer.setStyle(this.selectionMarkerElement, "height", 0);
    this.start = null;
  }

  private onMove(event: MouseEvent) {
    console.log("moving");
    if (!this.start) {
      return;
    }
    const newPos = this.domUtilsService.getClientCoords(event, this.element.nativeElement);
    const actualStart = Object.assign({}, this.start);
    if (newPos.x < this.start.x) {
      actualStart.x = newPos.x;
      newPos.x = this.start.x;
    }
    if (newPos.y < this.start.y) {
      actualStart.y = newPos.y;
      newPos.y = this.start.y;
    }
    this.renderer.setStyle(this.selectionMarkerElement, "left", actualStart.x + "px");
    this.renderer.setStyle(this.selectionMarkerElement, "top", actualStart.y + "px");
    this.renderer.setStyle(this.selectionMarkerElement, "width", newPos.x - actualStart.x + "px");
    this.renderer.setStyle(this.selectionMarkerElement, "height", newPos.y - actualStart.y + "px");
  }
}
