import "@tiptap/extension-text-style";

import {Extension} from "@tiptap/core";

export interface FontSizeOptions {
  types: string[];
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (fontSize: number) => ReturnType,
      unsetFontSize: () => ReturnType,
    };
  }
}

export const FontSize = Extension.create<FontSizeOptions>({
  name: "fontSize",

  addOptions(): FontSizeOptions {
    return {
      types: ["textStyle"]
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: element => {
              return element.style.fontSize?.replace(/['"a-z]+/g, "");
            },
            renderHTML: attributes => {
              if (!attributes.fontSize) {
                return {};
              }

              return {
                style: `font-size: ${attributes.fontSize}pt`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize: fontSize => ({chain}) => {
        return chain()
          .setMark("textStyle", {fontSize})
          .run();
      },
      unsetFontSize: () => ({chain}) => {
        return chain()
          .setMark("textStyle", {fontSize: null})
          .removeEmptyTextStyle()
          .run();
      },
    };
  }
});
