import {Injectable} from "@angular/core";
import {ToastController} from "@ionic/angular";
import {TranslateInterpolation} from "core/api/translate-interpolation.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorString} from "core";

export interface ToastOptions {
  animated?: boolean;
  color?: string;
  cssClass?: string;
  duration?: number;
  header?: string;
  message?: string;
  translucent?: boolean;
  position?: "bottom" | "middle" | "top";
  icon?: string;
  buttons?: Array<any>;
}

@Injectable({providedIn: "root"})
export class ToasterService {
  constructor(private translateInterpolation: TranslateInterpolation,
              public toastController: ToastController) {
  }

  private prepareToast(toastMessage: ToastOptions | string | number, options?: ToastOptions): ToastOptions {
    let ret: ToastOptions;
    if (typeof toastMessage === "number") {
      ret = {
        message: toastMessage.toString()
      };
    } else if (typeof toastMessage === "string") {
      ret = {
        message: this.translateInterpolation.translate(toastMessage)
      };
    } else if (toastMessage instanceof HttpErrorResponse) {
      ret = {
        header: `${toastMessage.status} ${toastMessage.statusText}`,
        message: this.translateInterpolation.translate(new ErrorString().transform(toastMessage)),
        icon: "alert-circle"
      };
    } else {
      ret = Object.assign(toastMessage, {
        header: toastMessage.header && this.translateInterpolation.translate(toastMessage.header),
        message: toastMessage.message && this.translateInterpolation.translate(toastMessage.message),
        icon: toastMessage.icon || options?.icon
      });
    }
    return Object.assign(ret, options);
  }

  async show(toastMessage: ToastOptions | string | number, options?: ToastOptions) {
    const toast = await this.toastController.create(this.prepareToast(toastMessage, options));
    await toast.present();
  }

  async default(toastMessage: ToastOptions | string | number, options?: ToastOptions) {
    await this.show(toastMessage, Object.assign({color: "dark", duration: 2000}, options));
  }

  async info(toastMessage: ToastOptions | string | number, options?: ToastOptions) {
    await this.show(toastMessage, Object.assign({color: "info", duration: 2000}, options));
  }

  async success(toastMessage: ToastOptions | string | number, options?: ToastOptions) {
    await this.show(toastMessage, Object.assign({color: "success", duration: 2000}, options));
  }

  async error(toastMessage: ToastOptions | string | number, options?: ToastOptions) {
    await this.show(toastMessage, Object.assign({color: "danger", duration: 2000}, options));
  }

  async warning(toastMessage: ToastOptions | string | number, options?: ToastOptions) {
    await this.show(toastMessage, Object.assign({color: "warning", duration: 2000}, options));
  }
}
