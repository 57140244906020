import {Injectable} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {forkJoin, Observable, Subscriber} from "rxjs";
import {filter} from "rxjs/operators";
import {ImagePicker, ImagePickerOptions, OutputType} from "@ionic-native/image-picker/ngx";
import {Chooser, ChooserResult} from "@ionic-native/chooser/ngx";
import {Camera, CameraSource, CameraResultType, ImageOptions, Photo} from "@capacitor/camera";
import {FileModel, FileService, FileSource, UUidService} from "core";
import {ModalAnyComponent} from "shared";
import {SupportedMimeType} from "documents/documents.model";
import {WeblinkInputComponent} from "./weblink-input/weblink-input.component";

export interface PickedFiles {
  status: "started" | "progress" | "finished";
  percent: number;
  count: number;
  files: Array<FileModel>;
}

@Injectable({providedIn: "root"})
export class FilePickerService {
  constructor(private modalController: ModalController,
              private fileService: FileService,
              private imagePicker: ImagePicker,
              private chooser: Chooser,
              private uuidService: UUidService) {
  }

  pick(fileSource: FileSource, acceptedMimeTypes: Array<SupportedMimeType> = null, isMultiple: boolean = false, maxCount: number = 0, promptCaption: string = null): Observable<PickedFiles> {
    const acceptedMimeTypesString = acceptedMimeTypes
      ? acceptedMimeTypes.map(x => x.mime).join(",")
      : "*";
    if (fileSource === FileSource.FileSys) {
      return this.pickFromDesktopFileSystem(acceptedMimeTypesString, isMultiple, maxCount);
    }
    if (fileSource === FileSource.Camera) {
      return this.takePicture();
    }
    if (fileSource === FileSource.Gallery) {
      return this.pickFromPhotoGallery(maxCount);
    }
    if (fileSource === FileSource.Weblink) {
      return this.pickFromWeblink(acceptedMimeTypes, promptCaption);
    }
    return this.pickFromMobileFileSystem(acceptedMimeTypesString);
  }

  pickFromDesktopFileSystem(acceptedMimeTypes: string = "*", isMultiple: boolean = false, maxCount: number = 0): Observable<PickedFiles> {
    return new Observable((x: Subscriber<PickedFiles>) => {
      const input: HTMLInputElement = document.createElement("input");
      input.type = "file";
      input.style.display = "none";
      input.accept = acceptedMimeTypes || "*";
      input.multiple = isMultiple;
      document.body.appendChild(input);
      input.onchange = ($event: any) => {
        document.body.removeChild(input);
        const selectedFiles: Array<File> = [];
        if ($event.target.files.length > 0) {
          for (let i = 0; i < $event.target.files.length; i++) {
            selectedFiles.push($event.target.files[i]);
          }
        }
        const goodFiles = selectedFiles
          .filter(f => acceptedMimeTypes.toLowerCase().includes(f.type.toLowerCase()));
        if (!goodFiles?.length) {
          return;
        }
        if (maxCount && goodFiles.length > maxCount) {
          x.error();
          x.complete();
          return;
        }
        x.next({status: "started", count: goodFiles.length, percent: 0, files: []});
        const files: Array<File> = [];
        for (let i = 0; i < goodFiles.length; i++) {
          files.push(goodFiles[i]);
        }
        forkJoin(files.map((f: File) => this.fileService.toFileModel(f, FileSource.FileSys)))
          .subscribe((fileModels: Array<FileModel>) => {
            x.next({
              status: "finished",
              count: goodFiles.length,
              percent: 100,
              files: fileModels
            });
            x.complete();
          });
      };
      input.click();
    }).pipe(filter((x: PickedFiles) => !!x?.files?.length || x.status === "started"));
  }

  pickFromMobileFileSystem(acceptedMimeTypes: string): Observable<PickedFiles> {
    return new Observable((x: Subscriber<PickedFiles>) => {
      this.chooser.getFile(acceptedMimeTypes)
        .then((file: ChooserResult) => {
          x.next({
            status: "finished",
            count: 1,
            percent: 100,
            files: [{
              source: FileSource.FileSys,
              name: file.name,
              type: file.mediaType,
              contentUrl: file.dataURI
            }]
          });
          x.complete();
        })
        .catch((err: any) => {
          x.error(err);
          x.complete();
        });
    });
  }

  takePicture(): Observable<PickedFiles> {
    const options: ImageOptions = {
      source: CameraSource.Camera,
      resultType: CameraResultType.DataUrl,
      quality: 20,
      allowEditing: false,
      correctOrientation: true,
      presentationStyle: "fullscreen"
    };
    return new Observable((x: Subscriber<PickedFiles>) => {
      Camera.getPhoto(options).then((cameraPhoto: Photo) => {
        x.next({
          status: "finished",
          count: 1,
          percent: 100,
          files: [{
            source: FileSource.Camera,
            name: "Photo",
            type: `image/${cameraPhoto.format}`,
            contentUrl: cameraPhoto.dataUrl
          }]
        });
        x.complete();
      }).catch(err => {
        x.error(err);
        x.complete();
      });
    });
  }

  pickFromPhotoGallery(maxCount: number): Observable<PickedFiles> {
    const options: ImagePickerOptions = {
      outputType: OutputType.DATA_URL,
      allow_video: false,
      quality: 50,
      maximumImagesCount: maxCount
    };
    return new Observable((x: Subscriber<PickedFiles>) => {
      this.imagePicker.getPictures(options).then((b64s: Array<string>) => {
        const files: Array<FileModel> = b64s.map((b64: string, i: number) => {
          return {
            id: this.uuidService.v4(),
            source: FileSource.Gallery,
            name: `image-${i}`,
            type: "image/jpeg",
            contentUrl: this.fileService.base64ToDataUrl(b64, "image/jpeg")
          };
        });
        x.next({
          status: "finished",
          count: files.length,
          percent: 100,
          files
        });
        x.complete();
      }, (err) => {
        x.error(err);
        x.complete();
      });
    });
  }

  pickFromWeblink(acceptedMimeTypes: Array<SupportedMimeType>, promptCaption: string = "URL"): Observable<PickedFiles> {
    return new Observable((x) => {
      this.modalController.create({
        component: ModalAnyComponent,
        cssClass: "responsive-modal",
        componentProps: {
          title: promptCaption,
          itemComponent: WeblinkInputComponent,
          itemProps: {
            acceptedMimeTypes,
            label: promptCaption
          },
          itemValue: {url: "", fileModel: null},
          submitCaption: "dict.ok",
        }
      }).then((modal: HTMLIonModalElement) => {
        modal.present().then(() => {
          modal.onDidDismiss().then(async (value: {data: {url: string, fileModel: FileModel}, role: any}) => {
            if (!value.data) {
              return x.complete();
            }
            x.next({
              status: "finished",
              count: 1,
              percent: 100,
              files: [value.data.fileModel]
            });
          });
        });
      });
    });
  }
}
