import {Component, EventEmitter, forwardRef, Input, Output} from "@angular/core";
import {PopoverController} from "@ionic/angular";
import {AbstractControl, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators} from "@angular/forms";
import {DateInterval} from "core";
import {ColorEvent} from "ngx-color";

@Component({
  selector: "app-color-picker",
  templateUrl: "./color-picker.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ColorPickerComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => ColorPickerComponent), multi: true}]
})
export class ColorPickerComponent {
  @Input() cssClass: string;
  @Input() clearColor = "#000000";
  @Output() submit = new EventEmitter<string>();

  formControl: FormControl;
  onTouched: () => void;

  constructor(private popoverController: PopoverController) {
    this.formControl = new FormControl("#000000", [Validators.required]);
  }

  async colorPicked() {
    this.submit.emit(this.formControl.value);
    await this.popoverController.dismiss(this.formControl.value);
  }

  async resetColor() {
    this.formControl.setValue(this.clearColor);
    await this.colorPicked();
  }

  writeValue(value: string | Array<string>) {
    if (Array.isArray(value)) {
      this.formControl.setValue(value[0]);
    } else {
      this.formControl.setValue(value);
    }
  }

  registerOnChange(fn: (value: DateInterval) => void) {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.formControl.valid ? null : {invalid: {message: "Invalid color"}};
  }

  handleColorChange($event: ColorEvent) {
    this.formControl.setValue($event.color.hex);
  }
}

