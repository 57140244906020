import {Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: "app-password-input",
  templateUrl: "./password-input.component.html",
  styleUrls: ["./password-input.component.scss"],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PasswordInputComponent), multi: true}]
})
export class PasswordInputComponent implements ControlValueAccessor {
  @Input() placeholder = "";
  @Input() name = "";
  @Input() enterkeyhint = "done";

  formControl = new FormControl();
  viewType = "password";

  onTouched: () => void;

  writeValue(value: string) {
    this.formControl.setValue(value);
  }

  registerOnChange(fn: (value: string) => void) {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

  // validate(c: AbstractControl): ValidationErrors | null {
  //   return this.formControl.valid ? null : {invalidDocumentType: {message: "Document type item is invalid"}};
  // }

  switchView() {
    if (this.viewType === "password") {
      this.viewType = "text";
    } else {
      this.viewType = "password";
    }
  }
}
