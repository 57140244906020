import {Component, Input, OnInit, forwardRef} from "@angular/core";
import {YearMonthPickerOptions} from "./year-month.model";
import {NGXMonthYear} from "./year-month";
import {DateInterval} from "core";
import {
  AbstractControl,
  ControlValueAccessor, FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator, Validators
} from "@angular/forms";

@Component({
  selector: "app-year-month-picker",
  templateUrl: "./year-month-picker.component.html",
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => YearMonthPickerComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => YearMonthPickerComponent), multi: true}]
})
export class YearMonthPickerComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() options: YearMonthPickerOptions;
  @Input() created: (x: YearMonthPickerComponent) => void;

  yearFormControl: FormControl;
  formControl: FormControl;
  onTouched: () => void;

  ngxMonthYear = new NGXMonthYear();

  constructor() {
    this.yearFormControl = new FormControl(new Date().getFullYear());
    this.formControl = new FormControl({dateFrom: null, dateTo: null}, [Validators.required]);
  }

  ngOnInit() {
    this.options = this.ngxMonthYear.setDefaultOptions(this.options ? this.options : {});
    if (this.created) {
      this.created(this);
    }
  }

  writeValue(value: DateInterval) {
    const newValue: DateInterval = Object.assign({dateFrom: null, dateTo: null}, value);
    this.formControl.setValue(newValue);
    const dateForYear = newValue.dateFrom || new Date();
    this.yearFormControl.setValue(dateForYear.getFullYear());
  }

  registerOnChange(fn: (value: DateInterval) => void) {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.formControl.valid ? null : {invalid: {message: "Invalid date interval"}};
  }
}
