import {Component, Input, forwardRef, OnDestroy} from "@angular/core";
import {YearMonthPickerOptions, monthAbbrev} from "./year-month.model";
import {DateInterval, SubSink} from "core";
import {NGXMonthYear} from "shared/year-month-picker/year-month";
import {EndOfPipe} from "ngx-dayjs9";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl, FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator
} from "@angular/forms";

@Component({
  selector: "app-month-picker",
  templateUrl: "./month-picker.component.html",
  styleUrls: ["./month-picker.component.scss"],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MonthPickerComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => MonthPickerComponent), multi: true}]
})
export class MonthPickerComponent implements OnDestroy, ControlValueAccessor, Validator {
  @Input() options: YearMonthPickerOptions;
  @Input() year: number;

  private sub = new SubSink();
  monthNames = monthAbbrev;
  form: FormGroup;
  onTouched: () => void;
  monthsInfo: {
    [key: number]: {
      isCurrent: boolean,
      isDisabled: boolean,
      isStartInterval: boolean,
      isEndInterval: boolean,
      isInsideInterval: boolean
    }
  } = {};

  private ngxMonthYear = new NGXMonthYear();

  constructor() {
    this.form = new FormGroup({
      "dateFrom": new FormControl(new Date()),
      "dateTo": new FormControl(new Date()),
    });
    this.sub.sink = this.form.valueChanges.subscribe(this.computeMonthsInfo.bind(this));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  writeValue(value: DateInterval) {
    const startMonthDate = new Date(this.year, new Date().getMonth(), 1);
    this.form.setValue(Object.assign({
      dateFrom: startMonthDate,
      dateTo: new EndOfPipe().transform(startMonthDate).endOf("month").toDate()
    }, value));
  }

  registerOnChange(fn: (value: DateInterval) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : {invalid: {message: "Invalid month interval"}};
  }

  clickMonth(monthIdx: number) {
    if (this.isMonthDisabledState(monthIdx)) {
      return;
    }
    const startMonthDate = new Date(this.year, monthIdx, 1);
    const endMonthDate = new EndOfPipe().transform(startMonthDate).endOf("month").toDate();

    if (!this.form.value) {
      this.form.setValue({
        dateFrom: startMonthDate,
        dateTo: endMonthDate
      });
    } else {
      if (!this.form.get("dateFrom")?.value) {
        this.form.get("dateFrom").setValue(startMonthDate);
      }
      if (!this.form.get("dateTo")?.value) {
        this.form.get("dateTo").setValue(endMonthDate);
      }
      const isBeforeSelectionStart = !this.form.get("dateFrom")?.value || this.ngxMonthYear.compare(startMonthDate, this.form.get("dateFrom")?.value) < 0;
      const isOnEnd = this.ngxMonthYear.compare(endMonthDate, this.form.get("dateTo")?.value) === 0;

      if (isBeforeSelectionStart) {
        this.form.get("dateFrom").setValue(startMonthDate);
      } else {
        if (isOnEnd) {
          this.form.get("dateFrom").setValue(startMonthDate);
          this.form.get("dateTo").setValue(endMonthDate);
        } else {
          this.form.get("dateTo").setValue(endMonthDate);
        }
      }
    }
  }

  private computeMonthsInfo(interval: DateInterval) {
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach(m => {
      this.monthsInfo[m] = {
        isCurrent: this.isMonthCurrent(m),
        isDisabled: this.isMonthDisabledState(m),
        isStartInterval: this.isMonthStartInterval(interval, m),
        isEndInterval: this.isMonthEndInterval(interval, m),
        isInsideInterval: this.isMonthInsideInterval(interval, m)
      };
    });
  }

  private isMonthStartInterval(interval: DateInterval, monthIdx: number): boolean {
    return interval?.dateFrom?.getFullYear() === this.year && interval?.dateFrom?.getMonth() === monthIdx;
  }

  private isMonthEndInterval(interval: DateInterval, monthIdx: number): boolean {
    return interval?.dateTo?.getFullYear() === this.year && interval?.dateTo?.getMonth() === monthIdx;
  }

  private isMonthInsideInterval(interval: DateInterval, monthIdx: number) {
    const dx = new Date(this.year, monthIdx, 1);
    return interval?.dateFrom && interval?.dateTo
      && this.ngxMonthYear.compare(interval?.dateFrom, dx) < 0
      && this.ngxMonthYear.compare(dx, interval?.dateTo) < 0;
  }

  private isMonthCurrent(monthIdx: number) {
    const now = new Date();
    return now.getFullYear() === this.year && now.getMonth() === monthIdx;
  }

  private isMonthDisabledState(monthIdx: number): boolean {
    const disabledRanges = this.options.disabledDateRanges && this.options.disabledDateRanges.filter((range) => {
      const startDate = new Date(range[0].getFullYear(), range[0].getMonth(), 1);
      const endDate = new Date(range[1].getFullYear(), range[1].getMonth(), 1);
      const viewDate = new Date(this.year, monthIdx, 1);
      return viewDate >= startDate && viewDate <= endDate;
    }).length > 0;
    const disabledIndividual = this.options.disabledDates && this.options.disabledDates.filter(date => date.getFullYear() === this.year && date.getMonth() === monthIdx).length > 0;
    return (disabledRanges || disabledIndividual);
  }
}
