import {Injectable} from "@angular/core";
import {AuthService} from "@faubulous/ng2-ui-auth";
import {Observable, of} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {StorageMap} from "@ngx-pwa/local-storage";
import {BrowserStorageService} from "../browser-storage/browser-storage.service";
import {IndexedDbStorage} from "../browser-storage/indexeddb-storage";

export class IdentityUser {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
  fullName?: string;
  initials?: string;
  photoUrl?: string;
  language?: string;
  claims?: Array<{[key: string]: string}>;
}

@Injectable({providedIn: "root"})
export class IdentityService {
  constructor(private auth: AuthService,
              private browserStorageService: BrowserStorageService,
              private storage: StorageMap) {
  }

  setUser(user: IdentityUser): Observable<any> {
    return this.browserStorageService.volatile.store("user", user);
  }

  getUser(): Observable<IdentityUser> {
    if (!this.auth.isAuthenticated()) {
      return of(null);
    }
    return this.browserStorageService.volatile.get("user");
  }

  observeUser(): Observable<IdentityUser> {
    if (!this.auth.isAuthenticated()) {
      return of(null);
    }
    return this.browserStorageService.volatile.observe("user");
  }

  updateUser(patch: any): Observable<any> {
    if (!this.auth.isAuthenticated()) {
      return of(null);
    }
    return this.browserStorageService.volatile.get("user")
      .pipe(mergeMap((user: IdentityUser) => this.setUser(Object.assign(user, patch))));
  }

  get settingsIdb(): Observable<IndexedDbStorage> {
    return new Observable<IndexedDbStorage>(x => {
      this.getUser().subscribe((user: IdentityUser) => {
        if (!user) {
          x.next(null);
          x.complete();
          return;
        }
        x.next(new IndexedDbStorage(`settings.${user.email}`, this.storage));
        x.complete();
      });
    });
  }

  setSetting(setting: string, value: any): Observable<any> {
    return this.settingsIdb.pipe(mergeMap((idb: IndexedDbStorage) => idb.store(setting, value)));
  }

  getSetting(setting: string): Observable<any> {
    return this.settingsIdb.pipe(mergeMap((idb: IndexedDbStorage) => idb.get(setting)));
  }

  observeSetting(setting: string): Observable<any> {
    return this.settingsIdb.pipe(mergeMap((idb: IndexedDbStorage) => idb.observe(setting)));
  }

  clearSetting(setting: string): Observable<any> {
    console.log("clear setting", setting);
    return this.settingsIdb.pipe(mergeMap((idb: IndexedDbStorage) => idb.clear(setting)));
  }

  clearSettings(): Observable<any> {
    console.log("clear settings");
    return this.settingsIdb.pipe(mergeMap((idb: IndexedDbStorage) => idb.clear()));
  }

  canAny(claims: Array<object>): Observable<boolean> {
    if (!claims) {
      return of(true);
    }
    return new Observable<boolean>(x => {
      this.getUser().subscribe((user: IdentityUser) => {
        if (!user || !user.claims) {
          x.next(false);
          x.complete();
          return;
        }
        for (let i = 0; i < claims.length; i++) {
          const claim = claims[i];
          const keys = Object.keys(claim);
          for (let k = 0; k < keys.length; k++) {
            const claimName = keys[k];
            if (user.claims[claimName]?.toString() === claim[claimName].toString()) {
              x.next(true);
              x.complete();
              return;
            }
          }
        }
        x.next(false);
        x.complete();
      });
    });
  }

  canAll(claims: Array<object>): Observable<boolean> {
    if (!claims) {
      return of(true);
    }
    return new Observable<boolean>(x => {
      this.getUser().subscribe((user: IdentityUser) => {
        if (!user.claims) {
          x.next(false);
          x.complete();
          return;
        }
        for (let i = 0; i < claims.length; i++) {
          const claim = claims[i];
          const keys = Object.keys(claim);
          for (let k = 0; k < keys.length; k++) {
            const claimName = keys[k];
            if (user.claims[claimName] !== claim[claimName]) {
              x.next(false);
              x.complete();
              return;
            }
          }
        }
        x.next(true);
        x.complete();
        return;
      });
    });
  }
}
