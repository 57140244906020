import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, Renderer2
} from "@angular/core";
import {Platform} from "@ionic/angular";
import {
  AppDomUtilsService,
  AppEventsService, DomUtilsService,
  LoadState,
  LoadStates,
  NavService,
  ScrollAntiBlock,
  SubSink,
  TourExtService
} from "core";
import {ActionBarItem, ActionBarItemPos, ActionBarItemType, ActionBarType} from "../action-bar";
import {filter} from "rxjs/operators";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"]
})
export class PageComponent implements OnInit, OnDestroy {
  @Input() loadState = new LoadState();
  @Input() pageTitle: string;
  @Input() tourId: string;
  private _actions: Array<ActionBarItem> = [];
  @Input() set actions(actions: Array<ActionBarItem>) {
    this.isActionsReady = false;
    this._actions = actions;
    this.prepareToolbarActions();
    this.isActionsReady = true;
  }

  @Input() actionsToolbarColor = "white";
  @Input() bCrumbs: Array<ActionBarItem> = [];
  @Output() titleClick = new EventEmitter<any>();

  refreshActionBarItem: ActionBarItem;
  searchActionBarItem: ActionBarItem;
  infiniteScrollActionBarItem: ActionBarItem;
  closeActionBarItem: ActionBarItem;
  desktopActions: Array<ActionBarItem>;
  mobileNormalActions: Array<ActionBarItem>;
  mobileSmartBottomActions: Array<ActionBarItem>;
  isActionsReady = false;
  isPullToRefresh = false;
  scrollAntiBlock: ScrollAntiBlock;
  ActionBarType = ActionBarType;

  isDesktop = this.platform.width() >= 768;
  private sub = new SubSink();

  constructor(private nav: NavService,
              private platform: Platform,
              private renderer: Renderer2,
              private appEventsService: AppEventsService,
              private appDomUtilsService: AppDomUtilsService,
              private domUtilsService: DomUtilsService,
              private tourExtService: TourExtService) {
  }

  ngOnInit() {
    if (this.loadState.isNull) {
      this.loadState.setOk();
    } else {
      this.loadState.setInit();
    }
    this.appEventsService.notifyShowAppTitle(null);
    if (!this.isDesktop) {
      this.initScrollAntiBlock();
    }
    this.tourExtService.init("main");
    this.tourExtService.end$
      .pipe(filter(endedTourId => endedTourId === "main"))
      .subscribe((endedTourId: string) => this.tourExtService.init(this.tourId, 0));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.scrollAntiBlock) {
      this.scrollAntiBlock.destroy();
    }
    this.tourExtService.destroy();
  }

  stickTitle(ev: {elementRef: ElementRef, isVisible: boolean}) {
    ev.elementRef.nativeElement.classList.toggle("opacity-0", !ev.isVisible);
    this.appEventsService.notifyShowAppTitle(!ev.isVisible ? this.pageTitle : null);
  }

  stickActions(ev: {elementRef: ElementRef, isVisible: boolean}) {
    this.appDomUtilsService.stickElement(ev.elementRef.nativeElement, !ev.isVisible);
  }

  stickBreadcrumbs(ev: {elementRef: ElementRef, isVisible: boolean}) {
    ev.elementRef.nativeElement.classList.toggle("opacity-0", !ev.isVisible);
    this.appEventsService.notifyShowBreadcrumbs(!ev.isVisible ? this.bCrumbs : []);
  }

  private prepareToolbarActions() {
    if (!this._actions) {
      return;
    }

    this.refreshActionBarItem = !this.isDesktop && this._actions.find(a => a.type === ActionBarItemType.Refresh);
    this.searchActionBarItem = this._actions.find(a => a.type === ActionBarItemType.Search);
    this.infiniteScrollActionBarItem = this._actions.find(a => a.type === ActionBarItemType.InfiniteScroll);
    this.closeActionBarItem = this._actions.find(a => a.type === ActionBarItemType.Close);

    this.mobileNormalActions = this._actions.filter((a: ActionBarItem, idx: number) =>
      (a.type !== ActionBarItemType.Label || idx === 0)
      && a.type !== ActionBarItemType.Close
      && a.type !== ActionBarItemType.InfiniteScroll
      && a.type !== ActionBarItemType.Separator
      && a.type !== ActionBarItemType.Search
      && a.pos !== ActionBarItemPos.SmartBottomMobile);

    this.mobileSmartBottomActions = !this.isDesktop && this._actions.filter((a: ActionBarItem) =>
      a.type !== ActionBarItemType.Refresh
      && a.type !== ActionBarItemType.Close
      && a.type !== ActionBarItemType.Separator
      && a.type !== ActionBarItemType.InfiniteScroll
      && a.pos === ActionBarItemPos.SmartBottomMobile);

    this.desktopActions = this._actions.filter(a => a.type !== ActionBarItemType.Close);
    if (this.scrollAntiBlock) {
      this.scrollAntiBlock.setAppliance(!this.refreshActionBarItem, true);
    }
  }

  private initScrollAntiBlock() {
    setTimeout(() => {
      const scrollable = AppDomUtilsService.getScrollableEl();
      if (!scrollable) {
        return this.initScrollAntiBlock();
      }
      this.scrollAntiBlock = new ScrollAntiBlock(scrollable, 1, 150, !this.refreshActionBarItem, true, this.renderer, this.platform, this.domUtilsService);
      this.scrollAntiBlock.apply();
    }, 500);
  }

  handleRefresh($event: any) {
    this.isPullToRefresh = true;
    this.sub.sink = this.refreshActionBarItem.handler.apply(null, [this.refreshActionBarItem, $event])
      .subscribe(() => {
        setTimeout(() => $event.target.complete());
        this.loadState.state = LoadStates.Ok;
        this.isActionsReady = true;
        setTimeout(() => {
          $event.target.complete();
          this.isPullToRefresh = false;
        }, 500);
      }, (err) => {
        this.loadState.setError(err);
      });
  }

  handleInfiniteScroll($event: any) {
    this.loadState.state = LoadStates.ScrollForMore;
    this.sub.sink = this.infiniteScrollActionBarItem.handler.apply(null, [this.infiniteScrollActionBarItem, $event])
      .subscribe((isAllLoaded: boolean) => {
        this.loadState.state = LoadStates.Ok;
        $event.target.complete();
        if (isAllLoaded) {
          $event.target.disabled = true;
          setTimeout(() => $event.target.disabled = false, 500);
        }
        this.isActionsReady = true;
      }, (err) => {
        this.loadState.setError(err);
      });
  }

  handleSearch($event: any) {
    this.searchActionBarItem.handler.apply(null, [this.searchActionBarItem, $event.detail.value]);
  }

  handleClose($event: any) {
    if (!this.closeActionBarItem.handler) {
      if (this.closeActionBarItem.routerLink) {
        return this.nav.go(this.closeActionBarItem.routerLink);
      }
      return this.nav.back();
    }
    this.closeActionBarItem.handler.apply(null, [this.closeActionBarItem, $event]);
  }

  handleHelp() {
    this.tourExtService.setStatus(this.tourId, "start")
      .subscribe(() => this.tourExtService.restart("main"));
  }
}
