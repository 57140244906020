import {Observable} from "rxjs";

const rotation = {
  1: "rotate(0deg)",
  3: "rotate(180deg)",
  6: "rotate(90deg)",
  8: "rotate(270deg)"
};

export class ImageProcessor {
  private image = new Image();
  private imageArrayBuffer: ArrayBuffer;

  loadImage(imageUrl: string, arrayBuffer: ArrayBuffer = null): Observable<HTMLImageElement> {
    this.imageArrayBuffer = arrayBuffer;
    return new Observable(o => {
      this.image.onload = () => {
        o.next(this.image);
        o.complete();
      };
      this.image.src = imageUrl;
      this.image.crossOrigin = "anonymous";
    });
  }

  getOrientation(): number {
    if (!this.imageArrayBuffer) {
      return 1;
    }
    if (this.imageArrayBuffer.byteLength < 2) {
      return 1;
    }
    const scanner = new DataView(this.imageArrayBuffer);
    if (scanner.getUint16(0) !== 0xFFD8) {
      return 1;
    }
    let idx = 2;
    let maxBytes = scanner.byteLength;
    while (idx < maxBytes - 2) {
      const uint16 = scanner.getUint16(idx);
      idx += 2;
      switch (uint16) {
        case 0xFFE1: // Start of EXIF
          const exifLength = scanner.getUint16(idx);
          maxBytes = exifLength - idx;
          idx += 2;
          break;
        case 0x0112: // Orientation tag
          // Read the value, its 6 bytes further out
          // See page 102 at http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
          return scanner.getUint16(idx + 6);
      }
    }
    return 1;
  }

  fixOrientation(): Observable<HTMLImageElement> {
    const orientation = this.getOrientation();

    if (orientation === 1) {
      return new Observable(o => {
        o.next(this.image);
        o.complete();
      });
    }

    const width = this.image.width;
    const height = this.image.height;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (4 < orientation && orientation < 9) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    switch (orientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, height, width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width);
        break;
      default:
        break;
    }
    ctx.drawImage(this.image, 0, 0);
    const fixedBase64Image = canvas.toDataURL();

    return this.loadImage(fixedBase64Image);
  }

  rotate(): Observable<HTMLImageElement> {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.setAttribute("width", this.image.height.toString());
    canvas.setAttribute("height", this.image.width.toString());

    ctx.rotate(90 * Math.PI / 180);
    ctx.drawImage(this.image, 0, -this.image.height);
    const base64Image = canvas.toDataURL();
    return this.loadImage(base64Image);
  }

  toFile(name: string): File {
    const blob = this.toBlob();
    return Object.assign(blob, {
      name,
      lastModifiedDate: new Date()
    });
  }

  toBlob(): any {
    const dataURI = this.image.src;
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1]);
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }

    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type: mimeString});
  }

  imageSrc(): string {
    return this.image.src;
  }
}
