import {AfterViewInit, ComponentRef, Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, Renderer2, ViewContainerRef} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {EventManager} from "@angular/platform-browser";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import _ from "lodash";
import {ActionBarItem, TypeAheadOptionsComponent} from "shared";
import {DomUtilsService, EvtUtilsService} from "core";

@Directive({
  selector: "[appTypeAhead]"
})
export class TypeAheadDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() dataLoader$: (search: string) => Observable<Array<{caption: string, value: any}>>;
  @Input() taIsActive: boolean;
  @Input() taAllowNonListed: boolean;
  @Output() taSelected = new EventEmitter<{input: string, value: any, isValid: boolean}>();

  private changeHandler: Function;
  private componentRef: ComponentRef<TypeAheadOptionsComponent>;
  private isCanceled = false;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private eventManager: EventManager,
              private viewContainerRef: ViewContainerRef,
              private evtUtilsService: EvtUtilsService,
              private domUtilsService: DomUtilsService,
              @Inject(DOCUMENT) private document: any) {
  }

  @HostListener("keydown", ["$event"])
  async onkeydown($event: KeyboardEvent | any) {
    if (!this.taIsActive) {
      return;
    }
    this.isCanceled = false;
    if (!this.componentRef) {
      return;
    }
    $event.hotKey = $event.hotKey || $event.code;
    const hotKey = $event.hotKey.toLowerCase();
    if (["arrowup", "arrowdown"].includes(hotKey)) {
      this.evtUtilsService.cancelEvt($event);
      await this.componentRef.instance.handleHotkey($event);
    } else if (["enter"].includes(hotKey)) {
      this.evtUtilsService.cancelEvt($event);
      if (this.componentRef.instance.hasActions()) {
        await this.componentRef.instance.handleHotkey($event);
      } else {
        this.destroyOptionsComponent();
        const input = this.el.nativeElement.value;
        this.taSelected.emit({input, value: null, isValid: this.taAllowNonListed});
      }
    } else if (["escape"].includes(hotKey)) {
      this.isCanceled = true;
      this.evtUtilsService.cancelEvt($event);
      this.destroyOptionsComponent();
    }
  }

  ngOnInit() {
    if (!this.taIsActive) {
      return;
    }
    this.changeHandler = this.renderer.listen(this.el.nativeElement, "ionInput", _.debounce(this.handleChange.bind(this), 800).bind(this));
  }

  ngAfterViewInit() {
    this.validateInput();
  }

  ngOnDestroy() {
    if (this.changeHandler) {
      this.changeHandler();
    }
    this.destroyOptionsComponent();
  }

  private createOptionsComponent() {
    if (this.componentRef) {
      return;
    }
    this.componentRef = this.viewContainerRef.createComponent(TypeAheadOptionsComponent);
    this.componentRef.location.nativeElement.setAttribute("class", "animated faster fadeIn");
    this.document.body.appendChild(this.componentRef.location.nativeElement);

    const scrollHandler = this.eventManager.addEventListener(this.document as any, "wheel", ($event: any) => {
      this.positionOptionComponent();
    });

    const clickOutsideHandler = this.eventManager.addEventListener(this.document as any, "mousedown", ($event: any) => {
      if ($event.target.name === this.el.nativeElement.name) {
        return;
      }
      clickOutsideHandler();
      scrollHandler();
      if (this.domUtilsService.findAncestorWithClass($event.target, "type-ahead-options")) {
        return;
      }
      this.validateInput();
      this.destroyOptionsComponent();
    });
  }

  private positionOptionComponent() {
    const inputCoords = this.el.nativeElement.getBoundingClientRect();
    const optionsCoords = this.componentRef.location.nativeElement.getBoundingClientRect();
    const left = inputCoords.x;
    const isFit = inputCoords.y + inputCoords.height + optionsCoords.height < window.innerHeight;
    const top = isFit
      ? inputCoords.y + inputCoords.height
      : inputCoords.y;
    const transform = isFit
      ? "none"
      : "translateY(-100%)";
    const display = this.componentRef.instance.hasActions()
      ? "block"
      : "none";
    this.componentRef.location.nativeElement.setAttribute("style", `top: ${top}px; left: ${left}px; transform: ${transform}; display: ${display}`);
  }

  private destroyOptionsComponent() {
    if (!this.componentRef) {
      return;
    }
    this.componentRef.destroy();
    this.componentRef = null;
  }

  private handleChange($event: any) {
    if (!this.dataLoader$) {
      return;
    }
    const search = this.el.nativeElement.value;
    this.dataLoader$.apply(null, [search])
      .pipe(map((rows: Array<{caption: string, value: any}>) => {
        return rows.map(row => {
          return {
            title: row.caption,
            actionValue: row.value,
            handler: (actionBarItem: ActionBarItem) => {
              this.taSelected.emit({input: row.caption, value: row.value, isValid: true});
              this.destroyOptionsComponent();
            }
          };
        });
      }))
      .subscribe((x: Array<ActionBarItem>) => {
        if (this.isCanceled) {
          return;
        }
        this.createOptionsComponent();
        this.componentRef.setInput("actions", x);
        setTimeout(() => this.positionOptionComponent());
      });
  }

  private validateInput() {
    const input = this.el.nativeElement.value;
    if (this.taAllowNonListed) {
      this.taSelected.emit({
        input: input,
        value: null,
        isValid: true
      });
      return;
    }

    if (!input) {
      this.taSelected.emit({
        input: input,
        value: null,
        isValid: false
      });
      return;
    }
    const inputString = input.toString().toLowerCase().trim();
    this.dataLoader$.apply(null, [input])
      .subscribe((rows: Array<{caption: string, value: any}>) => {
        const row = rows.find(r => r.caption.toLowerCase().trim() === inputString || r.value.toString().toLowerCase().trim() === inputString);
        this.taSelected.emit({
          input: row?.caption || input,
          value: row?.value,
          isValid: !!row
        });
      });
  }
}
