import {Observable, of} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {DateInterval, SplitOnType} from "core";
import {BrowserStorageService} from "./browser-storage.service";
import {Sorting} from "../api/sorting";

export class ObservableFilteringService<T> {
  initialFilter: T;
  filter: T;

  constructor(private browserStorageService: BrowserStorageService,
              private key: string,
              initialFilter: T) {
    this.initialFilter = Object.assign({}, initialFilter);
  }

  get(): Observable<T> {
    return this.browserStorageService.volatile.get(this.key);
  }

  set(filter: T): Observable<T> {
    return this.get()
      .pipe(mergeMap((savedFilter: T) => {
        this.filter = Object.assign({}, savedFilter || {}, filter);
        return this.store();
      }));
  }

  isInitialized(): boolean {
    return !!this.filter;
  }

  init(): Observable<T> {
    return this.get()
      .pipe(mergeMap((savedFilter: T) => {
        if (!savedFilter) {
          this.filter = this.initialFilter;
          return this.store();
        } else {
          this.filter = Object.assign({}, this.initialFilter, savedFilter);
          return of(this.filter);
        }
      }));
  }

  getFilterValue(key: string): any {
    return this.filter ? this.filter[key] : null;
  }

  getFilterDateInterval(): DateInterval {
    const dateInterval: DateInterval = this.getFilterValue("dateInterval");
    return {
      dateFrom: dateInterval?.dateFrom && new Date(dateInterval.dateFrom) || null,
      dateTo: dateInterval?.dateTo && new Date(dateInterval.dateTo) || null
    };
  }

  store(): Observable<T> {
    return this.browserStorageService.volatile.store(this.key, this.filter)
      .pipe(mergeMap(() => of(this.filter)));
  }

  reset(): Observable<T> {
    this.filter = Object.assign({}, this.initialFilter);
    return this.store();
  }

  observe(): Observable<T> {
    return this.browserStorageService.volatile.observe(this.key);
  }

  clear(): Observable<any> {
    return this.browserStorageService.volatile.clear(this.key);
  }

  isActive(): boolean {
    return JSON.stringify(this.initialFilter) !== JSON.stringify(this.filter);
  }

  isEmpty(): boolean {
    return JSON.stringify(this.filter) === JSON.stringify({});
  }

  addCriteria(property: string, value: any): Observable<T> {
    (this.filter as any)[property] = value;
    return this.store();
  }

  sortBy(sorting: Sorting | Array<Sorting>): Observable<T> {
    return this.addCriteria("sorting", sorting);
  }

  filterBySearch(search: string): Observable<T> {
    return this.addCriteria("search", search);
  }

  getSplitOnFn(): (x: any) => string {
    const sorting = (this.filter as any)?.sorting;
    if (!sorting) {
      return null;
    }
    const splitOnType: SplitOnType = sorting[0]?.splitOnType;
    const sortBy: SplitOnType = sorting[0]?.sortBy;
    if (!splitOnType || !sortBy) {
      return null;
    }
    return Sorting.createSplitOn(sortBy, splitOnType);
  }
}
