import {Component, OnInit} from "@angular/core";
import {SwUpdate} from "@angular/service-worker";
import {Platform} from "@ionic/angular";
import {environment} from "environment";
import {WindowService} from "core";

@Component({
  selector: "app-app-info",
  templateUrl: "./app-info.component.html"
})
export class AppInfoComponent implements OnInit {
  appVersion = environment.version;
  isUpdateAvailable = false;
  platforms: string;

  constructor(private swUpdate: SwUpdate,
              private platform: Platform,
              private windowService: WindowService) {
  }

  ngOnInit() {
    this.platforms = this.platform.platforms().join(", ");
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate()
        .then((isUpdateAvailable: boolean) => {
          this.isUpdateAvailable = isUpdateAvailable;
        });
    }
  }

  async reloadApp() {
    await fetch(this.windowService.getBaseUrl(), {
      headers: {
        Pragma: "no-cache",
        Expires: "-1",
        "Cache-Control": "no-cache",
      },
    });
    window.location.reload();
  }
}
