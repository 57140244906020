import {Directive, EventEmitter, HostListener, Input, Output} from "@angular/core";

export interface Coordinates {
  x: number;
  y: number;
}

export interface TouchSwipePayload {
  x: number;
  y: number;
  direction: "left" | "right";
}

@Directive({
  selector: "[appTouchSwipe]"
})
export class TouchSwipeDirective {
  @Input() x = 0;
  @Input() y = 0;
  @Output() touchPan = new EventEmitter<TouchSwipePayload>();
  @Output() touchSwipe = new EventEmitter<TouchSwipePayload>();
  @Output() touchSwipeAbort = new EventEmitter<TouchSwipePayload>();

  private initialCoordinates?: Coordinates;
  private vPan: boolean;
  private hPan: boolean;
  private isOn: boolean;

  private getCoordinates(e): Coordinates {
    return {
      x: e.changedTouches[0].pageX,
      y: e.changedTouches[0].pageY
    };
  }

  @HostListener("touchstart", ["$event"])
  onPanStart(e) {
    this.initialCoordinates = this.getCoordinates(e);
    this.vPan = false;
    this.hPan = false;
    this.isOn = true;
  }

  @HostListener("touchend", ["$event"])
  onPanEnd(e) {
    const coo = this.getCoordinates(e);
    const dX = coo.x - this.initialCoordinates.x;
    const dY = coo.y - this.initialCoordinates.y;
    const direction = dX < 0 ? "left" : "right";
    if (!this.isOn) {
      return;
    }
    if (Math.abs(dX) > 50) {
      this.touchSwipe.emit({x: dX + this.x, y: dY + this.y, direction: direction});
    } else {
      this.touchSwipeAbort.emit({x: dX + this.x, y: dY + this.y, direction: direction});
    }
  }

  @HostListener("touchmove", ["$event"])
  onPanMove(e) {
    const coo = this.getCoordinates(e);
    let dX = coo.x - this.initialCoordinates.x;
    let dY = coo.y - this.initialCoordinates.y;
    const direction = dX < 0 ? "left" : "right";

    if (Math.abs(dY) > 5) {
      this.vPan = true;
    }

    if (Math.abs(dX) > 5) {
      this.hPan = true;
    }

    if (this.vPan && !this.hPan) {
      this.isOn = false;
    }

    if (!this.isOn) {
      dX = 0;
      dY = 0;
    }

    this.touchPan.emit({x: dX + this.x, y: dY + this.y, direction: direction});
  }
}
