import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {FormatPipe} from "ngx-dayjs9";
import {ApiService} from "../api/api.service";

export interface CurrencyExchangeApiData {
  currencyFrom: string;
  currencyTo: string;
  exchangeDate: Date;
  exchangeRate: number;
}

@Injectable({providedIn: "root"})
export class CurrencyExchangeApiService {
  constructor(private apiService: ApiService) {
  }

  readCurrencyExchange(currencyFrom: string, exchangeDate: Date | string, currencyTo: string): Observable<CurrencyExchangeApiData> {
    if (!currencyFrom || !exchangeDate || !currencyTo) {
      return throwError(() => new Error("Invalid exchange parameters"));
    }
    return this.apiService.get("3rd/currency-exchange/{currencyFrom}/{exchangeDate}/{currencyTo}", {
      currencyFrom,
      exchangeDate: new FormatPipe().transform(exchangeDate, "YYYY-MM-DD"),
      currencyTo
    });
  }
}
