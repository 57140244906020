<div class="d-flex flex-wrap justify-content-between align-items-center">
  <div *ngFor="let month of monthNames; let i = index"
       class="cursor-pointer month-col px-0 py-2"
       [class.month-col__inner--start]="monthsInfo[i]?.isStartInterval"
       [class.month-col__inner--end]="monthsInfo[i]?.isEndInterval"
       [class.month-col__inner--inside]="monthsInfo[i]?.isInsideInterval"
       [class.month-col__inner--disabled]="monthsInfo[i]?.isDisabled"
       [class.month-col__inner--current]="monthsInfo[i]?.isCurrent"
       (click)="clickMonth(i)"
       [innerText]="month">
  </div>
</div>
